import React from 'react'
import ToriiAppBar from './ToriiAppBar'
import ToriiTabs from './ToriiTabs'
import ToriiAppBarTabs from './ToriiAppBarTabs'
import ToriiFab from './ToriiFab'
import ToriiCard from './ToriiCard'
import ToriiHorizontalSlider from './ToriiHorizontalSlider'
import ToriiSlideShow from './ToriiSlideShow/ToriiSlideShow'

const ToriiComponentNames = {
  '__torii-appbar__': {
    component: ToriiAppBar,
  },
  '__torii-tabs__': {
    component: ToriiTabs,
  },
  '__torii-tabsappbar__': {
    component: ToriiAppBarTabs,
  },
  '__torii-fab__': {
    component: ToriiFab,
  },
  '__torii-card__': {
    component: ToriiCard,
  },
  '__torii-horizontalslider__': {
    component: ToriiHorizontalSlider,
  },
  '__torii-slideshow__': {
    component: ToriiSlideShow,
  },
}

export function getToriiComponentByName(name, properties) {
  const toriiComponent = ToriiComponentNames[name]

  if (!toriiComponent) return null

  return <toriiComponent.component {...properties} />
}
