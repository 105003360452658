const jssStyles = theme => ({
  container: {
    position: 'absolute',
  },
  slide: {
    visibility: 'hidden',
    zIndex: -1,
  },
  slideActive: {
    visibility: 'visible',
    zIndex: 10,
  },
  handlers: {
    position: 'absolute',
    zIndex: 15,
  },
  handler: {
    cursor: 'pointer',
    opacity: 0.8,
  },
  handlerActive: {
    opacity: 1,
  },
})

export default jssStyles
