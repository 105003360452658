import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Layer from 'components/Layer'

import styles from './AppBarStyles'

const States = {
  default: '--default',
  change: '--change',
}

class AppBar extends Component {
  render() {
    const {
      classes,
      layer,
      layer: {
        frame,
        frame: { width, height },
        layers,
      },
      artboardEvents,
      artboardEvents: { scrollTop },
      storageBucketId,
      scaleRatio,
      editMode,
    } = this.props

    const isScrolling = scrollTop > 0

    const containerClassNames = classNames({
      [classes.container]: true,
      [classes.hiddenContainer]:
        (layer.name.includes(States.default) && isScrolling) ||
        (layer.name.includes(States.change) && !isScrolling),
    })

    const containerStyles = {
      width: width * scaleRatio,
      height: height * scaleRatio,
    }

    return (
      <div className={containerClassNames} style={containerStyles}>
        {layer.layers &&
          Object.keys(layers).map(layerId => {
            const componentLayer = layers[layerId]
            return (
              <Layer
                layer={componentLayer}
                artboardFrame={frame}
                key={layerId}
                artboardEvents={artboardEvents}
                storageBucketId={storageBucketId}
                scaleRatio={scaleRatio}
                editMode={editMode}
              />
            )
          })}
      </div>
    )
  }
}

AppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  artboardEvents: PropTypes.object,
  storageBucketId: PropTypes.string,
  scaleRatio: PropTypes.number,
  editMode: PropTypes.bool,
}

export default withStyles(styles)(AppBar)
