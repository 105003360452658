import '@babel/polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'

import { Provider as ReduxProvider } from 'react-redux'
import { MuiThemeProvider } from '@material-ui/core/styles'

import store, { history } from './store'
import theme from './styles/muiTheme'
import './styles/index.css'

import * as GoogleAnalytics from './analytics/GoogleAnalytics'

import App from './connected/App'

// Initialize Google Analytics and have it handle page changes
GoogleAnalytics.initialize()
history.listen(GoogleAnalytics.handleLocationChange)

ReactDOM.render(
  <ReduxProvider store={store}>
    <MuiThemeProvider theme={theme}>
      <Router history={history}>
        <App />
      </Router>
    </MuiThemeProvider>
  </ReduxProvider>,
  document.getElementById('root')
)
