import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DraggableCore } from 'react-draggable'
import throttle from 'lodash/throttle'

const ThrottleIntervalMs = 20

class DraggableBlock extends Component {
  state = {
    position: {
      x: 0,
      y: 0,
    },
  }

  componentWillReceiveProps(props) {
    const { position } = props
    if (!this.isCurrentlyDragging) {
      this.setState({ position })
    }
  }

  isCurrentlyDragging = false

  handleMouseDown = e => {
    const { onMouseDown } = this.props
    onMouseDown && onMouseDown(e)
  }

  handleOnDrag = (e, data) => {
    const { position } = this.state

    this.isCurrentlyDragging = true

    this.setState({
      position: {
        x: position.x + data.deltaX,
        y: position.y + data.deltaY,
      },
    })

    this.throttledDispatchPositionUpdate(e, data)
  }

  handleOnStop = (e, data) => {
    const { position } = this.state

    this.isCurrentlyDragging = false

    this.setState({
      position: {
        x: position.x + data.deltaX,
        y: position.y + data.deltaY,
      },
    })

    this.throttledDispatchPositionUpdate.cancel()
    this.dispatchPositionUpdate(e, data)
  }

  // Create a throttled version of the `dispatchPositionUpdate` method which will be
  // called whenever handleOnDrag is triggered from the React Draggable component. This prevents us
  // from updating the store too often when the user is dragging a FlagAnnotation.
  throttledDispatchPositionUpdate = throttle(
    this.dispatchPositionUpdate.bind(this),
    ThrottleIntervalMs
  )

  // Updates the position of the annotation in the redux store.
  dispatchPositionUpdate(e, data) {
    const { onChange } = this.props
    const { position } = this.state
    onChange && onChange(e, position, data.node)
  }

  render() {
    const { children, disabled } = this.props
    const { position } = this.state
    return (
      <DraggableCore
        onMouseDown={this.handleMouseDown}
        onDrag={this.handleOnDrag}
        onStop={this.handleOnStop}
        position={position}
        disabled={disabled}
        offsetParent={document.body}
      >
        {children}
      </DraggableCore>
    )
  }
}

DraggableBlock.propTypes = {
  position: PropTypes.object,
  disabled: PropTypes.bool,
  children: PropTypes.element,
  onMouseDown: PropTypes.func,
  onChange: PropTypes.func,
}

export default DraggableBlock
