const jssStyles = theme => ({
  container: {
    position: 'absolute',
    cursor: 'pointer',
    minWidth: 20,
    zIndex: theme.zIndex.layerElevated,
    padding: 4,
    boxSizing: 'unset',
    marginLeft: -4,
    marginTop: -4,
    borderRadius: 10,
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.1)',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      border: '1px solid transparent',
    },
  },
})

export default jssStyles
