import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import findIndex from 'lodash/findIndex'
import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Snackbar from '@material-ui/core/Snackbar'

import * as pageTypes from 'constants/pageTypes'
import { DownloadOptions } from 'constants/common'
import { setCurrentPage, fetchProjectPage, saveScreenPage } from 'actions/pageActions'

import AuthPage from 'components/AuthPage'
import CanvasContainer from 'components/CanvasContainer'

import styles from './AdminScreenPageStyles'

class AdminScreenPage extends Component {
  state = {
    downloadType: 0,
  }

  componentWillMount() {
    const {
      actions,
      routeParams: { projectId, screenId },
    } = this.props

    actions.setCurrentPage(pageTypes.AdminScreenPage)
    actions.fetchProjectPage(projectId, screenId)
  }

  componentWillReceiveProps(props) {
    const {
      page: {
        project,
      },
    } = props

    if (!project || !project.options || !project.options.downloadSizeOptions) {
      return
    }
    const downloadType = findIndex(
      DownloadOptions,
      option => option.values.join('') === project.options.downloadSizeOptions.join('')
    )
    this.setState({ downloadType })
  }

  handleSelectChange = e => {
    this.setState({ downloadType: e.target.value })
  }

  handleSaveScreenChanges = async() => {
    const { actions } = this.props
    const { downloadType } = this.state

    const options = {
      downloadSizeOptions: DownloadOptions[downloadType].values,
    }

    const response = await actions.saveScreenPage({
      options,
    })

    if (response) {
      this.setState({ openSnackbar: true })
    }
  }

  handleSnackbarClose = e => {
    this.setState({ openSnackbar: false })
  }

  render() {
    const {
      classes,
      user,
      page: {
        currentPage,
        project: { user: projectUser },
      },
    } = this.props
    const { downloadType, openSnackbar } = this.state

    if (!user || user.email !== projectUser) {
      return <AuthPage />
    }

    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <FormControl className={classes.downloadControls}>
            <InputLabel>Download Type</InputLabel>
            <Select value={downloadType} onChange={this.handleSelectChange}>
              {DownloadOptions.map((option, optionIdx) => (
                <MenuItem key={option.type} value={optionIdx}>
                  {option.type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <CanvasContainer currentPage={currentPage} etsyPage />

        <Button variant="outlined" onClick={this.handleSaveScreenChanges}>
          Save changes
        </Button>
        <Snackbar
          open={openSnackbar}
          onClose={this.handleSnackbarClose}
          message={<span>Changes saved</span>}
          autoHideDuration={2000}
        />
      </div>
    )
  }
}

AdminScreenPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    setCurrentPage: PropTypes.func.isRequired,
    fetchProjectPage: PropTypes.func.isRequired,
    saveScreenPage: PropTypes.func.isRequired,
  }),
  routeParams: PropTypes.object,
  user: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  hoveredLayerId: PropTypes.string,
  selectedLayerId: PropTypes.string,
}

const mapStateToProps = (state, ownProps) => {
  const { user, page } = state

  return {
    routeParams: ownProps.match.params,
    user,
    page,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        setCurrentPage,
        fetchProjectPage,
        saveScreenPage,
      },
      dispatch
    ),
  }
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(AdminScreenPage)
