import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import LineShape from './LineShape'
import OvalShape from './OvalShape'
import RectangleShape from './RectangleShape'

import styles from './ShapeLayerStyles'

class ShapeLayer extends Component {
  handleMouseDown = e => {
    const { onMouseDown, layer } = this.props
    onMouseDown && onMouseDown(e, layer.id)
  }

  handleMouseEnter = e => {
    const { onMouseEnter, layer } = this.props
    onMouseEnter && onMouseEnter(e, layer.id)
  }

  handleMouseLeave = e => {
    const { onMouseLeave, layer } = this.props
    onMouseLeave && onMouseLeave(e, layer.id)
  }

  handleShapeChange = (e, propPath, value) => {
    const { onChange } = this.props
    onChange && onChange(e, propPath, value)
  }

  render() {
    const {
      classes,
      isHovered,
      isSelected,
      layer,
      layer: { zIndex },
      scaleRatio,
    } = this.props

    const rootStyles = { zIndex }

    return (
      <div
        className={classes.container}
        style={rootStyles}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {layer.shapeType === 'line' && (
          <LineShape
            layer={layer}
            isHovered={isHovered}
            isSelected={isSelected}
            onMouseDown={this.handleMouseDown}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            onChange={this.handleShapeChange}
            scaleRatio={scaleRatio}
          />
        )}
        {layer.shapeType === 'oval' && (
          <OvalShape
            layer={layer}
            isHovered={isHovered}
            isSelected={isSelected}
            onMouseDown={this.handleMouseDown}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            onChange={this.handleShapeChange}
            scaleRatio={scaleRatio}
          />
        )}
        {layer.shapeType === 'rectangle' && (
          <RectangleShape
            layer={layer}
            isHovered={isHovered}
            isSelected={isSelected}
            onMouseDown={this.handleMouseDown}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            onChange={this.handleShapeChange}
            scaleRatio={scaleRatio}
          />
        )}
      </div>
    )
  }
}

ShapeLayer.propTypes = {
  classes: PropTypes.object.isRequired,
  projectId: PropTypes.string,
  layer: PropTypes.object.isRequired,
  isHovered: PropTypes.bool,
  isSelected: PropTypes.bool,
  onMouseDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onChange: PropTypes.func,
  scaleRatio: PropTypes.number,
}

export default withStyles(styles)(ShapeLayer)
