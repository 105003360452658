import { SET_CURRENT_PAGE, FETCH_PROJECT_PAGE } from 'constants/actionTypes'
import * as pageTypes from 'constants/pageTypes'
import { setCanvasProject } from 'actions/canvasActions'
import FirebaseAPI from 'api/FirebaseAPI'

export function setCurrentPage(pageName) {
  return dispatch => {
    dispatch({
      type: SET_CURRENT_PAGE,
      pageName,
    })
  }
}

export function fetchProjectPage(projectId, screenId) {
  return async(dispatch, getState) => {
    const {
      page: { currentPage },
    } = getState()

    switch (currentPage) {
      case pageTypes.LivePage:
      case pageTypes.MakerPage: {
        const project = await FirebaseAPI.getMakerProject(projectId)
        dispatch({
          type: FETCH_PROJECT_PAGE,
          payload: project,
        })

        dispatch(
          setCanvasProject(project, {
            storageBucketId: project.projectId,
          })
        )

        return project
      }
      case pageTypes.ScreenPage:
      case pageTypes.AdminScreenPage: {
        const project = await FirebaseAPI.getProject(projectId)
        const screen = await FirebaseAPI.getScreen(projectId, screenId)

        dispatch({
          type: FETCH_PROJECT_PAGE,
          payload: {
            id: project.id,
            screenId,
            user: project.user,
            title: screen.title,
            options: screen.options,
            screen,
          },
        })

        const canvasProject = {
          projectId: project.id,
          artboard: screen.artboard,
          layers: screen.layers,
        }

        dispatch(setCanvasProject(canvasProject, { storageBucketId: screen.makerProjectId }))

        return project
      }
      default:
        return null
    }
  }
}

export function saveScreenPage(params) {
  return async(dispatch, getState) => {
    const state = getState()
    const {
      canvas: {
        project: { artboard, layers },
      },
      page: {
        project: { id: projectId, screen },
      },
    } = state

    const newScreen = {
      ...screen,
      artboard,
      layers: layers || {},
      ...params,
    }

    const response = await FirebaseAPI.saveScreen(projectId, screen.id, newScreen)
    return response
  }
}
