import React from 'react'
import PropTypes from 'prop-types'

import TextField from '@material-ui/core/TextField'

class NumberField extends React.Component {
  handleChange = e => {
    const { onChange } = this.props
    const newValue = e.target.value
    const numberValue = parseFloat(newValue)
    onChange && onChange(e, numberValue)
  }

  incrementValue = (initiatingEvent, step) => {
    const { value, onChange } = this.props
    onChange && onChange(initiatingEvent, value + step)
  }

  decrementValue = (initiatingEvent, step) => {
    const { value, onChange } = this.props
    onChange && onChange(initiatingEvent, value - step)
  }

  handleKeyDown = e => {
    const { stepSmall, stepLarge, onKeyDown } = this.props
    const step = e.shiftKey ? stepLarge : stepSmall

    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault()
        this.incrementValue(e, step)
        break
      case 'ArrowDown':
        e.preventDefault()
        this.decrementValue(e, step)
        break
      default:
        break
    }

    onKeyDown && onKeyDown(e)
  }

  render() {
    const { onChange, onKeyDown, value, stepSmall, stepLarge, ...otherProps } = this.props

    return (
      <TextField
        {...otherProps}
        type="number"
        value={value}
        onKeyDown={this.handleKeyDown}
        onChange={this.handleChange}
      />
    )
  }
}

NumberField.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  hideCopyButton: PropTypes.bool,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  stepSmall: PropTypes.number.isRequired,
  stepLarge: PropTypes.number.isRequired,
}

NumberField.defaultProps = {
  stepSmall: 1,
  stepLarge: 10,
}

export default NumberField
