import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { ReactSymbols, ReactSymbol } from '@react-symbols'
import DraggableBlock from '_common/DraggableBlock'
import ResizeWrapper from '_common/ResizeWrapper'
import ProgressiveImage from '_common/ProgressiveImage'

import styles, { ResizerOffsetPx } from './SymbolLayerStyles'

class SymbolLayer extends React.Component {
  handleMouseDown = e => {
    const { onMouseDown, layer } = this.props
    onMouseDown && onMouseDown(e, layer.id)
  }

  handleMouseEnter = e => {
    const { onMouseEnter, layer } = this.props
    onMouseEnter && onMouseEnter(e, layer.id)
  }

  handleMouseLeave = e => {
    const { onMouseLeave, layer } = this.props
    onMouseLeave && onMouseLeave(e, layer.id)
  }

  handlePositionChange = (e, position) => {
    const { onChange, layer } = this.props

    onChange &&
      onChange(e, 'frame', {
        ...layer.frame,
        x: position.x,
        y: position.y,
      })
  }

  handleFrameChange = (e, frame) => {
    const { onChange, layer } = this.props

    onChange &&
      onChange(e, 'frame', {
        ...layer.frame,
        ...frame,
      })
  }

  handleSymbolChange = (e, propPath, value) => {
    const { onChange } = this.props
    onChange && onChange(e, propPath, value)
  }

  render() {
    const {
      classes,
      isHovered,
      isSelected,
      layer,
      layer: {
        symbolMasterId,
        frame: { x, y, height, width, textAlign },
        zIndex,
      },
      storageBucketId,
      artboardEvents,
      scaleRatio,
    } = this.props

    const isSymbol = symbolMasterId && ReactSymbols[symbolMasterId]

    const containerClassNames = classNames({
      [classes.container]: true,
      [classes.containerSymbol]: isSymbol,
      [classes.containerHovered]: isHovered,
      [classes.containerSelected]: isSelected,
    })

    const position = {
      x: x * scaleRatio,
      y: y * scaleRatio,
    }

    const dimensions = {
      top: y * scaleRatio,
      left: x * scaleRatio,
      height: height * scaleRatio,
      width: width * scaleRatio,
    }

    const offsetDimensions = isSymbol
      ? {
          height: ResizerOffsetPx,
          width: ResizerOffsetPx,
        }
      : null

    const symbolStyles = isSymbol ? { ...ReactSymbols[symbolMasterId].containerStyles } : {}

    const rootStyles = {
      textAlign,
      zIndex,
      ...dimensions,
      ...symbolStyles,
    }

    return (
      <DraggableBlock
        onMouseDown={this.handleMouseDown}
        position={position}
        onChange={this.handlePositionChange}
      >
        <div
          className={containerClassNames}
          onMouseDown={this.handleMouseDown}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          style={rootStyles}
        >
          <ResizeWrapper
            dimensions={dimensions}
            onChange={this.handleFrameChange}
            disabled={!isSelected}
            offsetDimensions={offsetDimensions}
          >
            {isSymbol ? (
              <ReactSymbol
                layer={layer}
                onChange={this.handleSymbolChange}
                artboardEvents={artboardEvents}
                isEditMode
              />
            ) : (
              <ProgressiveImage
                classes={classes}
                layer={layer}
                draggable={false}
                storageBucketId={storageBucketId}
              />
            )}
          </ResizeWrapper>
        </div>
      </DraggableBlock>
    )
  }
}

SymbolLayer.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  isHovered: PropTypes.bool,
  isSelected: PropTypes.bool,
  onMouseDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onChange: PropTypes.func,
  storageBucketId: PropTypes.string,
  artboardEvents: PropTypes.object,
  scaleRatio: PropTypes.number,
}

export default withStyles(styles)(SymbolLayer)
