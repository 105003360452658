import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'

import AuthPage from '../../components/AuthPage'

class RequireAuthInit extends React.Component {
  render() {
    const { user, children } = this.props
    return user && user.email ? children : <AuthPage />
  }
}

RequireAuthInit.propTypes = {
  user: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
}

const mapStateToProps = state => {
  const { user } = state
  return { user }
}

export default compose(
  connect(
    mapStateToProps,
    null
  )
)(RequireAuthInit)
