const jssStyles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 120,
    margin: '24px 0',
    '& > div:first-child': {
      fontFamily: 'Roboto',
      backgroundColor: '#232323',
      color: 'white',
      padding: '8px 16px',
      fontSize: 14,
    },
    '& > code': {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#f4f5f6',
      fontFamily: 'Roboto Mono',
      fontWeight: 'bold',
      letterSpacing: 1,
      fontSize: 21,
    },
  },
})

export default jssStyles
