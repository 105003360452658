function distanceSquared(p1, p2) {
  return (p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2
}

/**
 * Given two points (p1 and p2), return the distance between them
 */
export function distance(p1, p2) {
  return Math.sqrt(distanceSquared(p1, p2))
}

/**
 * Given a point (p) and two points representing a line segment (s1, s2), find
 * the shortest distance between the point and any point on the line segment.
 *
 * This implementation is a modified version of one shamelessly stolen from the
 * following stackoverflow answer: http://stackoverflow.com/a/1501725
 */
export function distFromPointToSegment(p, s1, s2) {
  // If line segment is 0 length, it's effectively a point -- return distance to that
  const l2 = distanceSquared(s1, s2)
  if (l2 === 0) return distance(p, s1)
  const t = ((p.x - s1.x) * (s2.x - s1.x) + (p.y - s1.y) * (s2.y - s1.y)) / l2
  const clampedT = Math.max(0, Math.min(1, t))
  return distance(p, {
    x: s1.x + clampedT * (s2.x - s1.x),
    y: s1.y + clampedT * (s2.y - s1.y),
  })
}

/**
 * Given a point (p), and the x,y,width, height of a rectangle, find the
 * shortest distance between the point and the rectangle. Points inside the
 * rectangle are considered to have 0 distance from it.
 *
 * This implementation adapated from the following stackoverflow answer:
 * http://gamedev.stackexchange.com/a/50722
 *
 * <3 stackoverflow
 */
export function distFromPointToRectangle(point, rectX, rectY, rectWidth, rectHeight) {
  // First, find the 'clamp point'. This is the closest point on the rectangle
  // edge to the given point, p. Basically, if you imagine a line drawn from the
  // center of the rectangle to the point, the clamp point is where that line
  // intersects with the edge of the rectangle.
  const clampPoint = {
    x: Math.max(Math.min(point.x, rectX + rectWidth), rectX),
    y: Math.max(Math.min(point.y, rectY + rectHeight), rectY),
  }
  // After that, it's a simple distance calculation.
  return distance(clampPoint, point)
}

/**
 * Given origin and destination points, return the angle of rotation in degrees.
 */
export function calcRotationBetweenPoints(p1, p2) {
  return (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI
}

/**
 * Given a radius and an angle (in degrees), return the coordinates along
 * the circumference of the circle.
 */
export function calcPointOnCircle(radius, angle) {
  // Convert to radians
  const angleInRadians = (angle * Math.PI) / 180
  const point = {
    x: radius * Math.cos(angleInRadians),
    y: radius * Math.sin(angleInRadians),
  }
  return point
}

/**
 * Given two points and a distance, return the coordinates along the line.
 */
export function calcPointAlongLine(p1, p2, dt) {
  const d = distance(p1, p2)
  const t = dt / d
  const point = {
    x: (1 - t) * p1.x + t * p2.x,
    y: (1 - t) * p1.y + t * p2.y,
  }
  return point
}

/**
 * Determine if two given rectangles intersect on the horizontal or vertical axis.
 */
export function getIntersectionAxisBetweenRects(rect1, rect2) {
  const intersectsOnXAxis = rect1.x < rect2.x + rect2.width && rect1.x + rect1.width > rect2.x
  const intersectsOnYAxis = rect1.y < rect2.y + rect2.height && rect1.y + rect1.height > rect2.y
  return {
    x: intersectsOnXAxis,
    y: intersectsOnYAxis,
  }
}
