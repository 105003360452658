import { FETCH_PROJECTS, FETCH_PROJECT } from '../constants/actionTypes'
import FirebaseAPI from '../api/FirebaseAPI'

import { hyphenateString, generateRandomValue } from '../utils'

export function submitProject(
  makerProjectId,
  projectName,
  title = '',
  layers = {},
  artboard = {},
  options = {}
) {
  return async(dispatch, getState) => {
    const state = getState()
    const { user } = state

    if (!user || !user.email) {
      return
    }

    const projectId = hyphenateString(projectName)
    let screenId = generateRandomValue()

    let project = await FirebaseAPI.getProject(projectId)

    const screen = {
      id: screenId,
      title,
      layers,
      artboard,
      makerProjectId,
      options,
    }

    if (project) {
      while (project.screen && project.screen[screenId]) {
        screenId = generateRandomValue()
      }
      screen.id = screenId

      project.screen = {
        ...project.screen,
        [screenId]: screen,
      }
    } else {
      project = {
        user: user.email,
        id: projectId,
        name: projectName,
        screen: {
          [screenId]: screen,
        },
      }
    }

    const projectResponse = await FirebaseAPI.submitProject(project)
    return projectResponse
  }
}

export function fetchProjects() {
  return async dispatch => {
    const projects = await FirebaseAPI.getProjects()
    dispatch({
      type: FETCH_PROJECTS,
      payload: projects,
    })

    return projects
  }
}

export function fetchProject(projectId) {
  return async dispatch => {
    const project = await FirebaseAPI.getProject(projectId)
    dispatch({
      type: FETCH_PROJECT,
      payload: project,
    })

    return project
  }
}
