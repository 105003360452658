import React, { Component } from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import { signUp } from '../../actions/userActions'

import AuthPage from '../AuthPage'

import styles from './SignUpStyles'

const InitialState = {
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
  message: null,
  signInActive: false,
  loading: false,
}

class SignUp extends Component {
  state = { ...InitialState }

  onSubmit = async e => {
    const { actions } = this.props
    const { email, passwordOne } = this.state
    e.preventDefault()
    this.setState({ loading: true })

    const authUser = await actions.signUp(email, passwordOne)

    this.setState({ ...authUser, loading: false })
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  signIn = e => {
    e.preventDefault()
    this.setState({ signInActive: true })
  }

  render() {
    const { classes } = this.props
    const { email, passwordOne, passwordTwo, error, message, signInActive, loading } = this.state

    const isInvalid = passwordOne !== passwordTwo || passwordOne === '' || email === ''

    if (signInActive) {
      return <AuthPage />
    }

    return (
      <div className={classes.container}>
        <form className={classes.form} onSubmit={this.onSubmit}>
          {loading && (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          )}
          <Typography variant="h5" className={classes.title}>
            Sign Up
          </Typography>
          <FormControl fullWidth margin="dense">
            <TextField
              label="Email Address"
              name="email"
              value={email}
              margin="normal"
              type="email"
              onChange={this.onChange}
              required
              style={{ top: 5 }}
            />
          </FormControl>
          <FormControl className={classes.row} fullWidth margin="dense">
            <TextField
              label="Password"
              name="passwordOne"
              value={passwordOne}
              margin="normal"
              type="password"
              onChange={this.onChange}
              required
            />
            <TextField
              label="Confirm Password"
              name="passwordTwo"
              value={passwordTwo}
              margin="normal"
              type="password"
              onChange={this.onChange}
              required
            />
          </FormControl>

          {error && message && (
            <Typography variant="subtitle2" className={classes.errorMessage}>
              {message}
            </Typography>
          )}

          <div className={classes.actions}>
            <Typography variant="subtitle2" className={classes.signInText}>
              Already a user?
              {' '}
              <span className={classes.signInLink} onClick={this.signIn}>
                Click here to sign in
              </span>
            </Typography>
            <Button variant="outlined" type="submit" disabled={isInvalid}>
              Sign Up
            </Button>
          </div>
        </form>
      </div>
    )
  }
}

SignUp.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    signUp: PropTypes.func.isRequired,
  }),
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ signUp }, dispatch),
  }
}

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withStyles(styles)
)(SignUp)
