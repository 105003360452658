import { combineReducers } from 'redux'

// Import reducers
import { routerReducer as routing } from 'react-router-redux'

import user from './userReducer'
import projects from './projectsReducer'
import projectPage from './projectPageReducer'
import page from './pageReducer'
import canvas from './canvasReducer'
import artboardFlow from './artboardFlowReducer'

export default combineReducers({
  // Library reducers
  routing,
  // App reducers
  user,
  projects,
  projectPage,
  page,
  canvas,
  artboardFlow,
})
