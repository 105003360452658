import update from 'immutability-helper'

import initialState from './initialState'
import { INIT_USER } from '../constants/actionTypes'

export default (state = initialState.user, action) => {
  switch (action.type) {
    case INIT_USER:
      return update(state, {
        $set: {
          ...initialState.user,
          ...action.payload,
        },
      })
    default:
      return state
  }
}
