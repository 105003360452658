import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import * as pageTypes from 'constants/pageTypes'
import { setCurrentPage } from 'actions/pageActions'
import { fetchProject } from 'actions/projectActions'
import { formatFirebaseDictAsArray } from 'utils'

import AuthPage from '../AuthPage'
import PageItem from '../PageItem'

import styles from './ProjectPageStyles'

const ToriiUrl = 'torii.co'

class ProjectPage extends Component {
  componentWillMount() {
    const {
      actions,
      routeParams: { projectId },
    } = this.props
    actions.setCurrentPage(pageTypes.ProjectPage)
    actions.fetchProject(projectId)
  }

  render() {
    const { classes, user, project } = this.props

    if (!user || user.email !== project.user) {
      return <AuthPage />
    }

    const screens = formatFirebaseDictAsArray(project.screen)
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography variant="h1" className={classes.projectTitle}>
            {project.name}
          </Typography>
          <Typography variant="body1" className={classes.projectUrl}>
            {`${project.id}.${ToriiUrl}`}
          </Typography>
        </div>
        <Grid container className={classes.pageLayout}>
          {screens.map(screen => (
            <Link to={`${project.id}/${screen.id}`} key={screen.id}>
              <PageItem page={screen} className={classes.pageItem} />
            </Link>
          ))}
        </Grid>
      </div>
    )
  }
}

ProjectPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    setCurrentPage: PropTypes.func.isRequired,
    fetchProject: PropTypes.func.isRequired,
  }),
  routeParams: PropTypes.object,
  user: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const { user } = state
  const { project } = state.projectPage
  return {
    routeParams: ownProps.match.params,
    user,
    project,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ setCurrentPage, fetchProject }, dispatch),
  }
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(ProjectPage)
