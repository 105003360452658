const jssStyles = theme => ({
  container: {
    width: 370,
    position: 'relative',
    margin: '0 12px',
  },
  display: {
    position: 'absolute',
    width: '100%',
    zIndex: 10,
  },
  image: {
    position: 'relative',
    left: '5%',
    width: '90%',
    top: 12,
    zIndex: 5,
    borderRadius: 26,
  },
  '@media (max-width: 900px)': {
    container: {
      width: 185,
    },
    image: {
      top: 4,
      borderRadius: 12,
    },
  },
})

export default jssStyles
