/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import QRCode from 'qrcode.react'
import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import SmartphoneIcon from '@material-ui/icons/Smartphone'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

import { isMobileDevice } from 'utils'
import * as pageTypes from 'constants/pageTypes'
import { setCurrentPage, fetchProjectPage } from 'actions/pageActions'

import CanvasContainer from 'components/CanvasContainer'

import styles from './MakerPageStyles'

class MakerPage extends Component {
  state = {
    loading: true,
    dialogActive: false,
  }

  componentWillMount() {
    const {
      actions,
      routeParams: { projectId },
    } = this.props
    actions.setCurrentPage(pageTypes.MakerPage)
    actions.fetchProjectPage(projectId)
  }

  componentDidUpdate({ page: prevPage }, prevState) {
    const { page } = this.props

    const prevArtboard = _get(prevPage, 'project.artboard')
    const artboard = _get(page, 'project.artboard')

    if (!isEqual(prevArtboard, artboard)) {
      this.setState({ loading: false })
    }
  }

  goToLive = () => {
    const {
      routeParams: { projectId },
      history,
    } = this.props
    history.push(`/live/${projectId}`)
    window.location.reload()
  }

  handleDialogOpen = () => {
    this.setState({ dialogActive: true })
  }

  handleDialogClose = () => {
    this.setState({ dialogActive: false })
  }

  render() {
    const {
      classes,
      page: { currentPage },
      routeParams: { projectId },
    } = this.props
    const { loading, dialogActive } = this.state

    return (
      <div className={classes.container}>
        {loading && (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        )}
        {!loading && <CanvasContainer currentPage={currentPage} />}

        {!isMobileDevice() && (
          <React.Fragment>
            <div className={classes.actions}>
              <Button onClick={this.goToLive} variant="outlined">
                Live
              </Button>
              <IconButton edge="start" color="primary" onClick={this.handleDialogOpen}>
                <SmartphoneIcon />
              </IconButton>
            </div>
            <Dialog className={classes.dialog} open={dialogActive} onClose={this.handleDialogClose}>
              <DialogContent>
                <QRCode value={`https://torii-dev-tt.firebaseapp.com/live/${projectId}`} />
              </DialogContent>
            </Dialog>
          </React.Fragment>
        )}
      </div>
    )
  }
}

MakerPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    setCurrentPage: PropTypes.func.isRequired,
    fetchProjectPage: PropTypes.func.isRequired,
  }),
  routeParams: PropTypes.object,
  page: PropTypes.object.isRequired,
  history: PropTypes.object,
}

const mapStateToProps = (state, ownProps) => {
  const { page } = state
  return {
    routeParams: ownProps.match.params,
    page,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        setCurrentPage,
        fetchProjectPage,
      },
      dispatch
    ),
  }
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(MakerPage)
