import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Layer from 'components/Layer'

import styles from './SlideshowStyles'

const SlideshowType = {
  container: 'slideshow',
  slides: 'slideshow-slides',
  handlers: 'slideshow-handlers',
}

class Slideshow extends Component {
  state = {
    layerActive: 0,
  }

  setLayerActive = layerActive => {
    this.setState({ layerActive })
  }

  renderHandlers(handlersLayer) {
    const { classes, artboardEvents, scaleRatio, editMode } = this.props
    const {
      layers,
      frame,
      frame: { x: left, y: top, width, height },
    } = handlersLayer
    const { layerActive } = this.state

    const handlersStyle = {
      top: top * scaleRatio,
      left: left * scaleRatio,
      width: width * scaleRatio,
      height: height * scaleRatio,
    }

    return (
      <div className={classes.handlers} style={handlersStyle}>
        {layers &&
          Object.keys(layers).map(layerId => {
            const componentLayer = layers[layerId]

            const handlersClassNames = classNames({
              [classes.handler]: true,
              [classes.handlerActive]: layerActive === componentLayer.zIndex,
            })

            return (
              <div
                key={layerId}
                className={handlersClassNames}
                onClick={() => {
                  this.setLayerActive(componentLayer.zIndex)
                }}
              >
                <Layer
                  layer={componentLayer}
                  artboardFrame={frame}
                  artboardEvents={artboardEvents}
                  scaleRatio={scaleRatio}
                  editMode={editMode}
                />
              </div>
            )
          })}
      </div>
    )
  }

  renderSlides(slidesLayer) {
    const { classes, artboardEvents, storageBucketId, scaleRatio, editMode } = this.props
    const { layers, frame } = slidesLayer
    const { layerActive } = this.state

    return (
      layers &&
      Object.keys(layers).map(layerId => {
        const componentLayer = layers[layerId]

        const slideClassNames = classNames({
          [classes.slide]: true,
          [classes.slideActive]:
            layerActive === Math.abs(Object.keys(layers).length - componentLayer.zIndex - 1),
        })

        return (
          <div className={slideClassNames} key={layerId}>
            {componentLayer.layers &&
              Object.keys(componentLayer.layers).map(slideLayerId => {
                const slideLayer = componentLayer.layers[slideLayerId]
                return (
                  <Layer
                    layer={slideLayer}
                    artboardFrame={frame}
                    artboardEvents={artboardEvents}
                    key={slideLayerId}
                    storageBucketId={storageBucketId}
                    scaleRatio={scaleRatio}
                    editMode={editMode}
                  />
                )
              })}
          </div>
        )
      })
    )
  }

  renderContainer() {
    const {
      classes,
      layer: {
        frame: { width, height, y: top, x: left },
        layers,
        zIndex,
      },
      scaleRatio,
    } = this.props

    const containerStyles = {
      top: top * scaleRatio,
      left: left * scaleRatio,
      width: width * scaleRatio,
      height: height * scaleRatio,
      zIndex,
    }

    const slidesLayerId = Object.keys(layers).find(layerId => {
      const componentLayer = layers[layerId]
      return componentLayer.name === SlideshowType.slides
    })

    const handlersLayerId = Object.keys(layers).find(layerId => {
      const componentLayer = layers[layerId]
      return componentLayer.name === SlideshowType.handlers
    })

    return (
      <div className={classes.container} style={containerStyles}>
        {handlersLayerId && this.renderHandlers(layers[handlersLayerId])}
        {slidesLayerId && this.renderSlides(layers[slidesLayerId])}
      </div>
    )
  }

  render() {
    const { layer } = this.props

    switch (layer.name) {
      case SlideshowType.container: {
        return this.renderContainer()
      }
      default: {
        return null
      }
    }
  }
}

Slideshow.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  artboardEvents: PropTypes.object,
  storageBucketId: PropTypes.string,
  scaleRatio: PropTypes.number,
  editMode: PropTypes.bool,
}

export default withStyles(styles)(Slideshow)
