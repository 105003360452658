import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import * as pageTypes from 'constants/pageTypes'
import { DownloadOptions } from 'constants/common'
import { setCurrentPage, fetchProjectPage } from 'actions/pageActions'

import PublishView from 'components/PublishView'
import SignUp from 'components/SignUp'
import CanvasContainer from 'components/CanvasContainer'

import styles from './EtsyMakerPageStyles'

class EtsyMakerPage extends Component {
  state = {
    publishDialogActive: false,
    downloadType: 0,
  }

  componentWillMount() {
    const {
      actions,
      routeParams: { projectId },
    } = this.props
    actions.setCurrentPage(pageTypes.MakerPage)
    actions.fetchProjectPage(projectId)
  }

  handlePublishButtonClick = () => {
    this.setState({ publishDialogActive: true })
  }

  handleDialogClose = () => {
    this.setState({ publishDialogActive: false })
  }

  handleSelectChange = e => {
    this.setState({ downloadType: e.target.value })
  }

  render() {
    const {
      classes,
      user,
      page: { currentPage },
    } = this.props

    const { publishDialogActive, downloadType } = this.state
    const isUserAuthenticated = user && user.email

    const publishOptions = {
      downloadSizeOptions: DownloadOptions[downloadType].values,
    }

    return (
      <div className={classes.container}>
        {this.renderDialogIfNecessary}
        <Dialog open={publishDialogActive} onClose={this.handleDialogClose}>
          {!isUserAuthenticated && <SignUp />}
          {isUserAuthenticated && <PublishView options={publishOptions} />}
        </Dialog>

        <div className={classes.header}>
          <FormControl className={classes.downloadControls}>
            <InputLabel>Download Type</InputLabel>
            <Select value={downloadType} onChange={this.handleSelectChange}>
              {DownloadOptions.map((option, optionIdx) => (
                <MenuItem key={option.type} value={optionIdx}>
                  {option.type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <CanvasContainer currentPage={currentPage} etsyPage />

        <Button
          variant="outlined"
          className={classes.publishButton}
          onClick={this.handlePublishButtonClick}
        >
          Publish
        </Button>
      </div>
    )
  }
}

EtsyMakerPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    setCurrentPage: PropTypes.func.isRequired,
    fetchProjectPage: PropTypes.func.isRequired,
  }),
  routeParams: PropTypes.object,
  user: PropTypes.object.isRequired,
  hoveredLayerId: PropTypes.string,
  selectedLayerId: PropTypes.string,
  page: PropTypes.object.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const { user } = state
  const { page } = state
  return {
    routeParams: ownProps.match.params,
    user,
    page,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        setCurrentPage,
        fetchProjectPage,
      },
      dispatch
    ),
  }
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(EtsyMakerPage)
