const jssStyles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    position: 'relative',
  },
  image: {
    flex: '0 0 auto',
    height: '100%',
    width: 'auto',
    marginRight: 16,
    borderRadius: 13,
  },
})

export default jssStyles
