const jssStyles = theme => ({
  scrollContainer: {
    position: 'relative',
    overflowX: 'scroll',
    overflowScrolling: 'touch',
    WebkitOverflowScrolling: 'touch',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },

  item: {
    height: 175,
    width: 175,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
    margin: '0 6px',
  },
  text: {
    fontFamily: 'Roboto',
    fontSize: 19,
    color: 'white',
    fontWeight: 100,
  },
})

export default jssStyles
