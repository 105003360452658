import mapValues from 'lodash/mapValues'
import zIndex from '@material-ui/core/styles/zIndex'
import { createMuiTheme } from '@material-ui/core/styles'

const maxLayers = 2000

const elevatedZIndices = mapValues(zIndex, z => z + maxLayers)

const ToriiColor = '#71196b'
const ToriiPrimary = '#012242'
const ToriiWhite = '#fff'

const black87 = 'rgba(0, 0, 0, 0.87)'
const black65 = 'rgba(0, 0, 0, 0.65)'
const black54 = 'rgba(0, 0, 0, 0.54)'
const black38 = 'rgba(0, 0, 0, 0.38)'
const black24 = 'rgba(0, 0, 0, 0.24)'
const black16 = 'rgba(0, 0, 0, 0.16)'
const black12 = 'rgba(0, 0, 0, 0.12)'

export default createMuiTheme({
  palette: {
    primary: {
      color: ToriiColor,
      main: ToriiPrimary
    },
    toriiPrimary: ToriiPrimary,
    toriiWhite: ToriiWhite,
    toriiBlack: '#2c2c2c',
    black87,
    black65,
    black54,
    black38,
    black24,
    black16,
    black12,
    uiBorder: black16,
    uiBorderLight: black12
  },
  typography: {
    fontFamily: ['Proxima Nova', 'Roboto', 'Arial'],
    useNextVariants: true,
    body1: {
      color: '#000'
    }
  },
  form: {
    padding: '0 16px 16px'
  },
  overrides: {},
  appBarHeight: 64,
  zIndex: {
    layer: maxLayers + 1,
    layerElevated: maxLayers + 5,
    GUI: maxLayers + 6,
    ...elevatedZIndices
  }
})
