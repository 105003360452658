const DrawerWidth = 240

const jssStyles = theme => ({
  container: {
    position: 'relative',
    width: DrawerWidth,
    flexShrink: 0,
    display: 'none',
  },
  containerOpened: {
    display: 'block',
  },
  drawerPaper: {
    width: DrawerWidth,
    padding: '72px 52px',
  },
})

export default jssStyles
