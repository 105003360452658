import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { withStyles } from '@material-ui/core/styles'

import { stopPropagation } from 'utils'

import ArtboardCanvas from 'components/ArtboardCanvas'
import Layer from 'components/Layer'
import BlockHint from 'components/BlockHint'

import styles from './CanvasStyles'

class Canvas extends Component {
  componentDidMount() {
    window.addEventListener('keydown', this.handleKeydown)
    window.addEventListener('keyup', this.handleKeyup)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeydown)
    window.removeEventListener('keyup', this.handleKeyup)
  }

  handleKeydown = (e, parentRef = null) => {
    const { onKeydown } = this.props
    onKeydown && onKeydown(e, parentRef)
  }

  handleKeyup = (e, parentRef = null) => {
    const { onKeyup } = this.props
    onKeyup && onKeyup(e, parentRef)
  }

  handleLayerMouseDown = (e, layerId) => {
    stopPropagation(e)
    const { onLayerMouseDown } = this.props
    onLayerMouseDown && onLayerMouseDown(layerId)
  }

  handleLayerMouseEnter = (e, layerId) => {
    stopPropagation(e)
    const { onLayerMouseEnter } = this.props
    onLayerMouseEnter && onLayerMouseEnter(layerId)
  }

  handleLayerMouseLeave = (e, layerId) => {
    stopPropagation(e)
    const { onLayerMouseLeave } = this.props
    onLayerMouseLeave && onLayerMouseLeave(layerId)
  }

  handleLayerChange = (e, layerId, propPath, value, parentRef = null) => {
    stopPropagation(e)
    const { onLayerChange } = this.props
    onLayerChange && onLayerChange(layerId, propPath, value, parentRef)
  }

  handleMouseDown = e => {
    stopPropagation(e)
    const { onCanvasMouseDown } = this.props
    onCanvasMouseDown && onCanvasMouseDown(e)
  }

  handleArtboardMouseDown = (e, artboardRef) => {
    stopPropagation(e)
    const { onCanvasMouseDown } = this.props
    onCanvasMouseDown &&
      onCanvasMouseDown(e, {
        type: 'artboard',
        node: artboardRef,
      })
  }

  render() {
    const {
      classes,
      hoveredLayerId,
      selectedLayerId,
      project: { layers, storageBucketId, artboard },
      artboardClassName,
      toolsState,
      originMousePosition,
      etsyPage,
    } = this.props

    const containerClassNames = classNames({
      [classes.container]: true,
      [classes.etsyContainer]: etsyPage,
    })

    return (
      <section className={containerClassNames} onMouseDown={this.handleMouseDown}>
        {layers &&
          Object.keys(layers).map(layerId => {
            const layer = layers[layerId]
            return (
              <Layer
                layer={layer}
                isHovered={layerId === hoveredLayerId}
                isSelected={layerId === selectedLayerId}
                storageBucketId={storageBucketId}
                onMouseDown={this.handleLayerMouseDown}
                onMouseEnter={this.handleLayerMouseEnter}
                onMouseLeave={this.handleLayerMouseLeave}
                onLayerChange={this.handleLayerChange}
                key={layerId}
              />
            )
          })}
        {!isEmpty(artboard) && (
          <ArtboardCanvas
            className={artboardClassName}
            artboard={artboard}
            storageBucketId={storageBucketId}
            hoveredLayerId={hoveredLayerId}
            selectedLayerId={selectedLayerId}
            onMouseDown={this.handleArtboardMouseDown}
            onLayerMouseDown={this.handleLayerMouseDown}
            onLayerMouseEnter={this.handleLayerMouseEnter}
            onLayerMouseLeave={this.handleLayerMouseLeave}
            onLayerChange={this.handleLayerChange}
            onKeydown={this.handleKeydown}
            onKeyup={this.handleKeyup}
            etsyPage={etsyPage}
          />
        )}
        {toolsState.activeTool && (
          <BlockHint toolsState={toolsState} originMousePosition={originMousePosition} />
        )}
      </section>
    )
  }
}

Canvas.propTypes = {
  classes: PropTypes.object.isRequired,
  project: PropTypes.object,
  currentPage: PropTypes.string,
  hoveredLayerId: PropTypes.string,
  selectedLayerId: PropTypes.string,
  artboardClassName: PropTypes.string,
  onCanvasMouseDown: PropTypes.func,
  onLayerMouseDown: PropTypes.func,
  onLayerMouseEnter: PropTypes.func,
  onLayerMouseLeave: PropTypes.func,
  onLayerChange: PropTypes.func,
  onKeydown: PropTypes.func,
  onKeyup: PropTypes.func,
  toolsState: PropTypes.object,
  originMousePosition: PropTypes.object,
  etsyPage: PropTypes.bool,
}

export default withStyles(styles)(Canvas)
