const IconSizePx = 24

const jssStyles = theme => ({
  container: {
    backgroundColor: theme.palette.toriiWhite,
    display: 'flex',
    width: '100%',
    height: 64,
    alignItems: 'center',
    justifyContent: 'center',
  },
  etsyContainer: {
    position: 'absolute',
  },
  toolButton: {
    opacity: 0.5,
  },
  toolActive: {
    opacity: 1,
  },
  icon: {
    color: 'black',
    WebkitMaskSize: `${IconSizePx}px ${IconSizePx}px`,
  },
})

export default jssStyles
