const jssStyles = theme => ({
  component: {
    position: 'absolute',
    zIndex: theme.zIndex.GUI,
    top: 0,
    left: 0,
    pointerEvents: 'none',
  },
})

export default jssStyles
