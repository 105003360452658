import theme from 'styles/muiTheme'
import { MaterialButton, MaterialAppBar } from './Material'
import { ToriiImageSlider } from './Torii'

const MaterialButtonFlat = {
  name: MaterialButton,
  props: {},
}

const MaterialButtonOutlined = {
  name: MaterialButton,
  props: {
    variant: 'outlined',
  },
}

const MaterialButtonContained = {
  name: MaterialButton,
  props: {
    variant: 'contained',
  },
  editModeStyles: {
    boxShadow:
      '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
  },
}

export default {
  'ABBD39BE-0234-4415-884C-7C90385DC190': {
    name: MaterialAppBar,
    overrideProps: {
      // <id>
      '6598CFE4-CAE9-45D4-94B8-E45EC50EB1D2/5CFFEB67-F6EA-4F38-B0A2-5CAF91996170_stringValue': {
        prop: 'text',
        type: 'text',
      },
    },
    containerStyles: {
      zIndex: theme.zIndex.layerElevated,
    },
  },
  '01B78274-8C85-463A-BF5C-68D1A00B8AFA': {
    ...MaterialButtonOutlined,
    overrideProps: {
      // <id>
      '6F34BAE2-6847-4557-B4EF-040D9FD31DC6/795E42AC-B672-4179-8C16-F51F6144C953_stringValue': {
        prop: 'text',
        type: 'text',
      },
    },
  },
  'E442DF11-4167-40FA-A0E7-9A38F17B4701': {
    ...MaterialButtonOutlined,
    overrideProps: {
      // <id>
      '94F4A592-BC2C-4F0B-82C2-C1ED9545B860/795E42AC-B672-4179-8C16-F51F6144C953_stringValue': {
        prop: 'text',
        type: 'text',
      },
    },
  },
  'E643F5A7-BEBA-481D-BC4C-C7F148D6F66C': {
    ...MaterialButtonFlat,
    overrideProps: {
      // <id>
      '14D81857-B03E-47AC-8E16-489B92329240/795E42AC-B672-4179-8C16-F51F6144C953_stringValue': {
        prop: 'text',
        type: 'text',
      },
    },
  },
  'E44F0496-9FC6-4A95-910C-3641A457DA99': {
    ...MaterialButtonContained,
    overrideProps: {
      // <id>
      '08C5F435-D882-483C-83F5-D398A89DAE4A_symbolID': {
        prop: 'backgroundColor',
        type: 'color',
      },
      '08C5F435-D882-483C-83F5-D398A89DAE4A/677A8015-2ADB-4217-A8A4-D335305F7967_symbolID': {
        prop: 'backgroundColor',
        type: 'color',
      },
      '471495BD-48B8-4CAF-A33E-1730F2E2B79B/856FB902-8190-4D3F-BECF-30AC63598E43_stringValue': {
        prop: 'text',
        type: 'text',
      },
    },
  },
  '54BBEED9-2714-49DE-8E0D-2DFA90D5EE72': {
    ...MaterialButtonContained,
    overrideProps: {
      // <id>
      '52D96E56-F168-4C54-AF3F-BA63EC70CC97/856FB902-8190-4D3F-BECF-30AC63598E43_stringValue': {
        prop: 'text',
        type: 'text',
      },
    },
  },
  'A7E635F1-D8DE-4FD9-8BED-EDF6C8DE3D53': {
    name: ToriiImageSlider,
    overrideProps: {
      // <id>
      'D83E34D2-3F70-4260-B616-DBF885CA787E_symbolID': {
        prop: 'frame',
        type: 'frame',
      },
    },
  },
  // 'B4397189-FA5C-4C38-962A-A7E2F540CC90': {
  //   name: MaterialTextField,
  //   overrideProps: {
  //     '4223224D-3B6E-4C55-BBD7-026651D17FB1/3A638FF8-EE2F-47F2-A198-8C5C5FFEF17D/6204EA1A-6F42-4991-8748-D1B87479FF2F_stringValue': {
  //       prop: 'label',
  //       type: 'text',
  //     },
  //   },
  //   props: {
  //     variant: 'outlined',
  //   },
  //   styleProps: ['frame', 'zIndex'],
  // },
}
