import React, { Component } from 'react'
import PropTypes from 'prop-types'
import values from 'lodash/values'
import { withStyles } from '@material-ui/core/styles'

import MaterialSlider from '@material-ui/core/Slider'

import { processLayerPosition } from 'utils'

import Layer from 'components/Layer'

import styles from './SliderStyles'

const DefaultSliderThumbSizePx = 12

class Slider extends Component {
  state = {
    thumbLayer: null,
    railLayer: null,
  }

  componentWillMount() {
    const {
      layer: { layers },
    } = this.props

    let thumbLayer = null
    let offsetAux = Infinity

    values(layers).forEach(layer => {
      const { frame } = layer

      const offsetSize = Math.max(frame.width, frame.height) / Math.min(frame.width, frame.height)

      if (offsetSize < offsetAux) {
        offsetAux = offsetSize
        thumbLayer = layer
      }
    })

    const railLayer = values(layers).find(layer => thumbLayer.id !== layer.id)

    this.setState({ thumbLayer, railLayer })
  }

  getSliderComponent() {
    const { scaleRatio } = this.props
    const { railLayer } = this.state

    const componentStyles = {
      width: railLayer.frame.width * scaleRatio,
      height: railLayer.frame.height * scaleRatio,
    }

    return withStyles({
      root: {
        ...componentStyles,
      },
      thumb: {
        background: 'transparent',
        height: DefaultSliderThumbSizePx,
        width: DefaultSliderThumbSizePx,
      },
      track: {
        ...railLayer.style,
        ...componentStyles,
      },
      rail: {
        ...railLayer.style,
        ...componentStyles,
      },
    })(MaterialSlider)
  }

  getThumbComponent = props => {
    const { artboardEvents, storageBucketId, scaleRatio, editMode } = this.props
    const { thumbLayer } = this.state

    const position = processLayerPosition(thumbLayer, scaleRatio)

    const componentStyles = {
      ...position,
      width: thumbLayer.frame.width * scaleRatio,
      height: thumbLayer.frame.height * scaleRatio,
    }

    const modLayer = {
      ...thumbLayer,
      frame: {
        ...componentStyles,
        x: -1 * (componentStyles.width / 2) + DefaultSliderThumbSizePx / 2,
        y: -1 * (componentStyles.height / 2) + DefaultSliderThumbSizePx / 2,
      },
    }

    return (
      <span {...props}>
        <Layer
          layer={modLayer}
          artboardFrame={modLayer.frame}
          artboardEvents={artboardEvents}
          storageBucketId={storageBucketId}
          scaleRatio={scaleRatio}
          editMode={editMode}
        />
      </span>
    )
  }

  render() {
    const {
      classes,
      layer,
      layer: {
        frame: { width, height },
      },
      scaleRatio,
    } = this.props

    const position = processLayerPosition(layer, scaleRatio)

    const containerStyles = {
      ...position,
      width: width * scaleRatio,
      height: height * scaleRatio,
    }

    const SliderComponent = this.getSliderComponent()

    return (
      <SliderComponent
        className={classes.container}
        ThumbComponent={this.getThumbComponent}
        style={containerStyles}
      />
    )
  }
}

Slider.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  artboardEvents: PropTypes.object,
  storageBucketId: PropTypes.string,
  scaleRatio: PropTypes.number,
  editMode: PropTypes.bool,
}

export default withStyles(styles)(Slider)
