import blue from '@material-ui/core/colors/blue'

const OffsetPosition = 6

const jssStyles = theme => ({
  container: {
    position: 'absolute',
    cursor: 'text',
    minWidth: 20,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: -OffsetPosition,
      left: -OffsetPosition,
      height: `calc(100% + ${OffsetPosition}px)`,
      width: `calc(100% + ${OffsetPosition}px)`,
      border: '1px solid transparent',
    },
  },
  containerHovered: {
    '&:before': {
      borderColor: blue[100],
    },
  },
  containerSelected: {
    '&:before': {
      borderColor: blue[400],
    },
  },
  containerMove: {
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
  disableSelection: {
    '&:before': {
      content: 'none',
    },
  },
  hyperlink: {
    borderBottom: '1px solid #000',
  },
  flexContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',

    '& > div': {
      flex: 1,
    },
  },
})

export default jssStyles
