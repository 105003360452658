import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

import { DownloadSizeOptions } from 'constants/common'

export async function saveAsPDF(pdfName, targetEl, { items, dividers, ...jsPdfProps }) {
  const offsetSize = 1.333

  const canvas = await html2canvas(targetEl, { useCORS: true, scale: 2, letterRendering: true })

  canvas.style.display = 'none'
  requestAnimationFrame(() => {
    canvas.style.display = 'block'
    const imgData = canvas.toDataURL('image/png', 1.0)

    // eslint-disable-next-line new-cap
    const pdf = new jsPDF({
      unit: 'px',
      ...jsPdfProps,
    })

    items.forEach(({ x, y, width, height }) => {
      pdf.addImage(
        imgData,
        'PNG',
        x / offsetSize,
        y / offsetSize,
        width / offsetSize,
        height / offsetSize
      )
    })

    if (dividers) {
      dividers.forEach(divider => {
        const [x1, y1, x2, y2] = divider
        pdf.line(x1 / offsetSize, y1 / offsetSize, x2 / offsetSize, y2 / offsetSize)
      })
    }

    pdf.save(pdfName)
  })
}

export function downloadPdfFromDOM(domEl, pdfName, option) {
  switch (option) {
    case DownloadSizeOptions.GAMES_2_ON_1.name:
      {
        const { name, orientation, width, height, dividers } = DownloadSizeOptions.GAMES_2_ON_1
        const pdfHeight = height
        const pdfWidth = width * 2
        saveAsPDF(`${pdfName}_${name}`, domEl, {
          orientation,
          format: [pdfHeight, pdfWidth],
          dividers: dividers({ height: pdfHeight, width: pdfWidth }),
          items: [
            {
              x: 0,
              y: 0,
              height,
              width,
            },
            {
              x: width,
              y: 0,
              height,
              width,
            },
          ],
        })
      }
      break
    case DownloadSizeOptions.GAMES_1_ON_1.name:
      {
        const { name, orientation, width, height } = DownloadSizeOptions.GAMES_1_ON_1
        saveAsPDF(`${pdfName}_${name}`, domEl, {
          orientation,
          format: [height, width],
          items: [
            {
              x: 0,
              y: 0,
              height,
              width,
            },
          ],
        })
      }
      break
    case DownloadSizeOptions.SIGNS_8_10.name:
      {
        const { name, orientation, width, height } = DownloadSizeOptions.SIGNS_8_10
        saveAsPDF(`${pdfName}_${name}`, domEl, {
          orientation,
          format: [height, width],
          items: [
            {
              x: 0,
              y: 0,
              height,
              width,
            },
          ],
        })
      }
      break
    case DownloadSizeOptions.SIGNS_8_10_LETTER.name:
      {
        const { name, orientation, width, height, dividers } = DownloadSizeOptions.SIGNS_8_10_LETTER
        saveAsPDF(`${pdfName}_${name}`, domEl, {
          orientation,
          format: [height, width],
          dividers: dividers({ height, width }),
          items: [
            {
              x: 0,
              y: 0,
              height,
              width,
            },
          ],
        })
      }
      break
    case DownloadSizeOptions.SIGNS_5_7.name:
      {
        const { name, orientation, width, height } = DownloadSizeOptions.SIGNS_5_7
        saveAsPDF(`${pdfName}_${name}`, domEl, {
          orientation,
          format: [height, width],
          items: [
            {
              x: 0,
              y: 0,
              height,
              width,
            },
          ],
        })
      }
      break
    case DownloadSizeOptions.CARDS_8_10_LETTER.name:
      {
        const { name, orientation, width, height, dividers } = DownloadSizeOptions.CARDS_8_10_LETTER
        const pdfHeight = height * 2
        const pdfWidth = width * 2

        saveAsPDF(`${pdfName}_${name}`, domEl, {
          orientation,
          format: [pdfHeight, pdfWidth],
          dividers: dividers({ height: pdfHeight, width: pdfWidth }),
          items: [
            {
              x: 0,
              y: 0,
              height,
              width,
            },
            {
              x: width,
              y: 0,
              height,
              width,
            },
            {
              x: 0,
              y: height,
              height,
              width,
            },
            {
              x: width,
              y: height,
              height,
              width,
            },
          ],
        })
      }
      break
    default:
  }
}
