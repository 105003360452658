/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

import styles from './ToriiHorizontalSliderStyles'

const SlideSymbol = {
  prop: '__slidesymbol__',
  value: [],
}

const SlideItemProps = {
  textProp: 'stringValue',
  imageProp: 'image',
}

const getSlideItemProps = propsValue => {
  const textProp = propsValue.find(p => p.property === SlideItemProps.textProp)
  const imageProp = propsValue.find(p => p.property === SlideItemProps.imageProp)
  return {
    textValue: (textProp && textProp.value) || '',
    imageValue: (imageProp && imageProp.value) || '',
  }
}

class ToriiHorizontalSlider extends Component {
  render() {
    const {
      classes,
      props,
      frame: { width: frameWidth },
      artboardFrame: { width: artboardFrameWidth },
    } = this.props

    const items = []
    props.forEach(({ prop, value: propsValue }) => {
      if (prop === SlideSymbol.prop) {
        items.push(getSlideItemProps(propsValue))
      }
    })

    const itemsProcessed = items.reverse()

    const scrollContainerStyles = {
      width: artboardFrameWidth,
    }

    const containerStyles = {
      width: frameWidth,
    }

    return (
      <div className={classes.scrollContainer} style={scrollContainerStyles}>
        <div className={classes.container} style={containerStyles}>
          {itemsProcessed &&
            itemsProcessed.map((item, itemId) => (
              <div
                key={`torii-h-slider-${itemId}`}
                className={classes.item}
                style={{ backgroundImage: `url(data:image/png;base64,${item.imageValue})` }}
              >
                <Typography variant="body1" className={classes.text}>
                  {item.textValue}
                </Typography>
              </div>
            ))}
        </div>
      </div>
    )
  }
}

ToriiHorizontalSlider.propTypes = {
  classes: PropTypes.object.isRequired,
  props: PropTypes.arrayOf(PropTypes.object).isRequired,
  frame: PropTypes.object.isRequired,
  artboardFrame: PropTypes.object,
}

export default withStyles(styles)(ToriiHorizontalSlider)
