import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'

import { formatFirebaseDictAsArray, hyphenateString } from '../../utils'
import { fetchProjects, submitProject } from '../../actions/projectActions'

import styles from './PublishViewStyles'

class PublishView extends Component {
  state = {
    projectNameValue: '',
    projectNameSelected: '',
    titleValue: '',
  }

  componentWillMount() {
    const { actions } = this.props
    actions.fetchProjects()
  }

  handleInputKeyDown = e => e.stopPropagation()

  handleTextChange = e => {
    this.setState({
      [`${e.target.name}Value`]: e.target.value,
    })
  }

  handleSelectChange = e => {
    this.setState({
      [`${e.target.name}Selected`]: e.target.value,
    })
  }

  handleSubmit = async e => {
    const { projectNameSelected, projectNameValue, titleValue } = this.state
    const {
      actions,
      canvas: {
        project: { projectId, layers, artboard },
      },
      history,
      options,
    } = this.props
    if ((!projectNameSelected && !projectNameValue) || !titleValue) {
      return false
    }

    const projectResponse = await actions.submitProject(
      projectId,
      projectNameSelected || projectNameValue,
      titleValue,
      layers,
      artboard,
      options
    )

    if (projectResponse && projectResponse.id) {
      history.push(`/${projectResponse.id}`)
    }
  }

  render() {
    const { classes, user, projects } = this.props
    const { projectNameValue, projectNameSelected, titleValue } = this.state

    const helperTextProjectName = projectNameValue
      ? `${hyphenateString(projectNameValue)}.torii.co`
      : 'torii.co/<your-project>'

    const helperTitleName = 'This site\'s title'

    const userProjects = projects.filter(p => {
      return p.user === user.email
    })

    return (
      <div className={classes.container}>
        <form className={classes.form}>
          <Typography variant="h5" className={classes.title}>
            Publish project
          </Typography>
          <div className={classes.projectName}>
            {projects.length > 0 && (
              <FormControl fullWidth margin="dense">
                <InputLabel htmlFor="select-project">Project Name</InputLabel>
                <Select
                  name="projectName"
                  value={projectNameSelected}
                  onChange={this.handleSelectChange}
                  className={classes.projectNameSelect}
                  input={<Input id="select-project" />}
                >
                  <MenuItem value="">New</MenuItem>
                  {userProjects.map(p => (
                    <MenuItem value={p.name} key={p.id}>
                      {p.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {!projectNameSelected && (
              <FormControl fullWidth margin="dense">
                <TextField
                  label="New"
                  name="projectName"
                  value={projectNameValue}
                  margin="normal"
                  onChange={this.handleTextChange}
                  onKeyDown={this.handleInputKeyDown}
                  helperText={helperTextProjectName}
                  required
                  style={{ top: 5 }}
                />
              </FormControl>
            )}
          </div>
          <FormControl fullWidth margin="dense">
            <TextField
              label="Title"
              name="title"
              value={titleValue}
              margin="normal"
              onChange={this.handleTextChange}
              onKeyDown={this.handleInputKeyDown}
              helperText={helperTitleName}
              required
            />
          </FormControl>

          <div className={classes.actions}>
            <Button
              onClick={this.handleSubmit}
              variant="outlined"
              className={classes.submit}
              disabled={(!projectNameSelected && !projectNameValue) || !titleValue}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    )
  }
}

PublishView.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  canvas: PropTypes.object.isRequired,
  options: PropTypes.object,
  projects: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.shape({
    fetchProjects: PropTypes.func.isRequired,
    submitProject: PropTypes.func.isRequired,
  }),
  history: PropTypes.object,
}

const mapStateToProps = state => {
  const { user, projects, canvas } = state
  return { user, projects: formatFirebaseDictAsArray(projects), canvas }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ fetchProjects, submitProject }, dispatch),
  }
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(PublishView)
