const jssStyles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  mainSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  header: {
    display: 'flex',
    height: 56,
    width: '100%',
    flexDirection: 'row-reverse',
  },
  downloadControls: {
    minWidth: 200,
    margin: '12px 16px',
  },
})

export default jssStyles
