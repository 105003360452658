import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

import { canvasKeyboardShortcuts } from 'constants/keyboardShortcuts'
import * as utils from 'utils'
import {
  setHoveredLayer,
  setSelectedLayer,
  setLayerProperty,
  handleShortcutsKeyDown,
  handleShortcutsKeyUp,
  setActiveTool,
  canvasClick,
  setOriginMousePosition,
} from 'actions/canvasActions'

import Toolbar from 'components/Toolbar'
import AppDrawer from 'components/AppDrawer'
import Canvas from 'components/Canvas'

import styles from './CanvasContainerStyles'

class CanvasContainer extends Component {
  setHoveredLayer = layerId => {
    const {
      actions,
      selectedLayerId,
      toolsState: { activeTool },
    } = this.props

    if (selectedLayerId || activeTool) {
      return
    }

    actions.setHoveredLayer(layerId)
  }

  unsetHoveredLayer = () => {
    const { actions, selectedLayerId } = this.props
    if (selectedLayerId) {
      return
    }
    actions.setHoveredLayer(null)
  }

  setSelectedLayer = layerId => {
    const {
      actions,
      currentPage,
      toolsState: { activeTool },
    } = this.props

    if (activeTool) {
      return
    }

    actions.setSelectedLayer(currentPage, layerId)
    actions.setHoveredLayer(null)
  }

  unsetSelectedLayer = () => {
    const { actions, currentPage } = this.props
    this.unsetHoveredLayer()
    actions.setSelectedLayer(currentPage, null)
  }

  setLayerChange = (layerId, propPath, value, parentRef = null) => {
    const { actions, currentPage } = this.props
    actions.setLayerProperty(currentPage, layerId, propPath, value, parentRef)
  }

  handleKeydown = (e, parentRef = null) => {
    const { actions, currentPage } = this.props
    actions.handleShortcutsKeyDown(e, currentPage, canvasKeyboardShortcuts, parentRef)
  }

  handleKeyup = (e, parentRef = null) => {
    const { actions, currentPage } = this.props
    actions.handleShortcutsKeyUp(e, currentPage, canvasKeyboardShortcuts, parentRef)
  }

  handleToolChange = (e, toolName) => {
    const { actions } = this.props
    actions.setActiveTool(toolName)
  }

  handleCanvasMouseDown = (e, ref = null) => {
    const { actions } = this.props
    const originMousePosition = utils.getPositionFromMouseEvent(e)
    actions.canvasClick(originMousePosition, ref)
    this.unsetSelectedLayer()
  }

  handleMouseMove = e => {
    const { actions } = this.props
    const originMousePosition = utils.getPositionFromMouseEvent(e)
    actions.setOriginMousePosition(originMousePosition)
  }

  getContainerStyle() {
    const {
      toolsState: { activeTool },
    } = this.props

    if (!activeTool) {
      return null
    }

    return {
      cursor: utils.getToolConfigForToolName(activeTool).cursor,
    }
  }

  render() {
    const {
      classes,
      className,
      hoveredLayerId,
      selectedLayerId,
      project,
      toolsState,
      originMousePosition,
      artboardClassName,
      etsyPage,
    } = this.props

    const containerClassNames = classNames(
      {
        [classes.container]: true,
        [classes.etsyContainer]: etsyPage,
      },
      className
    )

    const containerStyle = this.getContainerStyle()

    return (
      <div
        className={containerClassNames}
        onMouseDown={this.unsetSelectedLayer}
        style={containerStyle}
        onMouseMove={this.handleMouseMove}
      >
        {etsyPage && (
          <Toolbar toolsState={toolsState} onChange={this.handleToolChange} etsyPage={etsyPage} />
        )}
        <AppDrawer
          project={project}
          selectedLayerId={selectedLayerId}
          onLayerChange={this.setLayerChange}
        />
        <Canvas
          project={project}
          artboardClassName={artboardClassName}
          hoveredLayerId={hoveredLayerId}
          selectedLayerId={selectedLayerId}
          onCanvasMouseDown={this.handleCanvasMouseDown}
          onLayerMouseDown={this.setSelectedLayer}
          onLayerMouseEnter={this.setHoveredLayer}
          onLayerMouseLeave={this.unsetHoveredLayer}
          onLayerChange={this.setLayerChange}
          onKeydown={this.handleKeydown}
          onKeyup={this.handleKeyup}
          toolsState={toolsState}
          originMousePosition={originMousePosition}
          etsyPage={etsyPage}
        />
      </div>
    )
  }
}

CanvasContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  actions: PropTypes.shape({
    setLayerProperty: PropTypes.func.isRequired,
    setSelectedLayer: PropTypes.func.isRequired,
    setHoveredLayer: PropTypes.func.isRequired,
    handleShortcutsKeyDown: PropTypes.func.isRequired,
    handleShortcutsKeyUp: PropTypes.func.isRequired,
    setActiveTool: PropTypes.func.isRequired,
    canvasClick: PropTypes.func.isRequired,
    setOriginMousePosition: PropTypes.func.isRequired,
  }),
  toolsState: PropTypes.object,
  project: PropTypes.object.isRequired,
  currentPage: PropTypes.string,
  hoveredLayerId: PropTypes.string,
  selectedLayerId: PropTypes.string,
  originMousePosition: PropTypes.object,
  artboardClassName: PropTypes.string,
  etsyPage: PropTypes.bool,
}

const mapStateToProps = (state, ownProps) => {
  const { project, hoveredLayerId, selectedLayerId, toolsState, originMousePosition } = state.canvas
  return {
    project,
    hoveredLayerId,
    selectedLayerId,
    toolsState,
    originMousePosition,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        setLayerProperty,
        setSelectedLayer,
        setHoveredLayer,
        setActiveTool,
        canvasClick,
        handleShortcutsKeyDown,
        handleShortcutsKeyUp,
        setOriginMousePosition,
      },
      dispatch
    ),
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(CanvasContainer)
