/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

import DeviceImage from 'docs/components/DeviceImage'

import styles from 'docs/DocsPageStyles'

const DocsTypography = withStyles(theme => ({
  root: {
    color: theme.palette.black65,
    fontFamily: 'Open Sans',
  },
}))(Typography)

class DocsButtons extends Component {
  render() {
    const { classes } = this.props

    return (
      <a name="states" className={classes.subSectionLink}>
        <a href="#states">
          <DocsTypography variant="h6" className={classes.contentSubTitle}>
            States
          </DocsTypography>
        </a>
        <div className={classes.subSectionContent}>
          <p>
            Some components have specific states that you could use in order to change their
            behavior in runtime. To use states add double dashes <code>--</code> to your layer name
            or component identifier. For example, to make your previous component to float:{' '}
            <code>button--float</code> or <code>Group 1__button--float</code>
          </p>
          <div className={classes.imageContainer}>
            <DeviceImage src="/images/docs/sample-states-1.gif" />
          </div>
          <p style={{ marginTop: 100 }}>
            You can use the <code>float</code> state for other purposes as well:
          </p>
          <div className={classes.imageContainer}>
            <DeviceImage src="/images/docs/sample-states-2.gif" />
          </div>
        </div>
      </a>
    )
  }
}

DocsButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  routeParams: PropTypes.object,
}

export default withStyles(styles)(DocsButtons)
