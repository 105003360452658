/* eslint-disable no-param-reassign */
import React from 'react'
import PropTypes from 'prop-types'
import findIndex from 'lodash/findIndex'

import * as utils from 'utils'

import ReactSymbols from './ReactSymbols'

const getOverrideValue = (symbolOverride, baseOverrideProps) => {
  if (baseOverrideProps.type === 'color') {
    return symbolOverride.value
  }

  if (baseOverrideProps.type === 'text') {
    return symbolOverride.value
  }

  if (baseOverrideProps.type === 'frame') {
    return symbolOverride.frame
  }

  return null
}

class ReactSymbol extends React.Component {
  handleSymbolChange = (e, typeChange, attr) => {
    const {
      layer: { overrides },
      onChange,
    } = this.props

    let changedPath
    let changedValue
    switch (typeChange) {
      case 'addOverride': {
        const id = utils.getUniqId(overrides)
        const { property, value } = attr
        changedPath = 'overrides'
        changedValue = overrides.concat({
          id,
          path: id,
          property,
          value,
        })
        break
      }
      case 'changeOverride': {
        const overrideIndex = findIndex(overrides, o => o.id === attr.id)
        changedPath = `overrides.${overrideIndex}.value`
        changedValue = attr.value
        break
      }
      default: {
        break
      }
    }

    onChange && onChange(e, changedPath, changedValue)
  }

  mergeSymbolProps(baseSymbol) {
    const {
      layer: { overrides },
      isEditMode,
    } = this.props

    const editStyles = isEditMode ? baseSymbol.editModeStyles : {}
    const symbolProps = overrides.reduce((mergedProps, symbolOverride) => {
      const { id } = symbolOverride
      const baseOverrideProps = baseSymbol.overrideProps[id]

      if (!baseOverrideProps) {
        return mergedProps
      }

      mergedProps[baseOverrideProps.prop] = {
        id,
        value: getOverrideValue(symbolOverride, baseOverrideProps),
      }

      return mergedProps
    }, {})

    return {
      symbolProps,
      styleProps: {
        ...editStyles,
      },
      ...baseSymbol.props,
      overrides,
    }
  }

  render() {
    const {
      layer: symbol,
      layer: { symbolMasterId },
      artboardEvents,
      isEditMode,
    } = this.props
    const reactSymbol = ReactSymbols[symbolMasterId]
    const ReactSymbolName = reactSymbol.name

    return (
      <ReactSymbolName
        layer={symbol}
        artboardEvents={artboardEvents}
        isEditMode={isEditMode}
        onChange={this.handleSymbolChange}
        {...this.mergeSymbolProps(reactSymbol)}
      />
    )
  }
}

ReactSymbol.propTypes = {
  layer: PropTypes.object,
  artboardEvents: PropTypes.object,
  isEditMode: PropTypes.bool,
  onChange: PropTypes.func,
}

export default ReactSymbol
