import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import Swiper from 'react-id-swiper'

import styles from './TestPageStyles'

class TestPage extends Component {
  render() {
    const { classes } = this.props

    const style = {
      height: 200,
      width: 120,
    }

    const params = {
      slidesPerView: 'auto',
      spaceBetween: 20,
      mousewheel: true,
      freeMode: true,
    }

    return (
      <div className={classes.container}>
        <Swiper {...params}>
          <div style={{ backgroundColor: '#00eaff', ...style }}>Slide #1</div>
          <div style={{ backgroundColor: '#eafe00', ...style }}>Slide #2</div>
          <div style={{ backgroundColor: '#ff02ea', ...style }}>Slide #3</div>
          <div style={{ backgroundColor: '#00ff95', ...style }}>Slide #4</div>
          <div style={{ backgroundColor: '#ff006a', ...style }}>Slide #5</div>
        </Swiper>
      </div>
    )
  }
}

TestPage.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default compose(
  withRouter,
  withStyles(styles)
)(TestPage)
