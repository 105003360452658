/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { css } from 'emotion'
import isEqual from 'lodash/isEqual'
import { withStyles } from '@material-ui/core/styles'

import Layer from 'components/Layer'

import { CssTransitionOutDefaultValue, TransitionsIn } from 'constants/animations'
import { getTransitionFromType } from 'utils'

import styles from './ArtboardFlowStyles'

const isFixedName = name => {
  return name && name.includes('--fixed')
}

const getStyledComponent = animation => {
  return animation !== undefined
    ? css`
         {
          animation: ${animation.keyframes} ${animation.duration}ms ${animation.delay}ms forwards;
          z-index: 10;
        }
      `
    : css`
         {
        }
      `
}

class ArtboardFlow extends Component {
  state = {
    prevArtboardFlow: this.props.artboardFlow,
  }

  componentDidUpdate({ artboardFlow: prevArtboardFlow }) {
    const { prevArtboardFlow: prevArtboardFlowState } = this.state
    if (!isEqual(prevArtboardFlowState, prevArtboardFlow)) {
      this.setState({ prevArtboardFlow })
    }
  }

  handleArtboardFlowChange = hotspotValue => {
    const { onArtboardFlowChange } = this.props
    onArtboardFlowChange && onArtboardFlowChange(hotspotValue)
  }

  renderArtboardFlow() {
    const {
      classes,
      artboard: { artboards },
      artboardFlow,
      rootArtboardId,
      artboardEvents,
      artboardEvents: { isMobile, artboardRect, windowRect },
      scaleRatio,
      editMode,
    } = this.props

    const {
      prevArtboardFlow: { activeArtboardId: prevActiveArtboardId },
    } = this.state

    const { activeArtboardId, animationType } = artboardFlow

    const activeId = activeArtboardId || rootArtboardId
    const activeOutId = prevActiveArtboardId || rootArtboardId

    const activeArtboard = artboards[activeId]

    const artboardContainerStyles = {
      height: isFixedName(activeArtboard.name) ? '100%' : activeArtboard.frame.height * scaleRatio,
      overflow: isFixedName(activeArtboard.name) ? 'hidden' : 'auto',
    }

    return (
      <div className={classes.artboardContainer} style={artboardContainerStyles}>
        {Object.keys(artboards).map(artboardId => {
          const artboard = artboards[artboardId]
          const { name, frame } = artboard
          const shouldActivateInArtboard = activeId === artboardId
          const shouldActivateOutArtboard = !shouldActivateInArtboard && activeOutId === artboardId

          const cssTransformValue =
            shouldActivateInArtboard || shouldActivateOutArtboard
              ? ''
              : CssTransitionOutDefaultValue

          const fixedArtboardFrameHeight =
            isFixedName(name) && isMobile
              ? '100%'
              : Math.min(artboardRect.height, windowRect.height)

          const artboardFrameStyles = {
            height: isFixedName(name) ? fixedArtboardFrameHeight : frame.height * scaleRatio,
            transform: cssTransformValue,
          }

          let ActiveArtboardComponent = getStyledComponent()
          if (shouldActivateInArtboard && animationType !== null) {
            ActiveArtboardComponent = getStyledComponent(getTransitionFromType(animationType))
          }

          const artboardFrameContainerClassNames = classNames({
            [classes.artboardFrameContainer]: true,
            [ActiveArtboardComponent]: !!ActiveArtboardComponent,
          })

          return (
            <div
              className={artboardFrameContainerClassNames}
              style={artboardFrameStyles}
              onAnimationEnd={this.onAnimationEnd}
              key={`artboard-${artboardId}`}
            >
              <ArtboardFlow
                classes={classes}
                artboard={artboard}
                artboardFlow={artboardFlow}
                artboardEvents={artboardEvents}
                onArtboardFlowChange={this.handleArtboardFlowChange}
                rootArtboardId={rootArtboardId}
                scaleRatio={scaleRatio}
                editMode={editMode}
              />
            </div>
          )
        })}
      </div>
    )
  }

  render() {
    const {
      classes,
      artboard: { name, frame, layers, artboards },
      artboardEvents,
      style,
      rootArtboardId,
      scaleRatio,
      editMode,
    } = this.props

    const artboardFrameStyles = {
      height: isFixedName(name) ? '100%' : frame.height * scaleRatio,
      backgroundColor: frame.backgroundColor,
      ...style,
    }

    return (
      <React.Fragment>
        {!artboards && (
          <div className={classes.artboardFrame} style={artboardFrameStyles}>
            {layers &&
              Object.keys(layers).map(layerId => {
                const layer = layers[layerId]
                return (
                  <Layer
                    layer={layer}
                    key={layerId}
                    artboardFrame={{ ...artboardFrameStyles, width: frame.width }}
                    artboardEvents={artboardEvents}
                    onArtboardFlowChange={this.handleArtboardFlowChange}
                    storageBucketId={rootArtboardId}
                    scaleRatio={scaleRatio}
                    editMode={editMode}
                  />
                )
              })}
          </div>
        )}
        {artboards && this.renderArtboardFlow()}
      </React.Fragment>
    )
  }
}

ArtboardFlow.propTypes = {
  classes: PropTypes.object.isRequired,
  style: PropTypes.object,
  artboard: PropTypes.object.isRequired,
  onArtboardFlowChange: PropTypes.func,
  artboardFlow: PropTypes.object.isRequired,
  artboardEvents: PropTypes.object,
  rootArtboardId: PropTypes.string,
  scaleRatio: PropTypes.number,
  editMode: PropTypes.bool,
}

export default withStyles(styles)(ArtboardFlow)
