import blue from '@material-ui/core/colors/blue'

const jssStyles = theme => ({
  container: {
    position: 'absolute',
    transformOrigin: 'center left',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
    },
  },
  containerHovered: {
    border: `1px solid ${blue[100]} !important`,
  },
  containerSelected: {
    cursor: 'grab',
    border: `1px solid ${blue[400]} !important`,
    '&:active': {
      cursor: 'grabbing',
    },
  },
})

export default jssStyles
