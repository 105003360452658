const PageSize = 240
const ImageHeight = 200

const jssStyles = theme => ({
  container: {
    borderRadius: 4,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: PageSize,
    width: PageSize,
  },
  imageContainer: {
    height: ImageHeight,
    width: '100%',
    backgroundImage:
      'url(https://www.grouphealth.ca/wp-content/uploads/2018/05/placeholder-image-300x225.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    padding: '4px 12px',
  },
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    flex: 1,
    fontSize: 16,
  },
})

export default jssStyles
