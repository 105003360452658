const jssStyles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    fontFamily: 'Open Sans',
    borderRadius: 12,
  },
  imageContainer: {
    height: '75%',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingTop: 18,
    paddingLeft: 24,
  },
  title: {
    fontWeight: 600,
  },
})

export default jssStyles
