import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

import * as utils from 'utils'

import TextBlock from '_blocks/TextBlock'

class MaterialButton extends React.Component {
  handleTextChange = (e, value) => {
    const {
      symbolProps: {
        text: { id },
      },
      onChange,
    } = this.props

    onChange && onChange(e, 'changeOverride', { id, value })
  }

  render() {
    const {
      layer,
      symbolProps,
      symbolProps: { backgroundColor, text },
      styleProps,
      isEditMode,
      onChange,
      artboardEvents,
      ...otherProps
    } = this.props

    const colorStyleProps = backgroundColor
      ? {
          color: utils.getFontColorFromBackgroundColor(backgroundColor.value),
          backgroundColor: backgroundColor.value,
        }
      : {}

    const mergedStyleProps = {
      height: '100%',
      width: '100%',
      ...styleProps,
      ...colorStyleProps,
    }

    return (
      <Button style={{ ...mergedStyleProps }} {...otherProps}>
        {isEditMode ? (
          <TextBlock value={text.value} onChange={this.handleTextChange} />
        ) : (
          text.value
        )}
      </Button>
    )
  }
}

MaterialButton.propTypes = {
  layer: PropTypes.object,
  symbolProps: PropTypes.object,
  styleProps: PropTypes.object,
  artboardEvents: PropTypes.object,
  isEditMode: PropTypes.bool,
  onChange: PropTypes.func,
}

export default MaterialButton
