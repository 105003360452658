const jssStyles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  actions: {
    position: 'absolute',
    top: 14,
    right: 30,
  },
  loading: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default jssStyles
