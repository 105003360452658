/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

import Note from 'docs/components/Note'

import styles from 'docs/DocsPageStyles'

const DocsTypography = withStyles(theme => ({
  root: {
    color: theme.palette.black65,
    fontFamily: 'Open Sans',
  },
}))(Typography)

class Tutorials extends Component {
  render() {
    const { classes } = this.props

    return (
      <>
        <DocsTypography variant="h6" className={classes.contentSubTitle}>
          Tutorials
        </DocsTypography>
        <div>
          <p>
            Download our{' '}
            <a
              href="https://drive.google.com/uc?export=download&id=1uyZ4_fMuPI0dlw7jqV8Wq05sJzpP2Wd8"
              target="_blank"
              rel="noopener noreferrer"
            >
              sample project
            </a>
            , and make sure you have{' '}
            <a
              href="https://drive.google.com/uc?export=download&id=1KW0tWmXhUvyBLFdyTutJnDm63-Skj5xS"
              target="_blank"
              rel="noopener noreferrer"
            >
              downloaded and installed the plugin already
            </a>
            .
          </p>
        </div>
        <div className={classes.innerSection}>
          <DocsTypography variant="h6" className={classes.innerTitle}>
            Upload your Design
          </DocsTypography>
          <p>Know the basics by watching and doing this tutorial. ☀️</p>
          <div className={classes.videoContainer}>
            <iframe
              width="864"
              height="486"
              src="https://www.youtube.com/embed/YQmRLqVC1vY"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            <Note
              title="Note 1"
              description={(
                <div>
                  You can modify as much as you like a prototype by uploading the same artboard.
                </div>
)}
            />
            <Note
              title="Note 2"
              description={(
                <div>
                  Want to create a different prototype? Just use or create another artboard.
                </div>
)}
            />
          </div>
        </div>
        <div className={classes.innerSection}>
          <DocsTypography variant="h6" className={classes.innerTitle}>
            Add Interactivity
          </DocsTypography>
          <p>You will create your first interactive component here. 🎸</p>
          <div className={classes.videoContainer}>
            <iframe
              width="864"
              height="486"
              src="https://www.youtube.com/embed/Ts2APVoewHc"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            <Note
              title="Note"
              description={(
                <div>
                  You can `float` any component and layer on your prototypes, check our{' '}
                  <Link to="/docs/components#states">documentation</Link> to learn more.
                </div>
)}
            />
          </div>
        </div>
        <div className={classes.innerSection}>
          <DocsTypography variant="h6" className={classes.innerTitle}>
            More Components
          </DocsTypography>
          <p>What about adding a couple of input fields? 😎</p>
          <div className={classes.videoContainer}>
            <iframe
              width="864"
              height="486"
              src="https://www.youtube.com/embed/fDbkTvdT-Aw"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            <Note
              title="Note"
              description={(
                <div>
                  Inputs can have many different states, check our{' '}
                  <Link to="/docs/components#inputs">documentation</Link> to learn more.
                </div>
)}
            />
          </div>
        </div>
        <div className={classes.innerSection}>
          <DocsTypography variant="h6" className={classes.innerTitle}>
            Add Transitions between views
          </DocsTypography>
          <p>Time to wrap-up with some transitions 💫</p>
          <div className={classes.videoContainer}>
            <iframe
              width="864"
              height="486"
              src="https://www.youtube.com/embed/BHjkL8rK6EI"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </>
    )
  }
}

Tutorials.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Tutorials)
