export const TestPage = '/test-123'
export const LandingPage = '/'
export const DocsPage = '/docs/:sectionId?/:subSectionId?'
export const LandingPageSignUp = '/signup'
export const LivePage = '/live/:projectId'
export const MakerPage = '/maker/:projectId'
export const EtsyMakerPage = '/etsymaker/:projectId'
export const ProjectPage = '/:projectId'
export const ScreenPage = '/:projectId/:screenId'
export const AdminScreenPage = '/:projectId/:screenId/admin'
