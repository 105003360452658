/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

import ComponentIdentifier from 'docs/components/ComponentIdentifier'

import styles from 'docs/DocsPageStyles'

const DocsTypography = withStyles(theme => ({
  root: {
    color: theme.palette.black65,
    fontFamily: 'Open Sans',
  },
}))(Typography)

class DocsInputs extends Component {
  render() {
    const { classes } = this.props

    return (
      <a name="inputs" className={classes.subSectionLink}>
        <a href="#inputs">
          <DocsTypography variant="h6" className={classes.contentSubTitle}>
            Inputs
          </DocsTypography>
        </a>
        <div className={classes.subSectionContent}>
          <ComponentIdentifier name="input" />
          <p>
            An input is usually represented by a group of layers that contains a <span>text</span>{' '}
            layer and a <span>shape</span> layer. The text will become the placeholder or label in
            runtime. It usually looks like this:
          </p>
          <div className={classes.imageContainer}>
            <img src="/images/docs/layers-input.png" alt="Input layers" />
          </div>
          <p>Some samples you can easily create:</p>
          <div className={classes.imageContainer}>
            <img src="/images/docs/sample-inputs.png" alt="Sample inputs" />
          </div>
        </div>
      </a>
    )
  }
}

DocsInputs.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  routeParams: PropTypes.object,
}

export default withStyles(styles)(DocsInputs)
