import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

import { findToriiPropByName } from 'utils'

import styles from './ToriiAppBarStyles'

const Background = {
  prop: '__background__',
  value: '#f5f5f5',
}

const Title = {
  prop: '__title__',
  value: {
    color: '#000',
    text: 'Title',
  },
}

class ToriiAppBar extends Component {
  render() {
    const { classes, props } = this.props

    const { value: backgroundValue } = findToriiPropByName(props, Background.prop) || Background
    const { value: titleValue } = findToriiPropByName(props, Title.prop) || Title

    const containerStyle = {
      backgroundColor: backgroundValue,
    }

    const titleStyle = {
      color: titleValue.color,
    }

    return (
      <div className={classes.container} style={containerStyle}>
        <Typography variant="h5" className={classes.title} style={titleStyle}>
          {titleValue.text}
        </Typography>
      </div>
    )
  }
}

ToriiAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  props: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default withStyles(styles)(ToriiAppBar)
