import blue from '@material-ui/core/colors/blue'

const jssStyles = theme => ({
  container: {
    position: 'absolute',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      border: '1px solid transparent',
    },
  },
  containerHovered: {
    '&:before': {
      borderColor: blue[100],
    },
  },
  containerSelected: {
    '&:before': {
      borderColor: blue[400],
    },
  },
  containerMove: {
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
  disableSelection: {
    '&:before': {
      content: 'none',
    },
  },
})

export default jssStyles
