import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import values from 'lodash/values'
import find from 'lodash/find'
import { withStyles } from '@material-ui/core/styles'

import { processLayerPosition, parseLayerName } from 'utils'

import Layer from 'components/Layer'

import styles from './CheckboxStyles'

const CheckedModValue = 'checked'

class Checkbox extends Component {
  state = {
    checked: null,
  }

  componentWillMount() {
    const {
      layer: { layers },
    } = this.props

    const initialLayer = find(values(layers), layer => !!layer.visible)

    if (!initialLayer) {
      return
    }

    const { modifierName } = parseLayerName(initialLayer.name)
    this.setState({ checked: modifierName === CheckedModValue })
  }

  toggleCheckedState = () => {
    const { checked } = this.state
    this.setState({ checked: !checked })
  }

  render() {
    const {
      classes,
      layer,
      layer: {
        frame,
        frame: { width, height },
        layers,
      },
      artboardEvents,
      storageBucketId,
      scaleRatio,
      editMode,
    } = this.props
    const { checked } = this.state

    const position = processLayerPosition(layer, scaleRatio)

    const containerStyles = {
      ...position,
      width: width * scaleRatio,
      height: height * scaleRatio,
    }

    return (
      <button
        className={classes.container}
        style={containerStyles}
        type="button"
        onClick={this.toggleCheckedState}
      >
        <>
          <div className={classes.overlay} />
          {layer.layers &&
            values(layers).map((componentLayer, index) => {
              const { modifierName } = parseLayerName(componentLayer.name)

              const layerContainerClassNames = classNames({
                [classes.layerContainer]: true,
                [classes.layerContainerActive]:
                  (checked && modifierName === CheckedModValue) ||
                  (!checked && modifierName !== CheckedModValue),
              })

              return (
                <div className={layerContainerClassNames} key={componentLayer.name}>
                  <Layer
                    layer={componentLayer}
                    artboardFrame={frame}
                    artboardEvents={artboardEvents}
                    storageBucketId={storageBucketId}
                    scaleRatio={scaleRatio}
                    editMode={editMode}
                  />
                </div>
              )
            })}
        </>
      </button>
    )
  }
}

Checkbox.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  artboardEvents: PropTypes.object,
  storageBucketId: PropTypes.string,
  scaleRatio: PropTypes.number,
  editMode: PropTypes.bool,
}

export default withStyles(styles)(Checkbox)
