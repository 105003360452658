import * as layerTypes from 'constants/layerTypes'
import * as toolStates from 'constants/toolStates'

// Tool Types

export const TextTool = 'TextTool'
export const LineTool = 'LineTool'

// Tool Configs

export const TextToolConfig = {
  name: TextTool,
  shortcutId: 'textTool',
  tooltipText: 'Add Text',
  shortcutKey: 't',
  shortcutDesc: 'Text tool',
  createsLayerType: layerTypes.TextLayer,
  toolsStateKey: 'textToolState',
  iconName: TextTool,
  steps: toolStates.TextToolSteps,
  cursor: 'text',
}

export const LineToolConfig = {
  name: LineTool,
  shortcutId: 'lineTool',
  tooltipText: 'Add Line',
  shortcutKey: 'l',
  shortcutDesc: 'Line tool',
  createsLayerType: layerTypes.LineLayer,
  toolsStateKey: 'lineToolState',
  iconName: LineTool,
  steps: toolStates.LineToolSteps,
  cursor: 'crosshair',
}

const toolConfigList = [TextToolConfig, LineToolConfig]

export default toolConfigList
