import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import ShareIcon from '@material-ui/icons/Share'
import FavoriteIcon from '@material-ui/icons/Favorite'
import SearchIcon from '@material-ui/icons/Search'

import { withStyles } from '@material-ui/core/styles'

import TextBlock from '_blocks/TextBlock'

import styles from './MaterialAppBarStyles'

class MaterialAppBar extends React.Component {
  handleTextChange = (e, value) => {
    const {
      symbolProps: {
        text: { id },
      },
      onChange,
    } = this.props

    onChange && onChange(e, 'changeOverride', { id, value })
  }

  render() {
    const {
      classes,
      layer: {
        frame: { width, height },
      },
      symbolProps: { text },
      isEditMode,
      artboardEvents,
    } = this.props

    const hasScrolled = artboardEvents && !!artboardEvents.scrollTop

    const appBarStyles = {
      width,
      height: !hasScrolled ? height : 64,
    }

    return (
      <AppBar className={classes.container} position="static" style={appBarStyles}>
        <Toolbar className={classes.toolbar}>
          <IconButton edge="start" color="inherit" aria-label="Open drawer">
            <MenuIcon />
          </IconButton>
          {hasScrolled && (
            <Typography className={classes.title} variant="h6" color="inherit">
              {isEditMode ? (
                <TextBlock value={text.value} onChange={this.handleTextChange} />
              ) : (
                text.value
              )}
            </Typography>
          )}
          <div className={classes.rightIconContainer}>
            <IconButton color="inherit" aria-label="Open drawer">
              <ShareIcon />
            </IconButton>
            <IconButton color="inherit" aria-label="Open drawer">
              <FavoriteIcon />
            </IconButton>
            <IconButton color="inherit" aria-label="Open drawer">
              <SearchIcon />
            </IconButton>
          </div>
        </Toolbar>
        {!hasScrolled && (
          <Typography
            className={classNames(classes.title, classes.titleExpand)}
            variant="h5"
            color="inherit"
          >
            {isEditMode ? (
              <TextBlock value={text.value} onChange={this.handleTextChange} />
            ) : (
              text.value
            )}
          </Typography>
        )}
      </AppBar>
    )
  }
}

MaterialAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object,
  symbolProps: PropTypes.object,
  styleProps: PropTypes.object,
  artboardEvents: PropTypes.object,
  isEditMode: PropTypes.bool,
  onChange: PropTypes.func,
}

export default withStyles(styles)(MaterialAppBar)
