const SidebarWidthPx = 184

const jssStyles = theme => ({
  container: {
    color: theme.palette.black65,
    overflow: 'hidden',
    display: 'flex',
    fontFamily: 'Open Sans',
    '& a': {
      color: theme.palette.primary.color,
      borderBottom: '1px solid',
    },
    '& h6': {
      paddingBottom: 12,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'flex-end',
    height: 50,
    width: '100%',
    '& button': {
      display: 'none',
      position: 'fixed',
      backgroundColor: 'white',
      zIndex: 50,
    },
  },
  headerLogo: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    paddingRight: 24,
    opacity: 0,
  },
  headerTitle: {
    fontSize: 23,
  },
  contentTitle: {
    fontSize: 27,
    fontWeight: 'bold',
  },
  innerSection: {
    margin: '20px 0 80px',
  },
  subSectionTitle: {
    fontSize: 21,
    fontWeight: 600,
    marginBottom: 27,
  },
  innerTitle: {
    fontSize: 18,
    paddingBottom: 12,
  },
  sidebar: {
    backgroundColor: 'white',
    height: '100%',
    width: SidebarWidthPx,
    padding: '16px 0 0 60px',
    zIndex: 10,
  },
  sidebarContent: {
    marginTop: 58,
  },
  sectionsContainer: {
    marginTop: 20,
  },
  sectionActive: {
    color: theme.palette.primary.color,
    fontWeight: 600,
  },
  subSectionActive: {
    color: theme.palette.primary.color,
  },
  sectionTitle: {
    fontSize: 13,
    marginTop: 8,
  },
  sectionSubtitle: {
    fontSize: 11,
    marginLeft: 12,
    marginTop: 7,
  },
  content: {
    position: 'relative',
    padding: '2.2em 0',
    maxWidth: 1200,
    margin: '0 auto',
    width: '100%',
  },
  subContent: {
    fontSize: 14,
    marginTop: 46,
    marginRight: 24,

    '& p': {
      paddingBottom: 24,
      lineHeight: '24px',

      '& span': {
        color: theme.palette.primary.color,
      },

      '& code': {
        fontSize: 11,
        padding: 4,
        borderRadius: 4,
        backgroundColor: '#f4f5f6',
      },
    },
  },
  separateContainer: {
    marginTop: 32,
  },
  subSectionContent: {
    marginTop: 32,
    marginBottom: 46,
  },
  subSectionLink: {
    color: 'inherit !important',
  },
  imageContainer: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '16px 0 24px',
  },
  videoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 32,

    '& > *': {
      width: '80%',
    },
  },
  emoji: {
    paddingRight: 16,
    fontSize: 24,
  },
  layout: {
    // display: 'flex',
    // flexDirection: 'column',
    // flex: 1,
    paddingLeft: 32,
    overflow: 'auto',
  },
  logo: {
    height: 16,
  },
  strong: {
    fontWeight: 'bold',
  },
  versionTitle: {
    fontSize: 17,
  },
  versionNumber: {
    marginLeft: 8,
  },
  comparisonContainer: {
    display: 'flex',
    marginBottom: 40,

    '& > *': {
      flex: 1,
      margin: '0 4px',

      '& > div': {
        borderTop: '4px solid',
        paddingTop: 10,
        fontSize: 15,
        marginTop: 8,
      },
    },

    '& > div:nth-child(1)': {
      color: '#D32F2F',
    },

    '& > div:nth-child(2)': {
      color: '#4CAF50',
    },

    '& img': {
      width: '100%',
    },
  },
  footer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'flex-end',
    marginBottom: 23,

    '& p': {
      fontSize: 13,
      width: 250,
      lineHeight: '32px',
    },
  },
  '@media (max-width: 900px)': {
    header: {
      '& button': {
        display: 'block',
      },
    },
    sidebar: {
      position: 'fixed',
      transform: `translateX(-${SidebarWidthPx}px)`,
      paddingLeft: 24,
      '& img': {
        display: 'none',
      },
    },
    sidebarActive: {
      boxShadow: '0 0 10px rgba(0,0,0,0.2)',
      transform: 'translateX(0)',
    },
    headerLogo: {
      opacity: 1,
    },
  },
})

export default jssStyles
