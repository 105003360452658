import ReactGA from 'react-ga'
import instanceConfig from '../config/instances'

const gaConfig = instanceConfig.analytics

export function initialize() {
  ReactGA.initialize(gaConfig.id, gaConfig.options)
}

export function logPageView(page) {
  ReactGA.set({ page })
  ReactGA.pageview(page)
}

export function logEvent(fields) {
  ReactGA.event(fields)
}

export function logTiming(fields) {
  ReactGA.timing(fields)
}

export function handleLocationChange(location) {
  logPageView(location.pathname)
}

export const EVENT_CATEGORIES = {
  ToriiStudioPage: 'ToriiStudioPage',
  LandingPage: 'LandingPage',
  MakerPage: 'MakerPage',
  ScreenPage: 'SreenPage',
  AdminScreenPage: 'AdminScreenPage',
  SIGN_UP: 'SignUp',
  SIGN_IN: 'SignIn',
  SHORTCUTS: 'SHORTCUTS',
  TOOLBAR: 'TOOLBAR'
}

export const EVENT_ACTIONS = {
  CHANGE: 'change',
  CLICK: 'click',
  SUBMIT: 'submit',
  ERROR: 'error',
  SHORTCUTS_CLICK: 'shortcuts click'
}

export const EVENT_LABELS = {
  TRY_OUT: 'tryout for free',
  LAYER: 'layer',
  DOWNLOAD: 'download'
}
