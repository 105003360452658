import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import ConditionalWrap from 'conditional-wrap'
import { withStyles } from '@material-ui/core/styles'

import Fab from '@material-ui/core/Fab'

import { findToriiPropByName } from 'utils'
import styles from './ToriiFabStyles'

const Icon = {
  prop: '__icon__',
  value: '',
}

const Hotspot = {
  prop: 'Hotspot',
}

const OffsetPosition = 35

const getStylePositionFromObject = (object, frame) => {
  const frameLeftPos = window.innerWidth / 2 - frame.width / 2
  const frameRightPos = frameLeftPos + frame.width

  // top-left area position
  if (object.x < frame.width / 2 && object.y < frame.height / 2) {
    return {
      top: OffsetPosition,
      left: frameLeftPos + OffsetPosition,
    }
  }

  // top-right area position
  if (object.x > frame.width / 2 && object.y < frame.height / 2) {
    return {
      top: OffsetPosition,
      left: frameRightPos - OffsetPosition - object.width,
    }
  }

  // bottom-left area position
  if (object.x < frame.width / 2 && object.y > frame.height / 2) {
    return {
      bottom: OffsetPosition,
      left: frameLeftPos + OffsetPosition,
    }
  }
  if (object.x > frame.width / 2 && object.y > frame.height / 2) {
    // bottom-right area position
    return {
      bottom: OffsetPosition,
      left: frameRightPos - OffsetPosition - object.width,
    }
  }
}

class ToriiFab extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    // Not re-render when artboard events have changed
    return !isEqual(omit(nextProps, ['artboardEvents']), omit(this.props, ['artboardEvents']))
  }

  handleArtboardFlowChange = e => {
    e.stopPropagation()
    const { props, onArtboardFlowChange } = this.props
    const hotspot = findToriiPropByName(props, Hotspot.prop)

    hotspot && onArtboardFlowChange && onArtboardFlowChange(hotspot.value)
  }

  render() {
    const { classes, props, frame, artboardFrame } = this.props

    const { value: iconValue } = findToriiPropByName(props, Icon.prop) || Icon
    const isHotspot = !!findToriiPropByName(props, Hotspot.prop) || false

    const StyledFab = withStyles(theme => ({
      root: {
        color: '#70a6ba',
      },
    }))(Fab)

    const containerStyles = {
      ...getStylePositionFromObject(frame, artboardFrame),
    }

    const ToriiFabComponent = (
      <div className={classes.container} style={containerStyles}>
        <StyledFab className={classes.fab}>
          <img className={classes.fabIcon} src={`data:image/png;base64,${iconValue}`} alt="" />
        </StyledFab>
      </div>
    )

    return (
      <ConditionalWrap
        condition={isHotspot}
        wrap={() => <div onClick={this.handleArtboardFlowChange}>{ToriiFabComponent}</div>}
      >
        {ToriiFabComponent}
      </ConditionalWrap>
    )
  }
}

ToriiFab.propTypes = {
  classes: PropTypes.object.isRequired,
  props: PropTypes.arrayOf(PropTypes.object).isRequired,
  frame: PropTypes.object.isRequired,
  artboardFrame: PropTypes.object,
  onArtboardFlowChange: PropTypes.func,
}

export default withStyles(styles)(ToriiFab)
