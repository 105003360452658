/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'

import AuthBetaPage from 'components/AuthBetaPage'
import ZendeskHelper from 'docs/components/ZendeskHelper'
import { CurrentVersion, Sections } from 'constants/docs'
import { loadFont } from 'utils'
import { getPageByName } from './pages'

import styles from './DocsPageStyles'

const DocsTypography = withStyles(theme => ({
  root: {
    color: theme.palette.black65,
    fontFamily: 'Open Sans',
  },
}))(Typography)

class DocsPage extends Component {
  state = {
    activeSectionId: null,
    activeSubSectionId: null,
    drawerActive: false,
  }

  componentWillMount() {
    this.updateRoutes(this.props)
  }

  componentWillReceiveProps(props) {
    this.updateRoutes(props)
  }

  updateRoutes(props) {
    const {
      routeParams: { sectionId, subSectionId },
    } = props

    const activeSectionId = sectionId || Sections[0].id

    const { items } = Sections.find(section => section.id === activeSectionId)

    const activeSubSectionId =
      subSectionId || (items && (items.find(section => section.id === subSectionId) || items[0].id))

    this.setState({
      activeSectionId,
      activeSubSectionId,
    })
  }

  openDrawer = e => {
    e.stopPropagation(e)
    this.setState({ drawerActive: true })
  }

  closeDrawer = () => {
    this.setState({ drawerActive: false })
  }

  render() {
    const { classes } = this.props
    const { activeSectionId, activeSubSectionId, drawerActive } = this.state

    const sectionActive = Sections.find(section => section.id === activeSectionId)
    const subSectionActive =
      sectionActive.items && sectionActive.items.find(section => section.id === activeSubSectionId)

    const sidebarClassNames = classNames({
      [classes.sidebar]: true,
      [classes.sidebarActive]: drawerActive,
    })

    return (
      <AuthBetaPage>
        <ZendeskHelper>
          <div className={classes.container} onClick={this.closeDrawer}>
            <div className={sidebarClassNames}>
              <img className={classes.logo} src="/images/docs/logo.png" alt="Logo" />
              <div className={classes.sidebarContent}>
                <DocsTypography variant="body1" className={classes.versionTitle}>
                  <span className={classes.strong}>Version</span>
                  <span className={classes.versionNumber}>{CurrentVersion}</span>
                </DocsTypography>
                <ul className={classes.sectionsContainer}>
                  {Sections.map(section => {
                    const isSectionActive = section.id === activeSectionId
                    const sectionTitleClassNames = classNames({
                      [classes.sectionTitle]: true,
                      [classes.sectionActive]: isSectionActive,
                    })

                    return (
                      <li key={section.name}>
                        <Link to={`/docs/${section.id}`}>
                          <DocsTypography variant="subtitle1" className={sectionTitleClassNames}>
                            {section.name}
                          </DocsTypography>
                        </Link>
                        {isSectionActive && (
                          <ul className={classes.subsectionsContainer}>
                            {section.items &&
                              section.items.map(sectionItem => {
                                const isSubSectionActive = sectionItem.id === activeSubSectionId

                                const sectionItemClassNames = classNames({
                                  [classes.sectionSubtitle]: true,
                                  [classes.subSectionActive]: isSubSectionActive,
                                })

                                return (
                                  <li key={sectionItem.name}>
                                    {sectionActive.paginated && (
                                      <Link to={`/docs/${section.id}/${sectionItem.id}`}>
                                        <DocsTypography
                                          variant="subtitle2"
                                          className={sectionItemClassNames}
                                        >
                                          {sectionItem.name}
                                        </DocsTypography>
                                      </Link>
                                    )}
                                    {!sectionActive.paginated && (
                                      <a href={`#${sectionItem.id}`}>
                                        <DocsTypography
                                          variant="subtitle2"
                                          className={sectionItemClassNames}
                                        >
                                          {sectionItem.name}
                                        </DocsTypography>
                                      </a>
                                    )}
                                  </li>
                                )
                              })}
                          </ul>
                        )}
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
            <div className={classes.layout}>
              <div className={classes.header}>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={this.openDrawer}
                >
                  <MenuIcon />
                </IconButton>
                <div className={classes.headerLogo}>
                  <img className={classes.logo} src="/images/docs/logo.png" alt="Logo" />
                </div>
              </div>
              <div className={classes.content}>
                <DocsTypography variant="h4" className={classes.contentTitle}>
                  {sectionActive.name}
                </DocsTypography>

                <div className={classes.subContent}>
                  {sectionActive.paginated &&
                    subSectionActive &&
                    getPageByName(sectionActive.name, subSectionActive.componentName)}
                  {!sectionActive.paginated &&
                    sectionActive.items.map(page => getPageByName(sectionActive.name, page.name))}
                </div>
              </div>
              <div className={classes.footer}>
                <DocsTypography className={classes.footerSignature}>
                  Built with lots of ❤️ in San Francisco Copyright © 2019 Torii
                </DocsTypography>
              </div>
            </div>
          </div>
        </ZendeskHelper>
      </AuthBetaPage>
    )
  }
}

DocsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  routeParams: PropTypes.object,
}

const mapStateToProps = (state, ownProps) => {
  return {
    routeParams: ownProps.match.params,
  }
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    null
  ),
  withStyles(styles)
)(DocsPage)
