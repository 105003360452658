import React from 'react'
import PropTypes from 'prop-types'
import pick from 'lodash/pick'
import throttle from 'lodash/throttle'
import ConditionalWrap from 'conditional-wrap'
import { withStyles } from '@material-ui/core/styles'

import Dropzone from '_common/Dropzone'

import styles from './ToriiImageSliderStyles'

const ThrottleIntervalMs = 1000

class ToriiImageSlider extends React.Component {
  slideScrollToLastElement = () => {
    this.sliderRef.current.lastChild.scrollIntoView({
      behavior: 'smooth',
    })
  }

  sliderRef = React.createRef()

  throttledSlideScrollToLastElement = throttle(this.slideScrollToLastElement, ThrottleIntervalMs)

  handleFilesDropped = files => {
    const { onChange } = this.props

    files.forEach(file => {
      const reader = new FileReader()
      reader.onload = e => {
        const base64Content = e.target.result.split('base64,')[1]
        onChange && onChange(e, 'addOverride', { value: base64Content, property: 'image' })
        this.throttledSlideScrollToLastElement()
      }
      reader.readAsDataURL(file)
    })
  }

  render() {
    const {
      classes,
      symbolProps: { frame },
      overrides,
      isEditMode,
    } = this.props

    const containerStyles = (frame && pick(frame.value, ['width', 'height'])) || {}

    const SliderComponent = (
      <div className={classes.container} style={containerStyles} ref={this.sliderRef}>
        {overrides
          .filter(o => o.property === 'image')
          .map(o => (
            <img
              src={`data:image/png;base64,${o.value}`}
              alt="Slide item"
              className={classes.image}
              key={`${o.id}-${o.path}`}
            />
          ))}
      </div>
    )

    return (
      <ConditionalWrap
        condition={isEditMode}
        wrap={() => (
          <Dropzone onFilesAdded={this.handleFilesDropped} accept="image/jpeg, image/png">
            {SliderComponent}
          </Dropzone>
        )}
      >
        {SliderComponent}
      </ConditionalWrap>
    )
  }
}

ToriiImageSlider.propTypes = {
  classes: PropTypes.object.isRequired,
  symbolProps: PropTypes.object,
  overrides: PropTypes.arrayOf(PropTypes.object),
  isEditMode: PropTypes.bool,
  onChange: PropTypes.func,
}

export default withStyles(styles)(ToriiImageSlider)
