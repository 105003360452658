import update from 'immutability-helper'
import pickBy from 'lodash/pickBy'

import { setIn } from 'utils'
import initialState from './initialState'
import {
  SET_CANVAS_PROJECT,
  SET_HOVERED_LAYER,
  SET_SELECTED_LAYER,
  CREATE_LAYER,
  SET_LAYER_PROPERTY,
  REPLACE_LAYERS,
  REMOVE_LAYER,
  SET_DEFAULT_LAYER,
  RESET_TOOLS_STATE,
  SET_ACTIVE_TOOL,
  SET_TOOL_STEP,
  SET_ORIGIN_MOUSE_POSITION,
  SET_LINE_TOOL_START_ATTACHMENT,
} from '../constants/actionTypes'

export default (state = initialState.canvas, action) => {
  switch (action.type) {
    case SET_CANVAS_PROJECT: {
      const { project } = action.payload
      return update(state, {
        project: { $set: project },
      })
    }
    case SET_DEFAULT_LAYER: {
      const { defaultLayer } = action.payload
      return update(state, {
        defaultLayer: { $set: defaultLayer },
      })
    }
    case SET_HOVERED_LAYER:
      return update(state, {
        hoveredLayerId: { $set: action.layerId },
      })
    case SET_SELECTED_LAYER:
      return update(state, {
        selectedLayerId: { $set: action.layerId },
      })
    case CREATE_LAYER: {
      const { layer, parentRef } = action.payload
      const pathParent = parentRef ? `.${parentRef}.` : '.'
      const path = `project${pathParent}layers.${layer.id}`
      // New state is returned immutable
      return setIn(state, path, layer)
    }
    case SET_LAYER_PROPERTY: {
      const { layerId, propPath, value, parentRef } = action.payload

      const pathParent = parentRef ? `.${parentRef}.` : '.'
      const path = `project${pathParent}layers.${layerId}.${propPath}`

      // New state is returned immutable
      return setIn(state, path, value)
    }
    case REMOVE_LAYER: {
      const { layerId, parentRef } = action.payload
      const { project } = state

      const { layers } = parentRef ? project[parentRef] : project

      // Return a new object with any currently selected layers filtered out.
      const filteredLayers = pickBy(layers, layer => {
        return layer.id !== layerId
      })

      const pathParent = parentRef ? `.${parentRef}.` : '.'
      const path = `project${pathParent}layers`
      // New state is returned immutable
      return setIn(state, path, filteredLayers)
    }
    case REPLACE_LAYERS: {
      const { layers, parentRef } = action.payload
      const pathParent = parentRef ? `.${parentRef}.` : '.'
      const path = `project${pathParent}layers`
      return setIn(state, path, layers)
    }
    case SET_LINE_TOOL_START_ATTACHMENT: {
      return update(state, {
        toolsState: { lineToolState: { startAttachment: { $set: action.attachment } } },
      })
    }
    case SET_ACTIVE_TOOL:
      return update(state, {
        toolsState: {
          activeTool: { $set: action.toolName },
          toolStep: { $set: 0 },
        },
      })
    case SET_TOOL_STEP:
      return update(state, {
        toolsState: { toolStep: { $set: action.toolStep } },
      })
    case SET_ORIGIN_MOUSE_POSITION:
      return update(state, {
        originMousePosition: { $set: action.originMousePosition },
      })
    case RESET_TOOLS_STATE: {
      const resetState = { ...initialState.canvas.toolsState }
      return update(state, { toolsState: { $set: resetState } })
    }
    default:
      return state
  }
}
