/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import values from 'lodash/values'
import random from 'lodash/random'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-scroll'
import axios from 'axios'
import { withStyles } from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import CircularProgress from '@material-ui/core/CircularProgress'

import * as Analytics from 'analytics/GoogleAnalytics'
import { loadFont } from 'utils'

import CustomSnackbar from './CustomSnackbar'

import styles from './LandingPageStyles'

const FormUrl = 'https://script.google.com/macros/s/AKfycbxxO82-e_MSu7eaxn9QoZRnhM4zI6__3ckRJR9dghSIU7t7GXxg/exec'

const ServiceLabels = {
  PROTOTYPING: 'Prototyping',
  USER_TESTING: 'User Testing',
  DEVELOPMENT: 'Development'
}

const CurrentPrices = ['A', 'B', 'C']
// const CurrentPricingIndex = random(values(ServiceLabels).length - 1)
const CurrentPricingIndex = 0
const CurrentPrice = CurrentPrices[CurrentPricingIndex]

const encodePostData = data =>
  Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')

const CustomDialog = withStyles({
  '@media (max-width: 450px)': {
    paperWidthSm: {
      width: '95%',
      margin: 0
    }
  }
})(Dialog)

const ServicesData = [
  {
    label: ServiceLabels.PROTOTYPING,
    description: 'We create fully functional prototypes that look and feel like your final product',
    featuresIncluded: [
      'Pixel perfect prototypes',
      'Fully functional prototypes',
      'Built with custom ReactJS components',
      'Custom gestures for both Mobile And Desktop',
      'Fast loading',
      'Ready for User Testing'
    ],
    prices: [
      {
        hidePrice: true
      },
      {
        total: '$500',
        firstOption: '2 days',
        secondOption: '$35/hour'
      },
      {
        total: '$800',
        firstOption: '2 days',
        secondOption: '$50/hour'
      }
    ]
  },
  {
    label: ServiceLabels.USER_TESTING,
    description: 'We validate your prototypes, evaluate the metrics and discuss areas of improvement',
    featuresIncluded: [
      'Record screen & audio',
      'A/B Usability Testing',
      'Mobile & Tablet Testing',
      'Benchmarking Performance',
      'Time on Task',
      'Key Metrics',
      'Heatmaps'
    ],
    prices: [
      {
        hidePrice: true
      },
      {
        total: '$500',
        firstOption: '50 users',
        secondOption: '$10/addl user'
      },
      {
        total: '$1000',
        firstOption: '100 users',
        secondOption: '$20/addl user'
      }
    ]
  },
  {
    label: ServiceLabels.DEVELOPMENT,
    description: 'We create the final product for you making sure that it’s exactly what the user wants',
    featuresIncluded: [
      'Pixel perfect front-end',
      'Progressive Web Apps',
      'Fully responsive',
      'Cross browser compatibility',
      'SEO friendly',
      'Optimized files sizes',
      'Clean and readable code'
    ],
    prices: [
      {
        hidePrice: true
      },
      {
        total: '$4000',
        firstOption: '2 weeks',
        secondOption: '$50/hour'
      },
      {
        total: '$6000',
        firstOption: '2 weeks',
        secondOption: '$75/hour'
      }
    ]
  }
]

const CustomCheckbox = ({ classes, label, value, onChange }) => (
  <div className={classes.checkboxContainer} onClick={() => onChange && onChange(!value)}>
    {!value && <img src="/images/check-box.svg" alt="Checkbox" />}
    {value && <img src="/images/checked.svg" alt="Checkbox" />}
    <Typography variant="body1" className={classes.checkboxLabel}>
      {label}
    </Typography>
  </div>
)

CustomCheckbox.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func
}

const InitialFormValues = {
  name: null,
  email: null,
  message: null
}

const Notifications = {
  success: 'We will reach out to you shortly',
  error: 'Try again in a couple of minutes or email us directly at chao@torii.co'
}

const InitialCurrentNotification = { variant: null, message: null }

const InitialPricingDrawers = [false, false, false]

const isEmailValid = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return !!email && !!re.test(String(email).toLowerCase())
}

const LandingPage = ({ classes }) => {
  const [isPrototypingEnabled, setIsPrototypingEnabled] = useState(true)
  const [isUserTestingEnabled, setIsUserTestingEnabled] = useState(true)
  const [isDevelopmentEnabled, setIsDevelopmentEnabled] = useState(true)
  const [isDialogOpened, setIsDialogOpened] = useState(false)
  const [pricingDrawers, setPricingDrawers] = useState(InitialPricingDrawers)
  const [formValues, setFormValues] = useState(InitialFormValues)
  const [currentNotification, setCurrentNotification] = useState(InitialCurrentNotification)
  const [isContentScrolled, setIsContentScrolled] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleScroll = () => setIsContentScrolled(!!window.pageYOffset)

  useEffect(() => {
    Analytics.logEvent({
      category: Analytics.EVENT_CATEGORIES.ToriiStudioPage,
      action: Analytics.EVENT_ACTIONS.CLICK,
      label: `[${CurrentPrice}] TRYOUT`
    })

    loadFont('ProximaNova-Regular', 'ttf')
    loadFont('ProximaNova-Extrabold', 'ttf')

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const openDialog = () => setIsDialogOpened(true)

  const closeDialog = () => {
    setIsDialogOpened(false)
    setFormValues(InitialFormValues)
  }

  const isFormReadyToSend = () => formValues.name && isEmailValid(formValues.email) && formValues.message

  const handleFormChange = (e, valueType) =>
    setFormValues({
      ...formValues,
      [valueType]: e.target.value
    })

  const toggleServiceDrawer = drawerIndex => {
    const newPricingDrawer = [...pricingDrawers]
    newPricingDrawer[drawerIndex] = !newPricingDrawer[drawerIndex]
    setPricingDrawers(newPricingDrawer)
  }

  const getPriceContainer = service => {
    const prices = service.prices[CurrentPricingIndex]

    if (prices.hidePrice) return null

    return (
      <div className={classes.priceContainer}>
        <div className={classes.priceCenter}>
          <Typography variant="body1" className={classes.totalPrice}>
            {prices.total}
          </Typography>
          <div className={classes.subPrices}>
            <Typography variant="body1" className={classes.subPrice}>
              {prices.firstOption}
            </Typography>
            <Typography variant="body1" className={classes.subPrice}>
              {prices.secondOption}
            </Typography>
          </div>
        </div>
      </div>
    )
  }

  const logAnalyticsAfterSubmit = () => {
    Analytics.logEvent({
      category: Analytics.EVENT_CATEGORIES.ToriiStudioPage,
      action: Analytics.EVENT_ACTIONS.SUBMIT,
      label: `[${CurrentPrice}] SUBMITTED`
    })

    if (isPrototypingEnabled) {
      Analytics.logEvent({
        category: Analytics.EVENT_CATEGORIES.ToriiStudioPage,
        action: Analytics.EVENT_ACTIONS.SUBMIT,
        label: `[${CurrentPrice}] SUBMITTED: PROTOTYPING`
      })
    }

    if (isUserTestingEnabled) {
      Analytics.logEvent({
        category: Analytics.EVENT_CATEGORIES.ToriiStudioPage,
        action: Analytics.EVENT_ACTIONS.SUBMIT,
        label: `[${CurrentPrice}] SUBMITTED: USER_TESTING`
      })
    }

    if (isDevelopmentEnabled) {
      Analytics.logEvent({
        category: Analytics.EVENT_CATEGORIES.ToriiStudioPage,
        action: Analytics.EVENT_ACTIONS.SUBMIT,
        label: `[${CurrentPrice}] SUBMITTED: DEVELOPMENT`
      })
    }
  }

  const submitForm = async e => {
    e.preventDefault()

    if (!isFormReadyToSend()) return

    setLoading(true)

    const [name, ...lastName] = formValues.name.split(' ')

    const { status } = await axios.post(
      FormUrl,
      encodePostData({
        name,
        lastName: lastName || '',
        email: formValues.email,
        message: formValues.message,
        prototyping: isPrototypingEnabled,
        userTesting: isUserTestingEnabled,
        development: isDevelopmentEnabled,
        pricing: CurrentPrice
      }),
      {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*'
      }
    )

    let typeStatus = null
    if (status === 200) {
      typeStatus = 'success'
      setIsDialogOpened(false)
    } else {
      typeStatus = 'error'
    }

    logAnalyticsAfterSubmit()

    setCurrentNotification({
      variant: typeStatus,
      message: `${name} ${Notifications[typeStatus].toLowerCase()}`
    })

    setLoading(false)
  }

  const headerClassNames = classNames({
    [classes.header]: true,
    [classes.scrolling]: isContentScrolled
  })

  return (
    <>
      <div className={classes.container}>
        <CustomSnackbar open={currentNotification.variant} variant={currentNotification.variant} message={currentNotification.message} />
        <div className={headerClassNames}>
          <img className={classes.logo} src="/images/torii-logo.png" alt="Logo" />
          <div className={classes.flex} />
          <div className={classes.headerActions}>
            <Link to="services" spy="true" smooth="true" duration={500}>
              Services
            </Link>
            <Link to="pricing" spy="true" smooth="true" duration={500}>
              Pricing
            </Link>
            <Link to="about" spy="true" smooth="true" duration={500}>
              About
            </Link>
            <div href="#connect" className={classes.ctaButton} onClick={openDialog}>
              Get In Touch
            </div>
          </div>
        </div>
        <div className={classes.main}>
          <div className={classes.hero}>
            <Typography variant="h1" className={classes.headline}>
              Deliver what your user wants
            </Typography>
            <Typography variant="body1" className={classes.subheading}>
              We validate and deliver your ideas focusing on the user first
            </Typography>
            <div className={classes.servicesList}>
              <CustomCheckbox
                classes={classes}
                label={ServiceLabels.PROTOTYPING}
                value={isPrototypingEnabled}
                onChange={value => setIsPrototypingEnabled(value)}
              />
              <CustomCheckbox
                classes={classes}
                label={ServiceLabels.USER_TESTING}
                value={isUserTestingEnabled}
                onChange={value => setIsUserTestingEnabled(value)}
              />
              <CustomCheckbox
                classes={classes}
                label={ServiceLabels.DEVELOPMENT}
                value={isDevelopmentEnabled}
                onChange={value => setIsDevelopmentEnabled(value)}
              />
            </div>
            <button className={classes.ctaBoldLarge} type="button" onClick={openDialog}>
              Contact Us
            </button>
          </div>
          <a name="services" className={classes.heroServices}>
            {ServicesData.map(service => {
              if (
                (service.label === ServiceLabels.PROTOTYPING && !isPrototypingEnabled) ||
                (service.label === ServiceLabels.USER_TESTING && !isUserTestingEnabled) ||
                (service.label === ServiceLabels.DEVELOPMENT && !isDevelopmentEnabled)
              )
                return null

              return (
                <div className={classes.service} key={service.label}>
                  <Typography variant="h6" className={classes.serviceLabel}>
                    {service.label}
                  </Typography>
                  <Typography variant="body1" className={classes.serviceDescription}>
                    {service.description}
                  </Typography>
                  <div className={classes.flex} />
                  <Link to="pricing" spy="true" smooth="true" duration={500} className={classes.ctaToPricing}>
                    Learn More
                  </Link>
                </div>
              )
            })}
          </a>
          <a name="pricing" className={classes.heroPricing}>
            <div className={classes.pricingContainer}>
              {ServicesData.map((service, serviceIndex) => {
                if (
                  (service.label === ServiceLabels.PROTOTYPING && !isPrototypingEnabled) ||
                  (service.label === ServiceLabels.USER_TESTING && !isUserTestingEnabled) ||
                  (service.label === ServiceLabels.DEVELOPMENT && !isDevelopmentEnabled)
                )
                  return null

                const pricingServiceClassNames = classNames({
                  [classes.pricingService]: true,
                  [classes.showFeatures]: pricingDrawers[serviceIndex]
                })

                return (
                  <div className={pricingServiceClassNames} key={service.label}>
                    <div className={classes.pricingDrawers}>
                      {(!pricingDrawers[serviceIndex] && (
                        <IconButton
                          className={classes.pricingDrawerIcon}
                          color="inherit"
                          aria-label="Open drawer"
                          onClick={() => toggleServiceDrawer(serviceIndex)}
                        >
                          <KeyboardArrowDownIcon />
                        </IconButton>
                      )) || (
                        <IconButton
                          className={classes.pricingDrawerIcon}
                          color="inherit"
                          aria-label="Close drawer"
                          onClick={() => toggleServiceDrawer(serviceIndex)}
                        >
                          <KeyboardArrowUpIcon />
                        </IconButton>
                      )}
                    </div>

                    <Typography variant="body1" className={classes.pricingLabel}>
                      {service.label}
                    </Typography>

                    <Typography variant="body1" className={classes.pricingDescription}>
                      {service.description}
                    </Typography>

                    <div className={classes.feature}>
                      {service.featuresIncluded.map(feature => (
                        <Typography variant="body1" className={classes.pricingFeature} key={feature}>
                          {feature}
                        </Typography>
                      ))}
                    </div>
                    <div className={classes.flex} />
                    {getPriceContainer(service)}
                  </div>
                )
              })}
            </div>
            <div className={classes.pricingActions}>
              <button className={classes.ctaWhiteButton} type="button" onClick={openDialog}>
                Request a Quote
              </button>
            </div>
          </a>
          <a name="about" className={classes.heroAbout}>
            <div className={classes.centerContent}>
              <Typography variant="h2" className={classes.aboutLabel}>
                Why us?
              </Typography>
              <Typography variant="body1" className={classes.aboutDescription}>
                We are <span>The Studio</span> that creates world-class product experiences by gathering world-class experts that have worked for clients like
                Google and Apple during an era of bloated agencies and unreliable freelancers.
              </Typography>
            </div>
            <div className={classes.centerContent}>
              <button type="button" className={classes.ctaBlackButton} onClick={openDialog}>
                Connect
              </button>
            </div>
            <div className={classes.locationsContent}>
              <a href="https://www.google.com/maps/place/999+Bush+St+%232nd+floor,+San+Francisco,+CA+94109" target="_blank">
                <Typography variant="body1">SAN FRANCISCO</Typography>
              </a>
              <a href="https://www.google.com/maps/place/606+W+57th+St+%2328th+floor,+New+York,+NY+10019" target="_blank">
                <Typography variant="body1">NEW YORK</Typography>
              </a>
            </div>
          </a>
        </div>
      </div>
      <CustomDialog open={isDialogOpened} onClose={closeDialog} maxWidth="sm">
        <DialogTitle className={classes.dialogTitle}>Which services are you interested in?</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            <div className={classes.servicesList}>
              <CustomCheckbox
                classes={classes}
                label={ServiceLabels.PROTOTYPING}
                value={isPrototypingEnabled}
                onChange={value => setIsPrototypingEnabled(value)}
              />
              <CustomCheckbox
                classes={classes}
                label={ServiceLabels.USER_TESTING}
                value={isUserTestingEnabled}
                onChange={value => setIsUserTestingEnabled(value)}
              />
              <CustomCheckbox
                classes={classes}
                label={ServiceLabels.DEVELOPMENT}
                value={isDevelopmentEnabled}
                onChange={value => setIsDevelopmentEnabled(value)}
              />
            </div>
            <div className={classes.dialogForm}>
              <TextField
                label="Your Message"
                multiline
                rows="8"
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
                value={formValues.message}
                onChange={e => handleFormChange(e, 'message')}
              />
              <div className={classes.inputsOneLine}>
                <TextField label="Your Name" variant="outlined" value={formValues.name} onChange={e => handleFormChange(e, 'name')} />
                <TextField label="Your Email" variant="outlined" value={formValues.email} onChange={e => handleFormChange(e, 'email')} />
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <button className={classes.button} type="button" disabled={!isFormReadyToSend()} onClick={submitForm}>
            Send
          </button>
        </DialogActions>
        {loading && (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        )}
      </CustomDialog>
    </>
  )
}

LandingPage.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
  return {
    routeParams: ownProps.match.params
  }
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    null
  ),
  withStyles(styles)
)(LandingPage)
