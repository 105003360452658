// Layer Types
export const TextLayer = 'TextLayer'
export const LineLayer = 'LineLayer'

export const TextLayerConfig = {
  type: 'text',
  value: 'Type something',
}

export const LineLayerConfig = {
  type: 'shape',
  shapeType: 'line',
}
