/* eslint-disable react/no-danger */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'

class ContentEditable extends Component {
  shouldComponentUpdate(nextProps) {
    const { style } = this.props
    return !isEqual(style, nextProps.style) || nextProps.value !== this.textRef.current.innerHTML
  }

  textRef = React.createRef()

  handleInputKeyDown = e => e.stopPropagation()

  handleBlur = e => {
    const { onBlur } = this.props
    this.handleChange()
    onBlur && onBlur(e)
  }

  handleChange = () => {
    const { onChange } = this.props
    const value = this.textRef.current.innerHTML
    if (onChange && value !== this.lastValue) {
      onChange({
        target: {
          value,
        },
      })
    }
    this.lastValue = value
  }

  focus = () => {
    this.textRef.current.focus()
  }

  render() {
    const { value, style, editMode } = this.props

    return (
      <div
        onBlur={this.handleBlur}
        onInput={this.handleChange}
        onKeyDown={this.handleInputKeyDown}
        contentEditable={editMode}
        style={style}
        spellCheck={false}
        dangerouslySetInnerHTML={{ __html: value }}
        ref={this.textRef}
      />
    )
  }
}

ContentEditable.propTypes = {
  style: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  editMode: PropTypes.bool,
}

export default ContentEditable
