import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import SignUp from 'components/SignUp'

import { signIn, resetUser } from 'actions/userActions'

import styles from './AuthBetaPageStyles'

const InitialState = {
  email: '',
  password: '',
  error: null,
}

const EmailAddressesAllowed = [
  'cchao87@gmail.com',
  'andrew@realscout.com',
  'stevenfu.design@gmail.com',
  'hello@rubychien.me',
  'julia.zinchenko@live.com',
  'ariadna@spotahome.com',
  'bia.tdo@gmail.com',
  'carolina@oneskintech.com',
  'roychan@me.com',
  'castillejo.alejandro@gmail.com',
  'samadrita@google.com',
  'yufan.jiang@loopapps.com',
]

class AuthBetaPage extends Component {
  state = { ...InitialState }

  handleInputKeyDown = e => e.stopPropagation()

  onSubmit = async e => {
    const { actions } = this.props
    const { email, password } = this.state
    e.preventDefault()

    const authUser = await actions.signIn(email, password)

    this.setState({ ...authUser })
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleReset = () => {
    const { actions } = this.props
    actions.resetUser()
  }

  render() {
    const { classes, user, children } = this.props

    const isUserAuthenticated = user && user.email

    if (isUserAuthenticated && EmailAddressesAllowed.includes(user.email)) {
      return children
    }

    return (
      <div className={classes.container}>
        <div className={classes.formContainer}>
          {!isUserAuthenticated && <SignUp />}
          {isUserAuthenticated && (
            <>
              <Typography variant="body1">
                User does not have access to resource, contact
                {' '}
                <a href="mailto:hello@torii.co">support</a>
              </Typography>
              <Button style={{ marginTop: 42 }} onClick={this.handleReset}>
                Try again
              </Button>
            </>
          )}
        </div>
      </div>
    )
  }
}

AuthBetaPage.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    signIn: PropTypes.func.isRequired,
    resetUser: PropTypes.func.isRequired,
  }),
  children: PropTypes.element,
}

const mapStateToProps = (state, ownProps) => {
  const { user } = state

  return {
    user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ signIn, resetUser }, dispatch),
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(AuthBetaPage)
