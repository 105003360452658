export const GAMES_2_ON_1_NAME = '8:5x11 (2 on 1) - Games'
export const GAMES_1_ON_1 = '8:5x11 (Single) - Games'
export const SIGNS_8_10 = '8x10 - Signs'
export const SIGNS_8_10_LETTER = '8x10 on Letter Size - Signs'
export const SIGNS_5_7 = '5x7 - Signs'
export const CARDS_8_10_LETTER = '8x10 on Letter Size - Cards'

export const DownloadOptions = [
  {
    type: 'Games',
    values: [GAMES_2_ON_1_NAME, GAMES_1_ON_1],
  },
  {
    type: 'Signs',
    values: [SIGNS_8_10, SIGNS_8_10_LETTER, SIGNS_5_7],
  },
  {
    type: 'Cards',
    values: [CARDS_8_10_LETTER],
  },
]

export const DownloadSizeNames = [
  GAMES_2_ON_1_NAME,
  GAMES_1_ON_1,
  SIGNS_8_10,
  SIGNS_8_10_LETTER,
  SIGNS_5_7,
  CARDS_8_10_LETTER,
]

const DownloadDividerLength = 24
const DownloadDividerThickness = 1

export const DownloadSizeOptions = {
  GAMES_2_ON_1: {
    name: GAMES_2_ON_1_NAME,
    height: 612,
    width: 396,
    orientation: 'l',
    dividers: ({ width, height }) => {
      const halfX = (width - DownloadDividerThickness / 2) / 2
      return [
        [halfX, 0, halfX, DownloadDividerLength],
        [halfX, height - DownloadDividerLength, halfX, height],
      ]
    },
  },
  GAMES_1_ON_1: {
    name: GAMES_1_ON_1,
    height: 612,
    width: 396,
    orientation: 'p',
  },
  SIGNS_8_10: {
    name: SIGNS_8_10,
    height: 720,
    width: 576,
    orientation: 'p',
  },
  SIGNS_8_10_LETTER: {
    name: SIGNS_8_10_LETTER,
    height: 792,
    width: 612,
    orientation: 'p',
    dividers: ({ width, height }) => {
      // top-left
      const xPos1 = width * 0.03
      const yPos1 = height * 0.045

      // top-right
      const xPos2 = width - xPos1
      const yPos2 = yPos1

      // bottom-left
      const xPos3 = xPos1
      const yPos3 = height - yPos1

      // bottom-right
      const xPos4 = xPos2
      const yPos4 = yPos3

      return [
        [xPos1, yPos1, xPos1 - DownloadDividerLength, yPos1],
        [xPos1, yPos1, xPos1, yPos1 - DownloadDividerLength],
        [xPos2, yPos2, xPos2 + DownloadDividerLength, yPos2],
        [xPos2, yPos2, xPos2, yPos2 - DownloadDividerLength],
        [xPos3, yPos3, xPos3 - DownloadDividerLength, yPos3],
        [xPos3, yPos3, xPos3, yPos3 + DownloadDividerLength],
        [xPos4, yPos4, xPos4 + DownloadDividerLength, yPos4],
        [xPos4, yPos4, xPos4, yPos4 + DownloadDividerLength],
      ]
    },
  },
  SIGNS_5_7: {
    name: SIGNS_5_7,
    height: 504,
    width: 360,
    orientation: 'p',
  },
  CARDS_8_10_LETTER: {
    name: CARDS_8_10_LETTER,
    height: 306,
    width: 396,
    orientation: 'l',
    dividers: ({ width, height }) => {
      const halfX = (width - DownloadDividerThickness / 2) / 2
      const halfY = (height - DownloadDividerThickness / 2) / 2
      return [
        [halfX, 0, halfX, DownloadDividerLength],
        [halfX, height - DownloadDividerLength, halfX, height],
        [0, halfY, DownloadDividerLength, halfY],
        [width - DownloadDividerLength, halfY, width, halfY],
      ]
    },
  },
}
