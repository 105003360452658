import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import { withStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import * as Analytics from 'analytics/GoogleAnalytics'
import * as pageTypes from 'constants/pageTypes'
import { downloadPdfFromDOM } from 'utils'
import { setCurrentPage, fetchProjectPage } from 'actions/pageActions'

import CanvasContainer from 'components/CanvasContainer'

import styles from './ScreenPageStyles'

class ScreenPage extends Component {
  componentWillMount() {
    const {
      actions,
      routeParams: { projectId, screenId },
    } = this.props

    actions.setCurrentPage(pageTypes.ScreenPage)
    actions.fetchProjectPage(projectId, screenId)
  }

  artboardClassName = 'artboard-canvas'

  handleDownload = async() => {
    const {
      page: {
        project: { title: pdfName, options },
      },
    } = this.props

    Analytics.logEvent({
      category: Analytics.EVENT_CATEGORIES.SCREEN_PAGE,
      action: Analytics.EVENT_ACTIONS.CLICK,
      label: Analytics.EVENT_LABELS.DOWNLOAD,
    })

    const domEl = document.querySelector(`.${this.artboardClassName}`)

    options.downloadSizeOptions.forEach(option => {
      downloadPdfFromDOM(domEl, pdfName, option)
    })
  }

  render() {
    const {
      classes,
      page: { currentPage, project },
    } = this.props

    const isDownloadActive = !!get(project, 'options.downloadSizeOptions')
    return (
      <div className={classes.container}>
        <Paper className={classes.header} square elevation={0}>
          <Typography variant="h5">{project.title}</Typography>
          <div className={classes.separator} />
          {isDownloadActive && (
            <Button onClick={this.handleDownload} variant="outlined" className={classes.button}>
              Download
            </Button>
          )}
        </Paper>

        <CanvasContainer currentPage={currentPage} artboardClassName={this.artboardClassName} etsyPage />
      </div>
    )
  }
}

ScreenPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    setCurrentPage: PropTypes.func.isRequired,
    fetchProjectPage: PropTypes.func.isRequired,
  }),
  routeParams: PropTypes.object,
  page: PropTypes.object.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const { page } = state
  return {
    routeParams: ownProps.match.params,
    page,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        setCurrentPage,
        fetchProjectPage,
      },
      dispatch
    ),
  }
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(ScreenPage)
