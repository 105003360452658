const jssStyles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  header: {
    display: 'flex',
    padding: '0 16px',
    borderBottom: `1px solid ${theme.palette.uiBorderLight}`,
    height: theme.appBarHeight,
    alignItems: 'center',
    zIndex: theme.zIndex.GUI,
  },
  mainSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  separator: {
    flex: 1,
  },
})

export default jssStyles
