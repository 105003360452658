import _ from 'lodash'
import shortid from 'shortid'
import toolConfigList from 'constants/toolTypes'
import * as layerTypes from 'constants/layerTypes'
import { DefaultTransitionIn, TransitionsIn } from 'constants/animations'
import { removeDiacritics } from './misc'
import instanceConfig from '../config/instances'

export function encodeUrl(url) {
  return encodeURIComponent(url)
}

export function generateRandomValue(length = 14) {
  const radom13chars = () =>
    Math.random()
      .toString(16)
      .substring(2, 15)
  const loops = Math.ceil(length / 13)
  return new Array(loops)
    .fill(radom13chars)
    .reduce((string, func) => {
      return string + func()
    }, '')
    .substring(0, length)
}

export function getFrameFromNode(node) {
  const { x, y, width, height } = node.getBoundingClientRect()

  return {
    x,
    y,
    width,
    height
  }
}

export function getFirebaseUrl(value) {
  const FirebaseStorageUrl = `https://firebasestorage.googleapis.com/v0/b/${instanceConfig.firebase.storageBucket}/o`
  return `${FirebaseStorageUrl}/${encodeUrl(value)}?alt=media`
}

/**
 * Takes a firebase dictionary and returns an array. Useful for converting dictionaries stored
 * in firebase to format expected by redux.
 * @param {Object} dict
 */
export function formatFirebaseDictAsArray(dict) {
  if (!dict) return []
  return _.values(dict)
}

/**
 * Takes an array and returns an object. Useful for converting arrays into dictionaries stored
 * in firebase to format expected by redux.
 * @param {Array} array
 * @param {String} key
 */
export function formatArrayAsFirebaseDict(array, key) {
  if (!array) return {}
  return _.chain(array)
    .keyBy(key)
    .value()
}

/**
 * Encode an API mask query string
 * @param {String} queryName key name, e.g. 'q' in 'q=foobar'
 * @param {Object} obj the object to flatten into a string and put as value of query
 * @return {String}
 */
export function queryStrFromObj(queryName, obj) {
  const q = encodeURIComponent(JSON.stringify(obj))
  return `${queryName}=${q}`
}

export function getById(collection, id) {
  return _.find(collection, item => item.id === id) || null
}

export function getBlockById(project, id) {
  return getById(project.blocks, id)
}

export function getLayerById(project, id) {
  return project && (getById(project.artboard.layers, id) || getById(project.layers, id))
}

export function getLayerInformation(project, id) {
  if (!project) {
    return
  }

  // Get general layer
  let layer = getById(project.layers, id)
  if (layer) {
    return {
      layer
    }
  }

  // Get Artboard Layer
  layer = getById(project.artboard.layers, id)
  if (layer) {
    return {
      layer,
      parentRef: 'artboard'
    }
  }

  return {}
}

export function isLineLayer(layer) {
  return layer && layer.type === 'shape' && layer.shapeType === 'line'
}

export function hyphenateString(str) {
  return removeDiacritics(str)
    .replace(/\s+/g, '-')
    .toLowerCase()
}

export function loadFont(fontName, type = 'otf') {
  const fontUrl = getFirebaseUrl(`font/${fontName}.${type}`)
  const newStyle = document.createElement('style')
  newStyle.appendChild(document.createTextNode(`@font-face { font-family: '${fontName}'; src: url('${fontUrl}'); font-weight: normal; font-style: normal;}`))
  console.log('font loaded', fontUrl)
  document.head.appendChild(newStyle)
}

function extractAllFontsFromLayers(layers, fontFamilies) {
  _.values(layers).forEach(({ layers: childLayers }) => {
    childLayers && extractAllFontsFromLayers(childLayers, fontFamilies)
  })

  _.values(layers)
    .filter(layer => layer.type === 'text')
    .forEach(layer => {
      const fontFamily = _.get(layer, 'style.fontFamily')
      // eslint-disable-next-line no-param-reassign
      fontFamilies[fontFamily] = true
    })
}

export function loadFontsFromLayers(layers) {
  const fontFamilies = {}
  extractAllFontsFromLayers(layers, fontFamilies)

  const fontTypes = ['ttf', 'otf']
  fontTypes.forEach(ftype => {
    Object.keys(fontFamilies).forEach(fontFamilyName => {
      loadFont(fontFamilyName, ftype)
    })
  })
}

export function getPositionFromMouseEvent(e) {
  return {
    x: e.clientX,
    y: e.clientY
  }
}

export function isValidCanvasPosition(position) {
  // Must check for 'null' explicitly here rather than casting to boolean
  // because 0 is a valid position, but a falsy value.
  return position.x !== null && position.y !== null
}

// ========== TOOLS ================================================================================

export function getToolConfigForToolName(toolName) {
  const config = toolConfigList.find(toolConfig => toolConfig.name === toolName)
  if (!config) {
    throw new Error(`Can't get config for unrecognized view: ${toolName}`)
  }
  return config
}

export function getStepsForTool(toolName) {
  const toolConfig = getToolConfigForToolName(toolName)
  const { steps } = toolConfig
  if (!steps) {
    throw new Error(`Can't get steps from config for tool: ${toolName}`)
  }
  return steps
}

export function processLayerPosition(layer, scaleRatio) {
  let {
    frame: { x: left, y: top }
  } = layer

  // If element floats, the layer is treated differently
  if (layer.name.includes('--float')) {
    top = 0
    left = 0
  }

  return {
    top: top * scaleRatio,
    left: left * scaleRatio
  }
}

// ========== LAYER CREATORS ==================================================================

/**
 * Returns an object representing a text layer. The third (optional)
 * id parameter can be provided if the caller wants to specify their own ID
 * for the layer. Otherwise, a new one is generated.
 */

export function makePositionAttachment(position) {
  return { ...position }
}

export function makeTextLayer(position, id = null) {
  return {
    type: layerTypes.TextLayer,
    id: id || shortid.generate(),
    position,
    config: layerTypes.TextLayerConfig
  }
}

export function makeLineLayer(p1, p2 = {}, id = null) {
  return {
    type: 'shape',
    shapeType: 'line',
    id: id || shortid.generate(),
    points: {
      p1,
      p2
    }
  }
}

export function getUniqId(collection, propId = 'id') {
  let id = shortid.generate()

  // eslint-disable-next-line no-loop-func
  while (collection.find(c => c[propId] === id)) {
    id = shortid.generate()
  }

  return id
}

export function findToriiPropByName(props, name) {
  return props.find(p => p.prop === name)
}

export function filterToriiPropByName(props, name) {
  return props.filter(p => p.prop === name)
}

export function isMobileDevice() {
  const userAgent = window.navigator.userAgent.toLowerCase()
  const isIos = /iphone|ipod|ipad/.test(userAgent)
  const isAndroid = /android/.test(userAgent)
  return isIos || isAndroid
}

export function getTransitionFromType(animationType) {
  return TransitionsIn[animationType] ? TransitionsIn[animationType] : DefaultTransitionIn
}

const ComponentDelimeter = '__'
const ModifierDelimeter = '--'

export function parseStringByType(string, type) {
  const regex = `([^${ComponentDelimeter}+${ModifierDelimeter}]*)?(${ComponentDelimeter}([a-z0-9]+(_[a-z0-9]+)?))?((${ModifierDelimeter})([a-z0-9]+(-[a-z0-9]+)?)+)?`
  const matchProps = string.match(regex)

  switch (type) {
    case 'name': {
      return _.get(matchProps, '[1]')
    }
    case 'componentName': {
      return _.get(matchProps, '[3]')
    }
    case 'modifierName': {
      return _.get(matchProps, '[7]')
    }
    default: {
      return false
    }
  }
}

export function parseLayerName(name) {
  const regexComponent = `${ComponentDelimeter}[^]*`
  const regexModifier = `${ModifierDelimeter}[^]*`

  const matchComponent = _.get(name.match(regexComponent), '[0]')
  const matchModifier = _.get(name.match(regexModifier), '[0]')

  return {
    name: parseStringByType(name, 'name'),
    componentName: matchComponent ? parseStringByType(matchComponent, 'componentName') : undefined,
    modifierName: matchModifier ? parseStringByType(matchModifier, 'modifierName') : undefined
  }
}
