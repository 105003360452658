/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

import DeviceImage from 'docs/components/DeviceImage'

import styles from 'docs/DocsPageStyles'

const DocsTypography = withStyles(theme => ({
  root: {
    color: theme.palette.black65,
    fontFamily: 'Open Sans',
  },
}))(Typography)

class WhyTorii extends Component {
  render() {
    const { classes } = this.props

    const imageContainerClassNames = classNames({
      [classes.imageContainer]: true,
      [classes.threeImageContainer]: true,
    })

    return (
      <>
        <DocsTypography variant="h6" className={classes.contentSubTitle}>
          Why Torii?
        </DocsTypography>
        <div>
          <p>
            We know there are many hi-fidelity prototyping tools out there, so why we think you
            should give Torii a try?
          </p>
          <p>
            <span className={classes.emoji}>⚡</span> Speed. Hi-fidelity prototyping is very time
            consuming. With Torii you will watch your designs come to life with very little work.
          </p>
          <p>
            <span className={classes.emoji}>💆🏻</span> Lowest learning curve. With our guided
            1-minute tutorials, you{'\''}ll be able to start sharing close-to-final products right
            away.
          </p>
          <p>
            <span className={classes.emoji}>☘️</span> No need to install a new tool. Torii is
            integrated seamlessly with your tools! Now ready for Sketch, will be soon in Figma and
            Adobe XD.
          </p>
          <div className={imageContainerClassNames} style={{ marginTop: 48 }}>
            <DeviceImage src="/images/docs/sample-intro-1.gif" />
            <DeviceImage src="/images/docs/sample-intro-2.gif" />
            <DeviceImage src="/images/docs/sample-intro-3.gif" />
          </div>
        </div>
      </>
    )
  }
}

WhyTorii.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  routeParams: PropTypes.object,
}

export default withStyles(styles)(WhyTorii)
