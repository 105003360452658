const jssStyles = theme => ({
  container: {
    height: '100%',
    position: 'fixed',
    transition: 'height 100ms ease-in-out',
  },
  toolbar: {
    padding: 0,
  },
  rightIconContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    flex: 1,
    display: 'flex',
    fontWeight: 500,
    alignItems: 'flex-end',
  },
  titleExpand: {
    padding: '0 64px 20px',
  },
})

export default jssStyles
