import blue from '@material-ui/core/colors/blue'

export const ResizerOffsetPx = 6

const jssStyles = theme => ({
  container: {
    position: 'absolute',
    minWidth: 20,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      border: '1px solid transparent',
    },
  },
  containerSymbol: {
    '&:before': {
      top: -ResizerOffsetPx,
      left: -ResizerOffsetPx,
      height: `calc(100% + ${ResizerOffsetPx * 2}px)`,
      width: `calc(100% + ${ResizerOffsetPx * 2}px)`,
    },
  },
  containerHovered: {
    '&:before': {
      borderColor: blue[100],
    },
  },
  containerSelected: {
    '&:before': {
      borderColor: blue[400],
    },
  },
  containerMove: {
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
  disableSelection: {
    '&:before': {
      content: 'none',
    },
  },
})

export default jssStyles
