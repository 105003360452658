import * as instances from '../constants/instances'
import devConfig from './dev'
import prodConfig from './prod'

function getInstanceConfig() {
  const instance = process.env.NODE_ENV
  switch (instance) {
    case instances.Production:
      return prodConfig
    case instances.Development:
      return devConfig
    default:
      throw Error(`Unknown instance: ${instance}`)
  }
}

export default getInstanceConfig()
