const jssStyles = theme => ({
  container: {
    position: 'absolute',
    minWidth: 2,
    minHeight: 2,
    top: 0,
    left: 0,
  },
})

export default jssStyles
