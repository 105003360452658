const jssStyles = theme => ({
  outerContainer: {
    height: '100%',
    overflowY: 'scroll',
    overflowScrolling: 'touch',
    WebkitOverflowScrolling: 'touch',
  },
  container: {
    position: 'relative',
    overflow: 'hidden',
  },
  etsyContainer: {
    boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.2)',
  },
})

export default jssStyles
