const jssStyles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  formType: {
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
  },
})

export default jssStyles
