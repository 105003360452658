const jssStyles = theme => ({
  container: {
    position: 'absolute',
    border: 'none',
    background: 'transparent',
  },
  overlay: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 100,
    background: 'white',
    opacity: 0,
    transition: 'opacity 100ms ease-in-out',
    '&:hover': {
      opacity: 0.1,
    },
  },
})

export default jssStyles
