import React from 'react'
import PropTypes from 'prop-types'

import * as utils from 'utils'
import * as toolStates from 'constants/toolStates'

import LineShape from '_layers/ShapeLayer/LineShape'

const LineHint = props => {
  const {
    toolsState: { activeTool, toolStep, lineToolState },
    originMousePosition,
  } = props
  if (activeTool == null) return null

  let layer
  switch (toolStep.name) {
    case toolStates.AwaitingFirstAttachmentSelection: {
      layer = utils.makeLineLayer(originMousePosition)
      break
    }
    case toolStates.AwaitingSecondAttachmentSelection: {
      const { startAttachment } = lineToolState
      layer = utils.makeLineLayer(startAttachment, originMousePosition)
      break
    }
    default: {
      return null
    }
  }

  return <LineShape layer={layer} isGhost />
}

LineHint.propTypes = {
  toolsState: PropTypes.object,
  originMousePosition: PropTypes.object,
}

export default LineHint
