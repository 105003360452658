import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Layer from 'components/Layer'

import styles from './HorizontalSliderStyles'

const OffsetPx = 20

class HorizontalSlider extends Component {
  render() {
    const {
      classes,
      layer,
      layer: {
        frame,
        frame: { width, height, y: top, x: left },
        layers,
      },
      artboardEvents,
      storageBucketId,
      scaleRatio,
      editMode,
    } = this.props

    const scrollContainerStyles = {
      top: top * scaleRatio - OffsetPx,
      height: height * scaleRatio + OffsetPx * 2,
    }

    const containerStyles = {
      top: OffsetPx,
      left: left * scaleRatio,
      width: width * scaleRatio + left * scaleRatio,
      height: height * scaleRatio,
    }

    return (
      <div className={classes.scrollContainer} style={scrollContainerStyles}>
        <div className={classes.container} style={containerStyles}>
          {layer.layers &&
            Object.keys(layers).map(layerId => {
              const componentLayer = layers[layerId]
              return (
                <Layer
                  layer={componentLayer}
                  artboardFrame={frame}
                  key={layerId}
                  artboardEvents={artboardEvents}
                  storageBucketId={storageBucketId}
                  scaleRatio={scaleRatio}
                  editMode={editMode}
                />
              )
            })}
        </div>
      </div>
    )
  }
}

HorizontalSlider.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  artboardEvents: PropTypes.object,
  storageBucketId: PropTypes.string,
  scaleRatio: PropTypes.number,
  editMode: PropTypes.bool,
}

export default withStyles(styles)(HorizontalSlider)
