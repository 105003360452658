import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import values from 'lodash/values'
import { withStyles } from '@material-ui/core/styles'

import { isMobileDevice, loadFontsFromLayers } from 'utils'

import ArtboardFlow from './ArtboardFlow'

import styles from './ArtboardFlowStyles'

class ArtboardFlowContainer extends Component {
  state = {
    scrollTop: 0,
    artboardRect: {},
    windowRect: {},
  }

  componentWillMount() {
    const {
      artboard: { layers, artboards },
    } = this.props

    const allLayers = isEmpty(artboards)
      ? layers
      : values(artboards).reduce((acc, artboard) => {
          Object.keys(artboard.layers).forEach(layerId => {
            acc[layerId] = artboard.layers[layerId]
          })
          return acc
        }, {})

    loadFontsFromLayers(allLayers)
    this.handleResizeWindow()
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResizeWindow)
    this.getScrollContainer().addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResizeWindow)
    this.getScrollContainer().removeEventListener('scroll', this.handleScroll)
  }

  containerRef = React.createRef()

  getScrollContainer = () => {
    return isMobileDevice() ? window : this.containerRef.current
  }

  handleResizeWindow = () => {
    const {
      artboard: { frame },
      onWindowResize,
    } = this.props

    const artboardRectWidth = isMobileDevice() ? window.innerWidth : frame.width

    this.setState({
      artboardRect: {
        y: 0,
        x: isMobileDevice() ? 0 : window.innerWidth / 2 - frame.width / 2,
        width: artboardRectWidth,
        height: frame.height,
      },
      windowRect: {
        width: window.innerWidth,
        height: isMobileDevice() ? frame.height : window.innerHeight,
      },
    })

    onWindowResize && onWindowResize()
  }

  handleScroll = e => {
    this.setState({ scrollTop: e.target.scrollTop || window.pageYOffset })
  }

  handleArtboardFlowChange = value => {
    const { onArtboardFlowChange } = this.props

    this.getScrollContainer().scrollTo(0, 0)

    onArtboardFlowChange && onArtboardFlowChange(value)
  }

  render() {
    const {
      classes,
      artboard,
      artboard: { frame },
      artboardFlow,
      rootArtboardId,
      editMode,
    } = this.props

    const { scrollTop, artboardRect, windowRect } = this.state

    const artboardEvents = {
      scrollTop,
      artboardRect,
      windowRect,
      isMobile: isMobileDevice(),
    }

    const containerStyles = {
      overflow: isMobileDevice() ? false : 'scroll',
      width: artboardRect.width,
    }

    const scaleRatio = artboardRect.width / frame.width

    return (
      <div className={classes.container} style={containerStyles} ref={this.containerRef}>
        <ArtboardFlow
          artboard={artboard}
          onArtboardFlowChange={this.handleArtboardFlowChange}
          artboardFlow={artboardFlow}
          artboardEvents={artboardEvents}
          rootArtboardId={rootArtboardId}
          scaleRatio={scaleRatio}
          editMode={editMode}
        />
      </div>
    )
  }
}

ArtboardFlowContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  artboard: PropTypes.object.isRequired,
  onArtboardFlowChange: PropTypes.func,
  artboardFlow: PropTypes.object.isRequired,
  rootArtboardId: PropTypes.string,
  onWindowResize: PropTypes.func,
  editMode: PropTypes.bool,
}

export default withStyles(styles)(ArtboardFlowContainer)
