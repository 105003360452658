const jssStyles = theme => ({
  container: {
    position: 'absolute',
  },
  headers: {
    position: 'absolute',
  },
  contents: {
    position: 'absolute',
  },
  header: {
    cursor: 'pointer',
    position: 'absolute',
    opacity: 0.9,
  },
  headerActive: {
    opacity: 1,
  },
  content: {
    visibility: 'hidden',
    zIndex: -1,
  },
  contentActive: {
    visibility: 'visible',
    zIndex: -1,
  },
})

export default jssStyles
