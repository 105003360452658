import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { hot } from 'react-hot-loader'

import * as routes from 'constants/routes'

import TestPage from 'components/TestPage'
import LandingPage from 'components/LandingPage'
import DocsPage from 'components/docs'
import LivePage from 'components/LivePage'
import MakerPage from 'components/MakerPage'
import EtsyMakerPage from 'components/EtsyMakerPage'
import ScreenPage from 'components/ScreenPage'
import ProjectPage from 'components/ProjectPage'
import AdminScreenPage from 'components/AdminScreenPage'
import * as GoogleAnalytics from 'analytics/GoogleAnalytics'
import RequireAuthInit from './RequireAuthInit'

class App extends Component {
  componentDidMount() {
    const { location } = this.props
    GoogleAnalytics.logPageView(location.pathname)
  }

  render() {
    return (
      <Switch>
        <Route exact path={routes.TestPage} component={TestPage} />
        <Route
          path={routes.LandingPage}
          component={() => {
            window.location.href = 'https://torii.studio'
            return null
          }}
        />
        {/* <Route exact path={routes.LandingPage} component={LandingPage} /> */}
        <Route exact path={routes.DocsPage} component={DocsPage} />
        <Route exact path={routes.LandingPageSignUp} component={LandingPage} />
        <Route exact path={routes.LivePage} component={LivePage} />
        <Route exact path={routes.MakerPage} component={MakerPage} />
        <Route exact path={routes.EtsyMakerPage} component={EtsyMakerPage} />
        <Route exact path={routes.AdminScreenPage} component={AdminScreenPage} />
        <Route exact path={routes.ScreenPage} component={ScreenPage} />

        <RequireAuthInit>
          <Route exact path={routes.ProjectPage} component={ProjectPage} />
        </RequireAuthInit>
      </Switch>
    )
  }
}

App.propTypes = {
  location: PropTypes.object.isRequired,
}

export default compose(
  withRouter,
  hot(module)
)(App)
