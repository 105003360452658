import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { findToriiPropByName } from 'utils'

import ToriiAppBar from '@torii-components/ToriiAppBar'
import ToriiTabs from '@torii-components/ToriiTabs'

import styles from './ToriiAppBarTabsStyles'

const AppBarProp = {
  prop: '__torii-appbar__',
  value: {},
}

const TabsProp = {
  prop: '__torii-tabs__',
  value: {},
}

class ToriiAppBarTabs extends Component {
  render() {
    const {
      classes,
      props,
      artboardFrame,
      frame: { width, height },
      artboardEvents,
      storageBucketId,
    } = this.props

    const { value: appBarProps } = findToriiPropByName(props, AppBarProp.prop) || AppBarProp
    const { value: tabsProps } = findToriiPropByName(props, TabsProp.prop) || TabsProp

    const hasScrolled = artboardEvents && !!artboardEvents.scrollTop

    const containerStyles = {
      width: artboardFrame.width ? Math.min(artboardFrame.width, width) : width,
      height,
    }

    return (
      <div className={classes.container} style={containerStyles}>
        {!hasScrolled && <ToriiAppBar props={appBarProps} />}
        <ToriiTabs
          props={tabsProps}
          artboardEvents={artboardEvents}
          storageBucketId={storageBucketId}
        />
      </div>
    )
  }
}

ToriiAppBarTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  props: PropTypes.arrayOf(PropTypes.object).isRequired,
  frame: PropTypes.object.isRequired,
  artboardFrame: PropTypes.object,
  artboardEvents: PropTypes.object,
  storageBucketId: PropTypes.string,
}

export default withStyles(styles)(ToriiAppBarTabs)
