import blue from '@material-ui/core/colors/blue'

const jssStyles = theme => ({
  container: {},
  highlight: {
    backgroundColor: blue[50],
    opacity: 0.9,
  },
  input: {
    display: 'none',
  },
})

export default jssStyles
