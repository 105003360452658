const jssStyles = theme => ({
  container: {
    fontFamily: 'Roboto',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 26,
    height: '100%',
    width: '100%',
    maxHeight: 60,
  },
  title: {
    fontWeight: 500,
  },
})

export default jssStyles
