import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { processLayerPosition } from 'utils'

import DraggableBlock from '_common/DraggableBlock'
import ContentEditable from '_common/ContentEditable'
import ResizeWrapper from '_common/ResizeWrapper/ResizeWrapper'

import styles from './TextLayerStyles'

const WithHyperLinkLink = ({ link, className, children }) =>
  link ? (
    <a href={link} className={className} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  ) : (
    children
  )

WithHyperLinkLink.propTypes = {
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
}

class TextLayer extends React.Component {
  state = {
    disableSelection: false,
  }

  contentEditableRef = React.createRef()

  handleMouseDown = e => {
    const { onMouseDown, layer } = this.props
    onMouseDown && onMouseDown(e, layer.id)
  }

  handleMouseEnter = e => {
    const { onMouseEnter, layer } = this.props
    onMouseEnter && onMouseEnter(e, layer.id)
  }

  handleMouseLeave = e => {
    const { onMouseLeave, layer } = this.props
    onMouseLeave && onMouseLeave(e, layer.id)
  }

  handleBlur = e => {
    this.setState({ disableSelection: false })
  }

  handleDoubleClick = e => {
    this.contentEditableRef.current.focus()
    this.setState({ disableSelection: true })
  }

  handleTextChange = e => {
    const { onChange } = this.props
    onChange && onChange(e, 'value', e.target.value)
  }

  handlePositionChange = (e, position) => {
    const { onChange, layer } = this.props

    onChange &&
      onChange(e, 'frame', {
        ...layer.frame,
        x: position.x,
        y: position.y,
      })
  }

  handleFrameChange = (e, frame) => {
    const { onChange, layer } = this.props

    onChange &&
      onChange(e, 'frame', {
        ...layer.frame,
        ...frame,
      })
  }

  render() {
    const {
      classes,
      isHovered,
      isSelected,
      layer,
      layer: {
        frame,
        frame: { width, height, alignItems, justifyContent, lineHeight, textAlign },
        zIndex,
        style,
        value,
        hyperlink,
      },
      scaleRatio,
      editMode,
    } = this.props
    const { disableSelection } = this.state

    const containerClassNames = classNames({
      [classes.container]: true,
      [classes.containerHovered]: isHovered,
      [classes.containerSelected]: isSelected,
      [classes.containerMove]: isSelected && !disableSelection,
      [classes.disableSelection]: disableSelection,
      [classes.hyperlink]: hyperlink,
    })

    const position = processLayerPosition(layer, scaleRatio)

    const dimensions = {
      ...position,
      height: height * scaleRatio,
      width: width * scaleRatio,
    }

    const isTextInOneLine = height / 2 < style.fontSize

    const defaultLineHeight = isTextInOneLine ? 'unset' : 'normal'

    const rootStyles = {
      ...dimensions,
      // TODO: Fix fonts that have text that are multiline and the web moves them down. (e.g. Font: Hiragino Maru Gothic ProN)
      lineHeight: lineHeight ? `${lineHeight * scaleRatio}px` : defaultLineHeight,
      zIndex,
    }

    const mergeContainerStyles = {
      ...rootStyles,
      height: Math.ceil(height * scaleRatio) || 'auto',
      width: Math.ceil(width * scaleRatio) || 'auto',
    }

    const mergeTextStyles = {
      ...style,
      fontSize: Math.floor(style.fontSize * scaleRatio),
      textRendering: 'geometricPrecision',
      whiteSpace: isTextInOneLine ? 'nowrap' : 'normal',
    }

    const offsetDimensions = {
      width: 6,
      height: 6,
    }

    const flexContainerStyles = {
      alignItems,
      justifyContent,
      textAlign,
    }

    return (
      <WithHyperLinkLink link={hyperlink}>
        <DraggableBlock
          onMouseDown={this.handleMouseDown}
          position={frame}
          onChange={this.handlePositionChange}
          disabled={!editMode}
        >
          <div
            style={mergeContainerStyles}
            className={containerClassNames}
            onMouseDown={this.handleMouseDown}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            onDoubleClick={this.handleDoubleClick}
          >
            <ResizeWrapper
              dimensions={dimensions}
              offsetDimensions={offsetDimensions}
              onChange={this.handleFrameChange}
              disabled={!isSelected || disableSelection}
            >
              <div className={classes.flexContainer} style={flexContainerStyles}>
                <ContentEditable
                  style={mergeTextStyles}
                  onBlur={this.handleBlur}
                  onChange={this.handleTextChange}
                  value={value}
                  ref={this.contentEditableRef}
                  editMode={editMode}
                />
              </div>
            </ResizeWrapper>
          </div>
        </DraggableBlock>
      </WithHyperLinkLink>
    )
  }
}

TextLayer.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  isHovered: PropTypes.bool,
  isSelected: PropTypes.bool,
  onMouseDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onChange: PropTypes.func,
  scaleRatio: PropTypes.number,
  editMode: PropTypes.bool,
}

export default withStyles(styles)(TextLayer)
