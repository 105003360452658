/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

import Note from 'docs/components/Note'

import styles from 'docs/DocsPageStyles'

const DocsTypography = withStyles(theme => ({
  root: {
    color: theme.palette.black65,
    fontFamily: 'Open Sans',
  },
}))(Typography)

class DocsComponentsIntroduction extends Component {
  render() {
    const { classes } = this.props

    return (
      <a name="introduction" className={classes.subSectionLink}>
        <a href="#introduction">
          <DocsTypography variant="h6" className={classes.contentSubTitle}>
            Introduction
          </DocsTypography>
        </a>
        <div className={classes.subSectionContent}>
          <p>
            Torii translates your static design elements into high-fidelity interactive components.
            It uses mathematical programming and smart heuristics as quality indicators to render
            custom components. To know which components you are using,{' '}
            <span>you have to rename your layer groups </span> by using the component identifiers as
            shown on this guide.
          </p>
          <p>There are two ways to tag your component:</p>
          <p>Option A: Rename the group of layer with the component identifier. For example:</p>

          <div className={classes.comparisonContainer}>
            <div>
              <img src="/images/docs/components-introduction-image-1.png" alt="Before renaming" />
              <div>
                Before. Layer group called {'"'}
                <code>Group 1</code>
                {'"'}{' '}
              </div>
            </div>
            <div>
              <img src="/images/docs/components-introduction-image-2.png" alt="After renaming" />
              <div>
                After. Layer group called {'"'}
                <code>button</code>
                {'"'}{' '}
              </div>
            </div>
          </div>
          <p>
            Option B: Add two underscores <code>__</code> to your layer group or component
            identifier. For example:
          </p>
          <div className={classes.comparisonContainer}>
            <div>
              <img src="/images/docs/components-introduction-image-1.png" alt="Before renaming" />
              <div>
                Before. Layer group called {'"'}
                <code>Group 1</code>
                {'"'}{' '}
              </div>
            </div>
            <div>
              <img src="/images/docs/components-introduction-image-3.png" alt="After renaming" />
              <div>
                After. Layer group called {'"'}
                <code>Group 1__button</code>
                {'"'}{' '}
              </div>
            </div>
          </div>
          <Note
            title="Important Note"
            description="For each component, use our guides to make sure you use the appropiate component identifier"
          />
        </div>
      </a>
    )
  }
}

DocsComponentsIntroduction.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  routeParams: PropTypes.object,
}

export default withStyles(styles)(DocsComponentsIntroduction)
