import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { getToriiComponentByName } from '@torii-components'

import styles from './ToriiLayerStyles'

class ToriiLayer extends React.Component {
  render() {
    const {
      classes,
      layer: {
        frame,
        frame: { height, width, x: left, y: top },
        name,
        props,
      },
      artboardFrame,
      artboardEvents,
      onArtboardFlowChange,
      storageBucketId,
      scaleRatio,
    } = this.props

    const containerStyles = {
      top: top * scaleRatio,
      left: left * scaleRatio,
      width: artboardFrame.width
        ? Math.min(artboardFrame.width * scaleRatio, width * scaleRatio)
        : width * scaleRatio,
      height: height * scaleRatio,
    }

    return (
      <div className={classes.container} style={containerStyles}>
        {getToriiComponentByName(name, {
          props,
          artboardFrame,
          frame,
          artboardEvents,
          onArtboardFlowChange,
          storageBucketId,
          scaleRatio,
        })}
      </div>
    )
  }
}

ToriiLayer.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  artboardFrame: PropTypes.object,
  isHovered: PropTypes.bool,
  isSelected: PropTypes.bool,
  onMouseDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onChange: PropTypes.func,
  storageBucketId: PropTypes.string,
  artboardEvents: PropTypes.object,
  onArtboardFlowChange: PropTypes.func,
  scaleRatio: PropTypes.number,
}

export default withStyles(styles)(ToriiLayer)
