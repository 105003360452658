import 'firebase/auth'
import 'firebase/database'
import app from 'firebase/app'
import instanceConfig from '../config/instances'

const { firebase: config } = instanceConfig

class Firebase {
  constructor() {
    this.app = !app.apps.length ? app.initializeApp(config) : app

    this.auth = app.auth()
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) => {
    return new Promise(resolve => {
      this.auth
        .createUserWithEmailAndPassword(email, password)
        .then(response => {
          resolve({
            error: false,
            response,
          })
        })
        .catch(({ message }) => {
          resolve({
            error: true,
            message,
          })
        })
    })
  }

  doSignInWithEmailAndPassword = (email, password) => {
    return new Promise(resolve => {
      this.auth
        .signInWithEmailAndPassword(email, password)
        .then(response => {
          resolve({
            error: false,
            response,
          })
        })
        .catch(({ message }) => {
          resolve({
            error: true,
            message,
          })
        })
    })
  }

  doSignOut = () => this.auth.signOut()

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email)

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password)
}

export default new Firebase()
