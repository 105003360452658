import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Decorator from '_common/Decorator'

import TextLayer from '_layers/TextLayer'
import ShapeLayer from '_layers/ShapeLayer'
import ImageLayer from '_layers/ImageLayer'
import SymbolLayer from '_layers/SymbolLayer'
import ToriiLayer from '_layers/ToriiLayer'
import HotspotLayer from '_layers/HotspotLayer'
import ComponentLayer from '_layers/ComponentLayer'
import MaterialComponentLayer from '_layers/MaterialComponentLayer'

import styles from './LayerStyles'

class Layer extends Component {
  handleMouseDown = e => {
    const { onMouseDown, layer } = this.props
    onMouseDown && onMouseDown(e, layer.id)
  }

  handleMouseEnter = e => {
    const { onMouseEnter, layer } = this.props
    onMouseEnter && onMouseEnter(e, layer.id)
  }

  handleMouseLeave = e => {
    const { onMouseLeave, layer } = this.props
    onMouseLeave && onMouseLeave(e, layer.id)
  }

  handleLayerChange = (e, propPath, value) => {
    const { onLayerChange, layer } = this.props
    onLayerChange && onLayerChange(e, layer.id, propPath, value)
  }

  render() {
    const {
      isHovered,
      isSelected,
      layer,
      layer: { type },
      storageBucketId,
      artboardFrame,
      artboardEvents,
      onArtboardFlowChange,
      scaleRatio,
      editMode,
    } = this.props

    // console.log(layer)

    // TEXT
    if (type === 'text') {
      return (
        <Decorator layer={layer} scaleRatio={scaleRatio} artboardEvents={artboardEvents}>
          <TextLayer
            layer={layer}
            isHovered={isHovered}
            isSelected={isSelected}
            onMouseDown={this.handleMouseDown}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            onChange={this.handleLayerChange}
            scaleRatio={scaleRatio}
            editMode={editMode}
          />
        </Decorator>
      )
    }

    // SHAPE
    if (type === 'shape') {
      return (
        <Decorator layer={layer} scaleRatio={scaleRatio} artboardEvents={artboardEvents}>
          <ShapeLayer
            layer={layer}
            isHovered={isHovered}
            isSelected={isSelected}
            onMouseDown={this.handleMouseDown}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            onChange={this.handleLayerChange}
            scaleRatio={scaleRatio}
          />
        </Decorator>
      )
    }

    // IMAGE
    if (type === 'image') {
      return (
        <Decorator layer={layer} scaleRatio={scaleRatio} artboardEvents={artboardEvents}>
          <ImageLayer
            layer={layer}
            isHovered={isHovered}
            isSelected={isSelected}
            storageBucketId={storageBucketId}
            onMouseDown={this.handleMouseDown}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            onChange={this.handleLayerChange}
            scaleRatio={scaleRatio}
          />
        </Decorator>
      )
    }

    // SYMBOL
    if (type === 'symbol') {
      return (
        <Decorator layer={layer} scaleRatio={scaleRatio} artboardEvents={artboardEvents}>
          <SymbolLayer
            layer={layer}
            isHovered={isHovered}
            isSelected={isSelected}
            storageBucketId={storageBucketId}
            onMouseDown={this.handleMouseDown}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            onChange={this.handleLayerChange}
            artboardEvents={artboardEvents}
            scaleRatio={scaleRatio}
          />
        </Decorator>
      )
    }

    // TORII
    if (type === 'torii') {
      return (
        <Decorator layer={layer} scaleRatio={scaleRatio} artboardEvents={artboardEvents}>
          <ToriiLayer
            layer={layer}
            artboardFrame={artboardFrame}
            isHovered={isHovered}
            isSelected={isSelected}
            storageBucketId={storageBucketId}
            onMouseDown={this.handleMouseDown}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            onChange={this.handleLayerChange}
            artboardEvents={artboardEvents}
            onArtboardFlowChange={onArtboardFlowChange}
            scaleRatio={scaleRatio}
          />
        </Decorator>
      )
    }

    // HOTSPOT
    if (type === 'hotspot') {
      return (
        <Decorator layer={layer} scaleRatio={scaleRatio} artboardEvents={artboardEvents}>
          <HotspotLayer
            layer={layer}
            onMouseDown={this.handleMouseDown}
            onArtboardFlowChange={onArtboardFlowChange}
            scaleRatio={scaleRatio}
          />
        </Decorator>
      )
    }


    // COMPONENT
    if (type === 'component') {
      return (
        <Decorator layer={layer} scaleRatio={scaleRatio} artboardEvents={artboardEvents}>
          <ComponentLayer
            layer={layer}
            artboardFrame={artboardFrame}
            isHovered={isHovered}
            isSelected={isSelected}
            storageBucketId={storageBucketId}
            onMouseDown={this.handleMouseDown}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            onChange={this.handleLayerChange}
            artboardEvents={artboardEvents}
            onArtboardFlowChange={onArtboardFlowChange}
            scaleRatio={scaleRatio}
            editMode={editMode}
          />
        </Decorator>
      )
    }

    // MATERIAL COMPONENT
    if (type === 'material-component') {
      return (
        <Decorator layer={layer} scaleRatio={scaleRatio} artboardEvents={artboardEvents}>
          <MaterialComponentLayer
            layer={layer}
            artboardFrame={artboardFrame}
            isHovered={isHovered}
            isSelected={isSelected}
            storageBucketId={storageBucketId}
            onMouseDown={this.handleMouseDown}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            onChange={this.handleLayerChange}
            artboardEvents={artboardEvents}
            onArtboardFlowChange={onArtboardFlowChange}
            scaleRatio={scaleRatio}
          />
        </Decorator>
      )
    }

    return null
  }
}

Layer.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  artboardFrame: PropTypes.object,
  storageBucketId: PropTypes.string,
  isHovered: PropTypes.bool,
  isSelected: PropTypes.bool,
  onMouseDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onLayerChange: PropTypes.func,
  artboardEvents: PropTypes.object,
  onArtboardFlowChange: PropTypes.func,
  scaleRatio: PropTypes.number,
  editMode: PropTypes.bool,
}

export default withStyles(styles)(Layer)
