import React from 'react'
import IntroductionWhyTorii from './introduction/DocsWhyTorii'
import IntroductionGettingStarted from './introduction/DocsGettingStarted'
import IntroductionTutorials from './introduction/DocsTutorials'
import ComponentsIntroduction from './components/DocsIntroduction'
import ComponentsStates from './components/DocsStates'
import ComponentsButtons from './components/DocsButtons'
import ComponentsInputs from './components/DocsInputs'
import ComponentsCheckboxes from './components/DocsCheckboxes'
import ComponentsSliders from './components/DocsSliders'
import ComponentsScrollers from './components/DocsScrollers'
import ComponentsTabs from './components/DocsTabs'

const Pages = {
  IntroductionWhyTorii,
  IntroductionGettingStarted,
  IntroductionTutorials,
  ComponentsIntroduction,
  ComponentsStates,
  ComponentsButtons,
  ComponentsInputs,
  ComponentsCheckboxes,
  ComponentsSliders,
  ComponentsScrollers,
  ComponentsTabs,
}

export function getPageByName(sectionName, subSectionName) {
  const pageId = `${sectionName}${subSectionName}`
  const PageName = Pages[pageId]

  if (!PageName) return null

  return <PageName key={pageId} />
}
