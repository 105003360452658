const jssStyles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    fontFamily: 'Open Sans',
  },
  item: {
    transition: 'opacity 0.5s ease-out, visibility 0.5s ease-out',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    position: 'relative',
    fontSize: 18,
    color: 'white',
    fontWeight: 600,
    zIndex: 10,
  },
  subtitle: {
    position: 'relative',
    fontSize: 12,
    color: 'white',
    fontWeight: 100,
    zIndex: 10,
  },
  navigation: {
    position: 'absolute',
    height: 28,
    width: '100%',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  navContainer: {
    display: 'flex',
  },
  navItem: {
    cursor: 'pointer',
    display: 'inline-block',
    backgroundColor: 'white',
    borderRadius: '100%',
    width: 8,
    height: 8,
    margin: '0 8px',
    opacity: 0.5,
  },
  backdrop: {
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.35)',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
  },
})

export default jssStyles
