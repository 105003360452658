/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

import Note from 'docs/components/Note'
import ComponentIdentifier from 'docs/components/ComponentIdentifier'

import styles from 'docs/DocsPageStyles'

const DocsTypography = withStyles(theme => ({
  root: {
    color: theme.palette.black65,
    fontFamily: 'Open Sans',
  },
}))(Typography)

class DocsScrollers extends Component {
  render() {
    const { classes } = this.props

    return (
      <a name="scrollers" className={classes.subSectionLink}>
        <a href="#scrollers">
          <DocsTypography variant="h6" className={classes.contentSubTitle}>
            Scrollers
          </DocsTypography>
        </a>
        <div className={classes.subSectionContent}>
          <ComponentIdentifier name="scroller" />
          <p>The simplest way to represent a scroller looks like this:</p>
          <div className={classes.imageContainer}>
            <img src="/images/docs/scroller-1.png" alt="Scroller sketch file" />
          </div>
          <Note description={<div>Every item in the scroller should be tagged as <code>item</code>, as shown in the picture above: <code>&#60;Group Name&#62;__item</code></div>} />
          <div className={classes.separateContainer} />
          <p>With the previous example we could easily create something like this:</p>
          <div className={classes.imageContainer}>
            <img src="/images/docs/scroller-1.gif" alt="Scroller in Torii" />
          </div>
          <div className={classes.separateContainer} />
          <p className={classes.strong} style={{paddingBottom: 4}}>Advanced</p>
          <p>You can even add handlers for faster lookup:</p>
          <div className={classes.imageContainer}>
            <img src="/images/docs/scroller-2.gif" alt="Advance scroller sketch file" />
          </div>
          <Note description={<div>Notice the group called <code>items--slides</code>. We have grouped the previous example and tagged it as <code>items--slides</code>. Make sure you use this naming convention.</div>} />
          <div className={classes.separateContainer} />
          <p>To add the handlers, you just need to group your custom handlers and tag them as <code>items--handlers</code>. Like this:</p>
          <div className={classes.imageContainer}>
            <img src="/images/docs/scroller-3.png" alt="Scroller handlers" />
          </div>
          <Note description={<div>Notice the selected layer <code>Oval--active</code>. Append the state <code>--active</code> and let Torii automatically render the active handler using that layer style. Cool huh? 😎</div>} />
        </div>
      </a>
    )
  }
}

DocsScrollers.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  routeParams: PropTypes.object,
}

export default withStyles(styles)(DocsScrollers)
