import blue from '@material-ui/core/colors/blue'

const TransitionAnimation = 'opacity 300ms ease-in-out'

const jssStyles = theme => ({
  container: {
    position: 'absolute',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      border: '1px solid transparent',
    },
  },
  layer: {
    position: 'absolute',
    opacity: 1,
    transition: TransitionAnimation,
    WebkitTransition: TransitionAnimation,
  },
  layerLoading: {
    opacity: 0.5,
  },
  containerHovered: {
    '&:before': {
      borderColor: blue[100],
    },
  },
  containerSelected: {
    '&:before': {
      borderColor: blue[400],
    },
  },
})

export default jssStyles
