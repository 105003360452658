import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { processLayerPosition } from 'utils'

import DraggableBlock from '_common/DraggableBlock'
import ResizeWrapper from '_common/ResizeWrapper/ResizeWrapper'

import styles from './OvalShapeStyles'

class OvalShape extends React.Component {
  handleMouseDown = e => {
    const { onMouseDown } = this.props
    onMouseDown && onMouseDown(e)
  }

  handleMouseEnter = e => {
    const { onMouseEnter } = this.props
    onMouseEnter && onMouseEnter(e)
  }

  handleMouseLeave = e => {
    const { onMouseLeave } = this.props
    onMouseLeave && onMouseLeave(e)
  }

  handlePositionChange = (e, position) => {
    const { onChange, layer } = this.props

    onChange &&
      onChange(e, 'frame', {
        ...layer.frame,
        x: position.x,
        y: position.y,
      })
  }

  handleFrameChange = (e, frame) => {
    const { onChange, layer } = this.props

    onChange &&
      onChange(e, 'frame', {
        ...layer.frame,
        ...frame,
      })
  }

  render() {
    const {
      classes,
      layer,
      layer: {
        frame: { width, height },
        style,
      },
      isHovered,
      isSelected,
      scaleRatio,
    } = this.props

    const containerClassNames = classNames({
      [classes.container]: true,
      [classes.containerHovered]: isHovered,
      [classes.containerSelected]: isSelected,
    })

    const position = processLayerPosition(layer, scaleRatio)

    const dimensions = {
      ...position,
      width: width * scaleRatio,
      height: height * scaleRatio,
    }

    const rootStyles = {
      ...dimensions,
      ...style,
    }

    return (
      <DraggableBlock
        position={position}
        onMouseDown={this.handleMouseDown}
        onChange={this.handlePositionChange}
      >
        <div className={containerClassNames} style={rootStyles}>
          <ResizeWrapper
            dimensions={dimensions}
            onChange={this.handleFrameChange}
            disabled={!isSelected}
          />
        </div>
      </DraggableBlock>
    )
  }
}

OvalShape.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  isHovered: PropTypes.bool,
  isSelected: PropTypes.bool,
  onMouseDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onChange: PropTypes.func,
  scaleRatio: PropTypes.number,
}

export default withStyles(styles)(OvalShape)
