import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import { signIn } from '../../actions/userActions'

import styles from './AuthPageStyles'

const InitialState = {
  email: '',
  password: '',
  error: null,
  message: null,
  loading: false,
}

class AuthPage extends Component {
  state = { ...InitialState }

  handleInputKeyDown = e => e.stopPropagation()

  onSubmit = async e => {
    const { actions } = this.props
    const { email, password } = this.state
    e.preventDefault()
    this.setState({ loading: true })

    const authUser = await actions.signIn(email, password)

    this.setState({ ...authUser, loading: false })
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    const { classes } = this.props
    const { email, password, error, message, loading } = this.state

    const isInvalid = password === '' || email === ''

    return (
      <div className={classes.container}>
        <form className={classes.form} onSubmit={this.onSubmit}>
          {loading && (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          )}
          <Typography variant="subtitle2">Login</Typography>

          <TextField
            label="Email Address"
            name="email"
            value={email}
            margin="normal"
            type="email"
            onChange={this.onChange}
            onKeyDown={this.handleInputKeyDown}
          />
          <TextField
            label="Password"
            name="password"
            value={password}
            margin="normal"
            type="password"
            onChange={this.onChange}
            onKeyDown={this.handleInputKeyDown}
          />

          {error && message && (
            <Typography variant="subtitle2" className={classes.errorMessage}>
              {message}
            </Typography>
          )}

          <Button className={classes.action} variant="outlined" type="submit" disabled={isInvalid}>
            Sign in
          </Button>
        </form>
      </div>
    )
  }
}

AuthPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    signIn: PropTypes.func.isRequired,
  }),
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ signIn }, dispatch),
  }
}

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withStyles(styles)
)(AuthPage)
