/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import Layer from 'components/Layer'

import { findToriiPropByName, filterToriiPropByName } from 'utils'

import styles from './ToriiTabsStyles'

const Background = {
  prop: '__background__',
  value: '#f5f5f5',
}

const TabsHeaderProp = {
  prop: '__tabs-header__',
  value: [],
}

const Headers = {
  prop: '__header__',
  value: [],
}

const TabsContentProp = {
  prop: '__tabs-content__',
  value: [],
}

const Content = {
  prop: '__content__',
  value: [],
}

class ToriiTabs extends Component {
  state = {
    tabIndex: 0,
    containerWidth: null,
  }

  componentDidMount() {
    const { width: containerWidth } = this.ref.current.getBoundingClientRect()
    this.setState({ containerWidth })
  }

  ref = React.createRef()

  handleTabChange = (e, newValue) => {
    this.setState({ tabIndex: newValue })
  }

  render() {
    const { classes, props, artboardEvents, storageBucketId, editMode } = this.props
    const { tabIndex, containerWidth } = this.state

    const { value: headerValue } = findToriiPropByName(props, TabsHeaderProp.prop) || TabsHeaderProp
    const { value: contentValue } =
      findToriiPropByName(props, TabsContentProp.prop) || TabsContentProp

    const { value: backgroundValue } =
      findToriiPropByName(headerValue, Background.prop) || Background
    const headers = filterToriiPropByName(headerValue, Headers.prop) || Headers.value
    const contents = filterToriiPropByName(contentValue, Content.prop) || Content.value

    const containerStyle = {
      backgroundColor: backgroundValue,
    }

    const currentContent = contents.reverse()[tabIndex]

    let { frame, layers } = currentContent.value

    frame = {
      ...frame,
      width: 414,
    }

    layers = Object.values(layers).filter(l => l.name !== Content.prop)

    const { scrollTop } = artboardEvents

    const headerContainerStyles = scrollTop
      ? {
          position: 'fixed',
          transform: `translateY(${scrollTop}px)`,
        }
      : {}

    return (
      <div className={classes.container} ref={this.ref}>
        <div className={classes.headerContainer} style={headerContainerStyles}>
          <Tabs
            classes={{ flexContainer: classes.flexContainer }}
            className={classes.tabsHeader}
            value={tabIndex}
            onChange={this.handleTabChange}
            centered
            style={containerStyle}
          >
            {headers &&
              headers.reverse().map((header, idx) => {
                const { text, color } = header.value

                const tabStyles = {
                  color,
                  width: containerWidth / headers.length,
                }

                return (
                  <Tab
                    className={classes.tabHeaderValue}
                    label={text}
                    key={`tab-${idx}`}
                    style={tabStyles}
                  />
                )
              })}
          </Tabs>
        </div>
        {currentContent && (
          // TODO: Treat somehow layers with editable capabilities
          <div className={classes.content}>
            <div style={frame}>
              {layers.map(layer => {
                return (
                  <Layer
                    layer={layer}
                    artboardFrame={frame}
                    key={layer.id}
                    storageBucketId={storageBucketId}
                    artboardEvents={artboardEvents}
                    editMode={editMode}
                  />
                )
              })}
            </div>
          </div>
        )}
      </div>
    )
  }
}

ToriiTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  props: PropTypes.arrayOf(PropTypes.object).isRequired,
  artboardEvents: PropTypes.object,
  storageBucketId: PropTypes.string,
  editMode: PropTypes.bool,
}

export default withStyles(styles)(ToriiTabs)
