/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

import styles from './ToriiSlideShowStyles'

const SlideSymbol = {
  prop: '__slideshowsymbol__',
  value: [],
}

const SlideItemPaths = {
  title: '384FF19D-6AAD-4DEE-83CF-231BB57054A3',
  subtitle: '00C8F0B6-4079-41CB-93BB-119C54FC3A0D',
  image: '0795CE8B-2988-47C8-AD78-10923DE209C5',
}

const getSlideItemProps = propsValue => {
  const titleProp = propsValue.find(p => p.path === SlideItemPaths.title)
  const subtitleProp = propsValue.find(p => p.path === SlideItemPaths.subtitle)
  const imageProp = propsValue.find(p => p.path === SlideItemPaths.image)

  return {
    titleValue: (titleProp && titleProp.value) || '',
    subtitleValue: (subtitleProp && subtitleProp.value) || '',
    imageValue: (imageProp && imageProp.value) || '',
  }
}

class ToriiSlideShow extends Component {
  state = {
    itemIndex: 0,
  }

  handleSlideShow = (e, itemIndex) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({ itemIndex })
  }

  render() {
    const { classes, props } = this.props
    const { itemIndex } = this.state

    const items = []
    props.forEach(({ prop, value: propsValue }) => {
      if (prop === SlideSymbol.prop) {
        items.push(getSlideItemProps(propsValue))
      }
    })

    const itemsProcessed = items.reverse()

    return (
      <div className={classes.container}>
        {itemsProcessed &&
          itemsProcessed.map((item, itemId) => (
            <div
              key={`torii-slideshow-${itemId}`}
              className={classes.item}
              style={{
                visibility: itemIndex === itemId ? 'visible' : 'hidden',
                opacity: itemIndex === itemId ? 1 : 0,
                backgroundImage: `url(data:image/png;base64,${item.imageValue})`,
              }}
            >
              <Typography variant="body1" className={classes.title}>
                {item.titleValue}
              </Typography>
              <Typography variant="body2" className={classes.subtitle}>
                {item.subtitleValue}
              </Typography>
              <div className={classes.backdrop} />
            </div>
          ))}
        <div className={classes.navigation}>
          <div className={classes.navContainer}>
            {itemsProcessed &&
              itemsProcessed.map((item, itemId) => (
                <span
                  key={`torii-slideshow-nav-${itemId}`}
                  className={classes.navItem}
                  style={{ opacity: itemIndex === itemId ? 1 : 0.5 }}
                  onClick={e => this.handleSlideShow(e, itemId)}
                />
              ))}
          </div>
        </div>
      </div>
    )
  }
}

ToriiSlideShow.propTypes = {
  classes: PropTypes.object.isRequired,
  props: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default withStyles(styles)(ToriiSlideShow)
