import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import * as utils from 'utils'

import DraggableBlock from '_common/DraggableBlock'

import styles from './LineShapeStyles'

const PointIds = {
  p1: 'p1',
  p2: 'p2',
}

class LineShape extends React.Component {
  anchorPoint = {
    x: 0,
    y: 0,
  }

  handleMouseDown = e => {
    const { onMouseDown } = this.props
    onMouseDown && onMouseDown(e)
  }

  handleMouseEnter = e => {
    const { onMouseEnter } = this.props
    onMouseEnter && onMouseEnter(e)
  }

  handleMouseLeave = e => {
    const { onMouseLeave } = this.props
    onMouseLeave && onMouseLeave(e)
  }

  handleAnchorPositionChange = (e, position) => {
    const {
      onChange,
      layer: {
        points: { p1, p2 },
      },
    } = this.props

    const offsetAnchorPosition = {
      x: position.x - this.anchorPoint.x,
      y: position.y - this.anchorPoint.y,
    }
    onChange &&
      onChange(e, 'points', {
        p1: {
          x: p1.x + offsetAnchorPosition.x,
          y: p1.y + offsetAnchorPosition.y,
        },
        p2: {
          x: p2.x + offsetAnchorPosition.x,
          y: p2.y + offsetAnchorPosition.y,
        },
      })
  }

  handlePointPositionChange = (e, position, node) => {
    const { onChange, layer } = this.props

    onChange &&
      onChange(e, 'points', {
        ...layer.points,
        [node.id]: {
          x: position.x,
          y: position.y,
        },
      })
  }

  getLineStyle() {
    const {
      layer: {
        points: { p1, p2 },
        style,
      },
      scaleRatio,
    } = this.props

    const p1Scaled = { x: p1.x * scaleRatio, y: p1.y * scaleRatio }
    const p2Scaled = { x: p2.x * scaleRatio, y: p2.y * scaleRatio }

    const width = utils.distance(p1Scaled, p2Scaled)
    const rotationAngle = utils.calcRotationBetweenPoints(p1Scaled, p2Scaled)
    this.anchorPoint = utils.calcPointAlongLine(p1Scaled, p2Scaled, 0)

    return {
      height: 1,
      backgroundColor: '#000000',
      ...style,
      width: `${width}px`,
      transform: `translate(${this.anchorPoint.x}px, ${
        this.anchorPoint.y
      }px) rotate(${rotationAngle}deg)`,
    }
  }

  render() {
    const {
      classes,
      layer: {
        points: { p1, p2 },
      },
      isHovered,
      isSelected,
      scaleRatio,
    } = this.props

    const containerClassNames = classNames({
      [classes.container]: true,
      [classes.containerHovered]: isHovered,
      [classes.containerSelected]: isSelected,
    })

    const p1Scaled = { x: p1.x * scaleRatio, y: p1.y * scaleRatio }
    const p2Scaled = { x: p2.x * scaleRatio, y: p2.y * scaleRatio }

    const containerStyle = this.getLineStyle()

    return (
      <DraggableBlock
        position={this.anchorPoint}
        onMouseDown={this.handleMouseDown}
        onChange={this.handleAnchorPositionChange}
      >
        <div className={containerClassNames} style={containerStyle}>
          <div className={classes.containerOffset}>
            {isSelected && (
              <React.Fragment>
                <DraggableBlock
                  position={p1Scaled}
                  onMouseDown={this.handleMouseDown}
                  onChange={this.handlePointPositionChange}
                >
                  <div className={classNames(classes.point, classes.point1)} id={PointIds.p1} />
                </DraggableBlock>
                <DraggableBlock
                  position={p2Scaled}
                  onMouseDown={this.handleMouseDown}
                  onChange={this.handlePointPositionChange}
                >
                  <div className={classNames(classes.point, classes.point2)} id={PointIds.p2} />
                </DraggableBlock>
              </React.Fragment>
            )}
          </div>
        </div>
      </DraggableBlock>
    )
  }
}

LineShape.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  isHovered: PropTypes.bool,
  isSelected: PropTypes.bool,
  onMouseDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onChange: PropTypes.func,
  scaleRatio: PropTypes.number,
}

export default withStyles(styles)(LineShape)
