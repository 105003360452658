import update from 'immutability-helper'

import initialState from './initialState'
import { SET_CURRENT_PAGE, FETCH_PROJECT_PAGE } from '../constants/actionTypes'

export default (state = initialState.page, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return update(state, {
        currentPage: { $set: action.pageName },
      })
    case FETCH_PROJECT_PAGE:
      return update(state, {
        project: {
          $set: {
            ...initialState.page.project,
            ...action.payload,
          },
        },
      })
    default:
      return state
  }
}
