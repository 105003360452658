import { createStore, compose, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'react-router-redux'
import createDebounce from 'redux-debounced'
import throttle from 'lodash/throttle'
import merge from 'lodash/merge'
import get from 'lodash/get'

import * as pageTypes from 'constants/pageTypes'
import { saveStoreToLocalStorage, loadStoreFromLocalStorage } from 'utils/localStorage'
import FirebaseAPI, { ConnectionTypes } from 'api/FirebaseAPI'
import initialState from './reducers/initialState'
import rootReducer from './reducers'

export const history = createBrowserHistory()

function updateFirebaseConnections(store) {
  const { page } = store.getState()
  const { MakerLayers, ScreenLayers } = ConnectionTypes
  const { connections, connect, disconnect } = FirebaseAPI
  switch (page.currentPage) {
    case pageTypes.MakerPage: {
      const projectId = get(page, 'project.projectId')

      // Maker layers are bound at the project level
      if (projectId && !connections[MakerLayers].ref) {
        connect(
          store,
          MakerLayers,
          { projectId }
        )
      } else if (connections[MakerLayers].ref && projectId !== connections[MakerLayers].projectId) {
        disconnect(MakerLayers)
      }
      break
    }
    case pageTypes.ScreenPage: {
      const projectId = get(page, 'project.id')
      const screenId = get(page, 'project.screenId')
      // Screen layers are bound at the project/screen level
      if (projectId && screenId && !connections[ScreenLayers].ref) {
        connect(
          store,
          ScreenLayers,
          { projectId, screenId }
        )
      } else if (connections[ScreenLayers].ref && screenId !== connections[ScreenLayers].screenId) {
        disconnect(ScreenLayers)
      }
      break
    }
    default:
      break
  }
}

function configureStore() {
  // Give the router middleware a history to attach itself to
  const attachedRouterMiddleware = routerMiddleware(history)

  const debounceMiddleware = createDebounce()
  // Load subset of state from localStorage. This will override the initialState in reducers.
  // @see http://redux.js.org/docs/recipes/reducers/InitializingState.html
  // Note: Since preloaded state overrides initialState when provided via createStore, we need to
  // first deep merge these two together. This allows values in local storage to overwrite the
  // equivalent values in initialState if necessary.
  const preloadedState = merge({}, initialState, loadStoreFromLocalStorage())

  // Allow the store to work with the Redux DevTools Chrome Extension:
  // https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd
  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(
      // Debounce middleware must be applied before thunkMiddleware in order to work in thunks.
      applyMiddleware(debounceMiddleware, thunkMiddleware, attachedRouterMiddleware)
    )
  )

  // Listen for store updates and save a subset of redux store to localStorage. Throttled to reduce
  // the performance overhead from consecutive store updates.
  // @see https://stackoverflow.com/a/35675304/2275300
  store.subscribe(throttle(_ => saveStoreToLocalStorage(store.getState()), 5000))
  store.subscribe(_ => updateFirebaseConnections(store))
  return store
}

export default configureStore()
