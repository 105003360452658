import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import MaterialRadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import styles from './RadioGroupStyles'

class RadioGroup extends Component {
  state = {
    value: '',
  }

  handleChange = e => {
    this.setState({ value: e.target.value })
  }

  render() {
    const {
      classes,
      layer,
      layer: {
        frame: { width, height, y: top, x: left },
        layers,
      },
    } = this.props

    const { value } = this.state

    const containerStyles = {
      top,
      left,
      width,
      height,
    }

    return (
      <div className={classes.container} style={containerStyles}>
        <MaterialRadioGroup value={value} onChange={this.handleChange}>
          {layer.layers &&
            Object.keys(layers).map(layerId => {
              return <FormControlLabel key={layerId} value={layerId} control={<Radio />} />
            })}
        </MaterialRadioGroup>
      </div>
    )
  }
}

RadioGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  artboardEvents: PropTypes.object,
  storageBucketId: PropTypes.string,
}

export default withStyles(styles)(RadioGroup)
