import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Drawer from '@material-ui/core/Drawer'

import { getLayerInformation, stopPropagation } from 'utils'

import TextForm from '_forms/TextForm'

import styles from './AppDrawerStyles'

class AppDrawer extends Component {
  handleMouseDown = e => {
    stopPropagation(e)
  }

  handleChange = (e, propPath, value, parentRef) => {
    stopPropagation(e)
    const { onLayerChange, selectedLayerId } = this.props
    onLayerChange(selectedLayerId, propPath, value, parentRef)
  }

  render() {
    const { classes, project, selectedLayerId } = this.props
    const { layer, parentRef } = getLayerInformation(project, selectedLayerId)
    const isOpen = !!selectedLayerId

    const containerClassNames = classNames({
      [classes.container]: true,
      [classes.containerOpened]: isOpen,
    })

    if (!layer || layer.type !== 'text') {
      return null
    }

    return (
      <Drawer
        className={containerClassNames}
        variant="persistent"
        anchor="left"
        open={isOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onMouseDown={this.handleMouseDown}
      >
        {layer && layer.type === 'text' && (
          <TextForm layer={layer} parentRef={parentRef} onChange={this.handleChange} />
        )}
      </Drawer>
    )
  }
}

AppDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  project: PropTypes.object,
  selectedLayerId: PropTypes.string,
  onLayerChange: PropTypes.func,
}

export default withStyles(styles)(AppDrawer)
