import { SET_ARTBOARD_FLOW } from 'constants/actionTypes'
import { getTransitionFromType } from 'utils'

export function setArtboardFlow(flow) {
  return dispatch => {
    const { destinationArtboardID, animationType } = flow

    const transition = getTransitionFromType(animationType)

    dispatch({
      type: SET_ARTBOARD_FLOW,
      payload: {
        activeArtboardId: destinationArtboardID,
        animationType,
      },
    })

    setTimeout(() => {
      dispatch({
        type: SET_ARTBOARD_FLOW,
        payload: {
          activeArtboardId: destinationArtboardID,
          animationType: null,
        },
      })
    }, transition.duration)
  }
}
