/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

import Note from 'docs/components/Note'
import ComponentIdentifier from 'docs/components/ComponentIdentifier'

import styles from 'docs/DocsPageStyles'

const DocsTypography = withStyles(theme => ({
  root: {
    color: theme.palette.black65,
    fontFamily: 'Open Sans'
  }
}))(Typography)

class DocsCheckboxes extends Component {
  render() {
    const { classes } = this.props

    return (
      <a name="checkboxes" className={classes.subSectionLink}>
        <a href="#checkboxes">
          <DocsTypography variant="h6" className={classes.contentSubTitle}>
            Checkboxes
          </DocsTypography>
        </a>
        <div className={classes.subSectionContent}>
          <ComponentIdentifier name="checkbox" />
          <p>
            A checkbox should be represented by two layer groups. One group represents the checkbox checked and it should have the state suffix{' '}
            <span>--checked</span>. The other group represents the unchecked checkbox. Then turn visibility off from the group that is not going to appear first
            in runtime. In Sketch, it usually looks like this:
          </p>
          <div className={classes.imageContainer}>
            <img src="/images/docs/layers-checkbox.png?id=12" alt="Checkbox layers" />
          </div>
          <p>Some samples you can easily create:</p>
          <div className={classes.imageContainer}>
            <img src="/images/docs/sample-checkboxes.png" alt="Sample checkboxes" />
          </div>
          <Note description="Make sure to turn visibility off from the layer group you don't want to see by default. For example, if you want the checkbox to appear unchecked at first, turn visibility off of the group that represents the checked component." />
        </div>
      </a>
    )
  }
}

DocsCheckboxes.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  routeParams: PropTypes.object
}

export default withStyles(styles)(DocsCheckboxes)
