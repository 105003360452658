import update from 'immutability-helper'

import { SET_ARTBOARD_FLOW } from 'constants/actionTypes'
import initialState from './initialState'

export default (state = initialState.artboardFlow, action) => {
  switch (action.type) {
    case SET_ARTBOARD_FLOW:
      return update(state, { $set: action.payload })
    default:
      return state
  }
}
