import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import styles from './ComponentIdentifierStyles'

class ComponentIdentifier extends Component {
  render() {
    const { classes, name } = this.props

    return (
      <div className={classes.container}>
        <div>component identifier</div>
        <code>{name}</code>
      </div>
    )
  }
}

ComponentIdentifier.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
}

export default withStyles(styles)(ComponentIdentifier)
