import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import styles from './DecoratorStyles'

class Decorator extends Component {
  render() {
    const {
      classes,
      layer: { name, frame, type },
      scaleRatio,
      artboardEvents,
      children,
    } = this.props

    if (name.includes('--float')) {
      const { x, y: layerY } = frame
      const {
        artboardRect: { x: artboardX, height: artboardHeight },
        windowRect: { height: windowHeight },
      } = artboardEvents

      const handleTop = artboardHeight / 2 > layerY

      const verticalPosition = handleTop
        ? { top: layerY * scaleRatio }
        : { bottom: (Math.max(windowHeight, artboardHeight) - layerY) * scaleRatio }

      const left = artboardX + x

      const decoratorStyle = {
        ...verticalPosition,
        left: left * scaleRatio,
      }

      if (type === 'hotspot') {
        decoratorStyle.zIndex = 3000
      }

      return (
        <div className={classes.floatContainer} style={decoratorStyle}>
          {children}
        </div>
      )
    }

    return children
  }
}

Decorator.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  scaleRatio: PropTypes.number,
  artboardEvents: PropTypes.object,
  children: PropTypes.element,
}

export default withStyles(styles)(Decorator)
