const jssStyles = theme => ({
  container: {
    position: 'relative',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    // overflow: 'scroll',
    overflowScrolling: 'touch',
    WebkitOverflowScrolling: 'touch',
    // background: 'white',
    // transform: 'translateZ(0)',
  },
  artboardContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  artboardFrameContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  artboardFrame: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    fontFamily: 'Roboto',
    position: 'absolute',
    overflow: 'hidden',
    backgroundColor: 'white',
    zIndex: 5,
    top: 0,
    left: 0,
  },
})

export default jssStyles
