import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { processLayerPosition } from 'utils'

import DraggableBlock from '_common/DraggableBlock'
import ProgressiveImage from '_common/ProgressiveImage'
import ResizeWrapper from '_common/ResizeWrapper/ResizeWrapper'

import styles from './ImageLayerStyles'

class ImageLayer extends React.Component {
  state = {
    disableSelection: false,
  }

  componentDidMount() {
    const { current: imageEl } = this.imageRef
    const {
      layer: {
        frame: { width, height, x, y },
      },
    } = this.props

    const artboardRect = imageEl.parentElement.getBoundingClientRect()

    const disableSelection =
      x === 0 && y === 0 && artboardRect.width === width && artboardRect.height === height

    this.setState({ disableSelection })
  }

  imageRef = React.createRef()

  handleMouseDown = e => {
    const { onMouseDown, layer } = this.props
    onMouseDown && onMouseDown(e, layer.id)
  }

  handleMouseEnter = e => {
    const { onMouseEnter, layer } = this.props
    onMouseEnter && onMouseEnter(e, layer.id)
  }

  handleMouseLeave = e => {
    const { onMouseLeave, layer } = this.props
    onMouseLeave && onMouseLeave(e, layer.id)
  }

  handlePositionChange = (e, position) => {
    const { onChange, layer } = this.props

    onChange &&
      onChange(e, 'frame', {
        ...layer.frame,
        x: position.x,
        y: position.y,
      })
  }

  handleFrameChange = (e, frame) => {
    const { onChange, layer } = this.props

    onChange &&
      onChange(e, 'frame', {
        ...layer.frame,
        ...frame,
      })
  }

  render() {
    const {
      classes,
      isHovered,
      isSelected,
      layer,
      layer: {
        frame: { width, textAlign },
        zIndex,
      },
      storageBucketId,
      scaleRatio,
    } = this.props
    const { disableSelection } = this.state

    const containerClassNames = classNames({
      [classes.container]: true,
      [classes.containerHovered]: isHovered,
      [classes.containerSelected]: isSelected,
      [classes.disableSelection]: disableSelection,
    })

    const position = processLayerPosition(layer, scaleRatio)

    const dimensions = {
      ...position,
      width: width * scaleRatio,
    }

    const rootStyles = {
      ...dimensions,
      textAlign,
      zIndex,
    }

    const resizerDimensions = {
      ...dimensions,
      height: '100%',
    }

    return (
      <DraggableBlock
        onMouseDown={this.handleMouseDown}
        position={position}
        onChange={this.handlePositionChange}
        disabled={disableSelection}
      >
        <div
          className={containerClassNames}
          onMouseDown={this.handleMouseDown}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          style={rootStyles}
          ref={this.imageRef}
        >
          <ResizeWrapper
            dimensions={resizerDimensions}
            onChange={this.handleFrameChange}
            axis="horizontal"
            disabled={!isSelected || disableSelection}
          >
            <ProgressiveImage
              classes={classes}
              layer={layer}
              draggable={false}
              storageBucketId={storageBucketId}
            />
          </ResizeWrapper>
        </div>
      </DraggableBlock>
    )
  }
}

ImageLayer.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  isHovered: PropTypes.bool,
  isSelected: PropTypes.bool,
  onMouseDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onChange: PropTypes.func,
  storageBucketId: PropTypes.string,
  scaleRatio: PropTypes.number,
}

export default withStyles(styles)(ImageLayer)
