import React from 'react'
import PropTypes from 'prop-types'

const Key = '5cc04861-ceab-4495-ba0c-cd6fae335421'

class ZendeskHelper extends React.Component {
  componentWillMount() {
    const tag = document.createElement('script')
    tag.id = 'ze-snippet'
    tag.async = false
    tag.src = `https://static.zdassets.com/ekr/snippet.js?key=${Key}`
    document.body.appendChild(tag)
  }

  render() {
    const { children } = this.props
    return children
  }
}

ZendeskHelper.propTypes = {
  children: PropTypes.element,
}

export default ZendeskHelper
