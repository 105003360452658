import blue from '@material-ui/core/colors/blue'

const jssStyles = theme => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: 560,
  },
  form: {
    display: 'block',
    padding: 28,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',

    '& > *': {
      flex: 1,
    },

    '& > *:nth-child(2)': {
      marginLeft: 12,
    },
  },
  errorMessage: {},
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 32,
  },
  signInText: {
    flex: 1,
    alignSelf: 'flex-end',
    fontWeight: 400,
  },
  signInLink: {
    cursor: 'pointer',
    color: blue[800],
  },
  loadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'white',
    opacity: 1,
    zIndex: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '@media (max-width: 600px)': {
    container: {
      width: '100%',
    },
    row: {
      display: 'flex',
      flexDirection: 'column',
      '& > *:nth-child(2)': {
        marginLeft: 0,
      },
    },
    actions: {
      flexDirection: 'column-reverse',

      '& h6': {
        marginTop: 42,
      },
    },
  },
})

export default jssStyles
