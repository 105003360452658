const jssStyles = theme => ({
  container: {
    display: 'block',
    height: '100%',
    width: '100%',
  },
  header: {
    padding: 32,
  },
  projectTitle: {
    textTransform: 'uppercase',
  },
  projectUrl: {
    fontSize: 24,
  },
  pageLayout: {
    position: 'relative',
    padding: 32,
    borderRadius: 8,
    marginBottom: 16,
  },
  pageItem: {
    position: 'relative',
    transform: 'box-shadow ',
    boxShadow: '0 1px 2px rgba(0,0,0,0.15)',
    margin: '24px 24px 24px 0',

    '&:after': {
      content: '""',
      boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
      opacity: 0,
      transition: 'opacity 0.3s ease-in-out',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
    },
    '&:hover:after': {
      opacity: 1,
    },
  },
})

export default jssStyles
