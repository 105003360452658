export default {
  artboardFlow: {
    activeArtboardId: null,
    animationType: null,
  },
  page: {
    currentPage: null,
    project: {},
  },
  canvas: {
    originMousePosition: null,
    selectedLayerId: null,
    hoveredLayerId: null,
    defaultLayer: {},
    project: {
      storageBucketId: null,
      artboard: {},
      layers: {},
    },
    toolsState: {
      toolState: null,
      activeTool: null,
      lineToolState: {
        startAttachment: null,
        endAttachment: null,
      },
    },
  },
  projectPage: {
    project: {
      id: null,
      name: null,
      screen: {},
    },
    screen: {},
  },
  projects: {},
  user: {
    email: null,
    token: null,
  },
}
