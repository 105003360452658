import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { css } from 'emotion'
import get from 'lodash/get'
import values from 'lodash/values'
import sortBy from 'lodash/sortBy'
import { withStyles } from '@material-ui/core/styles'

import Swiper from 'react-id-swiper'

import { processLayerPosition, parseLayerName } from 'utils'

import Layer from 'components/Layer'

import styles from './ScrollerStyles'

const OffsetPx = 20

const ModifierSlides = 'slides'
const ModifierPagination = 'handlers'
const ActivePagination = 'active'

const getComponentByLayersAndModifierName = (layers, modName, equalAsModifier = true) => {
  return values(layers).find(l => {
    const { modifierName } = parseLayerName(l.name)
    return equalAsModifier ? modifierName === modName : modifierName !== modName
  })
}

const extractSlideValuesFromLayer = layer => {
  const { layers } = layer
  const pageContainer = getComponentByLayersAndModifierName(layers, ModifierPagination)

  if (!pageContainer) {
    return { slideContainer: { ...layer } }
  }

  const activeLayer = getComponentByLayersAndModifierName(pageContainer.layers, ActivePagination)
  const unactiveLayer = getComponentByLayersAndModifierName(
    pageContainer.layers,
    ActivePagination,
    false
  )

  const slideContainer = getComponentByLayersAndModifierName(layers, ModifierSlides)

  return { pagination: { activeLayer, unactiveLayer, frame: pageContainer.frame }, slideContainer }
}

class Scroller extends Component {
  state = {
    slides: [],
    spaceBetween: null,
    pagination: null,
  }

  componentWillMount() {
    const { layer } = this.props

    const {
      slideContainer: { layers },
      pagination,
    } = extractSlideValuesFromLayer(layer)

    // Sorting slides by `x` position in the scroller
    const slides = sortBy(values(layers), l => {
      return l.frame.x
    })

    const frameA = get(slides, '[0].frame')
    const frameB = get(slides, '[1].frame')

    if (!frameA) {
      return
    }

    const spaceBetween = frameB ? frameB.x - (frameA.x + frameA.width) : 0

    this.setState({ slides, spaceBetween, pagination })
  }

  getPaginationProps() {
    const { pagination } = this.state

    if (!pagination) {
      return {}
    }

    const { activeLayer, unactiveLayer } = pagination

    const bulletClassName =
      unactiveLayer &&
      css`
        ${{
          ...unactiveLayer.frame,
          ...unactiveLayer.style,
        }}
      `

    const bulletActiveClassName =
      activeLayer &&
      css`
        ${{
          ...activeLayer.frame,
          ...activeLayer.style,
          opacity: 1,
        }}
      `

    return {
      pagination: {
        el: '.swiper-pagination',
        bulletActiveClass: bulletActiveClassName,
        clickable: true,
        renderBullet: (index, className) => {
          const bulletElement = <span className={classNames(bulletClassName, className)} />

          return ReactDOMServer.renderToString(bulletElement)
        },
      },
    }
  }

  renderSlides() {
    const {
      layer: frameLayer,
      layer: { frame: containerFrame },
      scaleRatio,
      artboardEvents,
      storageBucketId,
      editMode,
    } = this.props
    const { slides } = this.state

    const { left: leftContainer } = processLayerPosition(frameLayer, scaleRatio)

    return slides.map(layerSlide => {
      const containerRect = {
        height: layerSlide.frame.height * scaleRatio,
        width: layerSlide.frame.width * scaleRatio,
        top: OffsetPx,
        left: leftContainer,
      }

      const layerSlideRelative = {
        ...layerSlide,
        frame: {
          ...layerSlide.frame,
          x: 0,
          y: 0,
        },
      }

      return (
        <div style={containerRect} key={layerSlide.id}>
          {layerSlide.layers &&
            values(layerSlide.layers).map(l => {
              return (
                <Layer
                  layer={l}
                  artboardFrame={containerFrame}
                  artboardEvents={artboardEvents}
                  storageBucketId={storageBucketId}
                  scaleRatio={scaleRatio}
                  editMode={editMode}
                  key={l.id}
                />
              )
            })}
          {!layerSlide.layers && (
            <Layer
              layer={layerSlideRelative}
              artboardFrame={containerFrame}
              artboardEvents={artboardEvents}
              storageBucketId={storageBucketId}
              scaleRatio={scaleRatio}
              editMode={editMode}
              key={layerSlide.id}
            />
          )}
        </div>
      )
    })
  }

  render() {
    const {
      classes,
      layer,
      layer: { frame },
      scaleRatio,
      artboardEvents: { artboardRect },
    } = this.props
    const { spaceBetween } = this.state

    const { top: topContainer, left: leftContainer } = processLayerPosition(layer, scaleRatio)

    const containerStyles = {
      top: topContainer - OffsetPx,
      left: 0,
      width: artboardRect.width - frame.x * scaleRatio + leftContainer,
      height: frame.height * scaleRatio + OffsetPx * 2,
    }

    const paginationProps = this.getPaginationProps()

    const swiperParams = {
      slidesPerView: 'auto',
      spaceBetween,
      mousewheel: true,
      freeMode: true,
      ...paginationProps,
    }

    return (
      <div className={classes.container} style={containerStyles}>
        <Swiper {...swiperParams} style={containerStyles}>
          {this.renderSlides()}
          <div style={{ width: leftContainer }} />
        </Swiper>
      </div>
    )
  }
}

Scroller.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  artboardEvents: PropTypes.object,
  storageBucketId: PropTypes.string,
  scaleRatio: PropTypes.number,
  editMode: PropTypes.bool,
}

export default withStyles(styles)(Scroller)
