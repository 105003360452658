import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import styles from './DeviceImageStyles'

class DeviceImage extends Component {
  render() {
    const { classes, src } = this.props

    return (
      <div className={classes.container}>
        <img className={classes.display} src="/images/docs/iphone-x.png" alt="Iphone X frame" />
        <img className={classes.image} src={src} alt="Sample" />
      </div>
    )
  }
}

DeviceImage.propTypes = {
  classes: PropTypes.object.isRequired,
  src: PropTypes.string.isRequired,
}

export default withStyles(styles)(DeviceImage)
