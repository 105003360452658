import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Layer from 'components/Layer'

import styles from './TabsStyles'

const TabsType = {
  container: 'tabs',
  headers: 'tabs-headers',
  contents: 'tabs-contents',
}

class Tabs extends Component {
  state = {
    tabActive: 0,
  }

  setTabActive = tabActive => {
    this.setState({ tabActive })
  }

  renderHeaders(headersLayer) {
    const { classes, artboardEvents, storageBucketId, scaleRatio, editMode } = this.props
    const {
      layers,
      frame,
      frame: { x: left, y: top, width, height },
    } = headersLayer
    const { tabActive } = this.state

    const headersStyle = {
      top: top * scaleRatio,
      left: left * scaleRatio,
      width: width * scaleRatio,
      height: height * scaleRatio,
    }

    return (
      <div className={classes.headers} style={headersStyle}>
        {layers &&
          Object.keys(layers).map(layerId => {
            const componentLayer = layers[layerId]

            const handlersClassNames = classNames({
              [classes.header]: true,
              [classes.headerActive]:
                tabActive === Math.abs(Object.keys(layers).length - componentLayer.zIndex - 1),
            })

            return (
              <div
                key={layerId}
                className={handlersClassNames}
                onClick={() => {
                  this.setTabActive(
                    Math.abs(Object.keys(layers).length - componentLayer.zIndex - 1)
                  )
                }}
              >
                {componentLayer.layers &&
                  Object.keys(componentLayer.layers).map(childLayerId => {
                    const childLayer = componentLayer.layers[childLayerId]
                    return (
                      <Layer
                        key={childLayerId}
                        layer={childLayer}
                        artboardFrame={frame}
                        artboardEvents={artboardEvents}
                        storageBucketId={storageBucketId}
                        scaleRatio={scaleRatio}
                        editMode={editMode}
                      />
                    )
                  })}
              </div>
            )
          })}
      </div>
    )
  }

  renderContents(contentsLayer) {
    const { classes, artboardEvents, storageBucketId, scaleRatio, editMode } = this.props
    const {
      layers,
      frame,
      frame: { x: left, y: top, width, height },
    } = contentsLayer
    const { tabActive } = this.state

    const contentsStyle = {
      top: top * scaleRatio,
      left: left * scaleRatio,
      width: width * scaleRatio,
      height: height * scaleRatio,
    }

    return (
      <div className={classes.contents} style={contentsStyle}>
        {layers &&
          Object.keys(layers).map(layerId => {
            const componentLayer = layers[layerId]

            const contentsClassNames = classNames({
              [classes.content]: true,
              [classes.contentActive]:
                tabActive === Math.abs(Object.keys(layers).length - componentLayer.zIndex - 1),
            })

            return (
              <div
                key={layerId}
                className={contentsClassNames}
                onClick={() => {
                  this.setTabActive(
                    Math.abs(Object.keys(layers).length - componentLayer.zIndex - 1)
                  )
                }}
              >
                {componentLayer.layers &&
                  Object.keys(componentLayer.layers).map(childLayerId => {
                    const childLayer = componentLayer.layers[childLayerId]
                    return (
                      <Layer
                        key={childLayerId}
                        layer={childLayer}
                        artboardFrame={frame}
                        artboardEvents={artboardEvents}
                        storageBucketId={storageBucketId}
                        scaleRatio={scaleRatio}
                        editMode={editMode}
                      />
                    )
                  })}
              </div>
            )
          })}
      </div>
    )
  }

  renderContainer() {
    const {
      classes,
      layer: {
        frame: { width, height, y: top, x: left },
        layers,
        zIndex,
      },
      scaleRatio,
    } = this.props

    const containerStyles = {
      top: top * scaleRatio,
      left: left * scaleRatio,
      width: width * scaleRatio,
      height: height * scaleRatio,
      zIndex,
    }

    const headersLayerId = Object.keys(layers).find(layerId => {
      const componentLayer = layers[layerId]
      return componentLayer.name === TabsType.headers
    })

    const contentsLayerId = Object.keys(layers).find(layerId => {
      const componentLayer = layers[layerId]
      return componentLayer.name === TabsType.contents
    })

    return (
      <div className={classes.container} style={containerStyles}>
        {headersLayerId && this.renderHeaders(layers[headersLayerId])}
        {contentsLayerId && this.renderContents(layers[contentsLayerId])}
      </div>
    )
  }

  render() {
    const { layer } = this.props

    switch (layer.name) {
      case TabsType.container: {
        return this.renderContainer()
      }
      default: {
        return null
      }
    }
  }
}

Tabs.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  artboardEvents: PropTypes.object,
  storageBucketId: PropTypes.string,
  scaleRatio: PropTypes.number,
  editMode: PropTypes.bool,
}

export default withStyles(styles)(Tabs)
