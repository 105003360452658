import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'

import { stopPropagation } from 'utils'
import toolConfigList from 'constants/toolTypes'
import * as toolImages from 'svgs/tools'

import styles from './ToolbarStyles'

class Toolbar extends Component {
  handleToolChange = (e, toolName) => {
    stopPropagation(e)
    const { onChange } = this.props
    onChange && onChange(e, toolName)
  }

  render() {
    const {
      classes,
      className,
      toolsState: { activeTool },
      etsyPage,
    } = this.props

    const containerClassNames = classNames(
      {
        [classes.container]: true,
        [classes.etsyContainer]: etsyPage,
      },
      className
    )

    return (
      <div className={containerClassNames}>
        {toolConfigList.map(tool => {
          const toolClassName = classNames({
            [classes.toolButton]: true,
            [classes.toolActive]: activeTool === tool.name,
          })
          return (
            <IconButton
              key={tool.shortcutId}
              className={toolClassName}
              onClick={e => this.handleToolChange(e, tool.name)}
            >
              <img
                src={toolImages[tool.iconName]}
                alt={tool.shortcutDesc}
                className={classes.icon}
              />
            </IconButton>
          )
        })}
      </div>
    )
  }
}

Toolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  toolsState: PropTypes.object,
  onChange: PropTypes.func,
  etsyPage: PropTypes.bool,
}

export default withStyles(styles)(Toolbar)
