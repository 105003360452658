import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import ContentEditable from '_common/ContentEditable'

import styles from './TextBlockStyles'

class TextBlock extends Component {
  contentEditableRef = React.createRef()

  handleTextChange = e => {
    const { onChange } = this.props
    onChange && onChange(e, e.target.value)
  }

  handleMouseDown = e => {
    e.stopPropagation()
  }

  render() {
    const { classes, value } = this.props

    return (
      <div
        className={classes.container}
        onMouseDown={this.handleMouseDown}
        onClick={this.handleMouseDown}
        onDoubleClick={this.handleDoubleClick}
      >
        <ContentEditable
          onChange={this.handleTextChange}
          value={value}
          ref={this.contentEditableRef}
        />
      </div>
    )
  }
}

TextBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default withStyles(styles)(TextBlock)
