const jssStyles = theme => ({
  container: {
    // transition: 'height 0.3s ease-out',
    flexDirection: 'column',
    fontFamily: 'Roboto',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    position: 'fixed',
    overflow: 'hidden',
    zIndex: theme.zIndex.layerElevated,
  },
  title: {
    fontWeight: 500,
  },
})

export default jssStyles
