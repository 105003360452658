import blue from '@material-ui/core/colors/blue'

const LineOffset = 4
const SizeHandler = 6
const HandlerOffsetPos = -SizeHandler / 2 + LineOffset / 2

const PointStyle = {
  cursor: 'ew-resize',
  position: 'absolute',
  width: SizeHandler,
  height: SizeHandler,
  top: HandlerOffsetPos,
  backgroundColor: '#fff',
  border: '1px solid #a8a8a8',
  boxShadow: '0 1px 2px #a8a8a8',
}

const jssStyles = theme => ({
  container: {
    position: 'absolute',
    transformOrigin: 'center left',
    minHeight: 1,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
    },
  },
  containerOffset: {
    position: 'absolute',
    padding: `${LineOffset}px 0`,
    top: -(LineOffset / 2),
    left: 0,
    width: '100%',
  },
  containerHovered: {
    '&:before': {
      backgroundColor: blue[100],
    },
  },
  containerSelected: {
    cursor: 'grab',
    '&:before': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      cursor: 'grabbing',
    },
  },
  point: {
    ...PointStyle,
  },
  point1: {
    left: HandlerOffsetPos,
  },
  point2: {
    right: HandlerOffsetPos,
  },
})

export default jssStyles
