import React from 'react'
import TextFieldOutlined from './TextFieldOutlined'

import ButtonContained from './ButtonContained'
import ButtonOutlined from './ButtonOutlined'
import Checkbox from './Checkbox'
import Radio from './Radio'

const ComponentNames = {
  TextFieldOutlined,
  ButtonContained,
  ButtonOutlined,
  Checkbox,
  Radio,
}

export function getComponentFromLayer(layer, props) {
  const ComponentName = ComponentNames[layer.name]

  if (!ComponentName) return null

  return <ComponentName layer={layer} {...props} />
}
