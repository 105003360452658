/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

import ComponentIdentifier from 'docs/components/ComponentIdentifier'

import styles from 'docs/DocsPageStyles'

const DocsTypography = withStyles(theme => ({
  root: {
    color: theme.palette.black65,
    fontFamily: 'Open Sans',
  },
}))(Typography)

class DocsSliders extends Component {
  render() {
    const { classes } = this.props

    return (
      <a name="sliders" className={classes.subSectionLink}>
        <a href="#sliders">
          <DocsTypography variant="h6" className={classes.contentSubTitle}>
            Sliders
          </DocsTypography>
        </a>
        <div className={classes.subSectionContent}>
          <ComponentIdentifier name="slider" />
          <p>
            A slider is usually represented by a group of layers that contains two shapes, most
            times an <span>oval</span> layer and a <span>rectangle/line</span> layer. The oval
            usually becomes the thumb. It usually looks like this:
          </p>
          <div className={classes.imageContainer}>
            <img src="/images/docs/layers-slider.png" alt="Slider layers" />
          </div>
          <p>Some samples you can easily create:</p>
          <div className={classes.imageContainer}>
            <img src="/images/docs/sample-sliders.png" alt="Sample sliders" />
          </div>
        </div>
      </a>
    )
  }
}

DocsSliders.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  routeParams: PropTypes.object,
}

export default withStyles(styles)(DocsSliders)
