const jssStyles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  publishButton: {
    position: 'absolute',
    bottom: 30,
    right: 30,
  },
  header: {
    display: 'flex',
    height: 56,
    width: '100%',
    flexDirection: 'row-reverse',
  },
  downloadControls: {
    minWidth: 200,
    margin: '12px 16px',
  },
})

export default jssStyles
