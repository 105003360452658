import { formatFirebaseDictAsArray, formatArrayAsFirebaseDict } from 'utils/general'
import { replaceLayers } from 'actions/canvasActions'
import firebase from './firebase'
import { initializeLayer } from './responseInitializers'

export const ConnectionTypes = {
  Projects: 'Projects',
  MakerProjects: 'MakerProjects',
  MakerLayers: 'MakerLayers',
  ScreenLayers: 'ScreenLayers',
}

const FirebaseDB = firebase.app.database()
const ProjectsRef = FirebaseDB.ref('projects')
const MakerProjectsRef = FirebaseDB.ref('maker')

const DefaultConnextionsConfig = {
  [ConnectionTypes.Projects]: {
    ref: ProjectsRef,
  },
  [ConnectionTypes.MakerProjects]: {
    ref: MakerProjectsRef,
  },
  [ConnectionTypes.MakerLayers]: {
    // The firebase ref associated with `maker layers`.
    ref: null,
    artboard: {},
    // The project id associated with firebase connection.
    // Used to enable checks to see if a users connection should persist.
    projectId: null,
  },
  [ConnectionTypes.ScreenLayers]: {
    // The firebase ref associated with `screen layers`.
    ref: null,
    artboard: {},
    // The screen id associated with firebase connection.
    // Used to enable checks to see if a users connection should persist.
    screenId: null,
  },
}

class FirebaseAPI {
  connections = { ...DefaultConnextionsConfig }

  getConnectionRef = (ConnectionType, parentRef = null) => {
    return parentRef
      ? this.connections[ConnectionType][parentRef].ref
      : this.connections[ConnectionType].ref
  }

  signIn = async(email, password) => {
    const userAuth = await firebase.doSignInWithEmailAndPassword(email, password)
    return userAuth
  }

  signUp = async(email, password) => {
    const userAuth = await firebase.doCreateUserWithEmailAndPassword(email, password)
    return userAuth
  }

  submitProject = async project => {
    if (!this.connections[ConnectionTypes.Projects].ref) return
    this.connections[ConnectionTypes.Projects].ref.child(project.id).set(project)
    return project
  }

  getProjects = async() => {
    if (!this.connections[ConnectionTypes.Projects].ref) return
    const snapshot = await this.connections[ConnectionTypes.Projects].ref.once('value')
    return snapshot.val()
  }

  getProject = async projectId => {
    if (!this.connections[ConnectionTypes.Projects].ref) return
    const snapshot = await this.connections[ConnectionTypes.Projects].ref
      .child(projectId)
      .once('value')
    return snapshot.val()
  }

  getScreen = async(projectId, screenId) => {
    if (!this.connections[ConnectionTypes.Projects].ref) return
    const snapshot = await this.connections[ConnectionTypes.Projects].ref
      .child(`${projectId}/screen/${screenId}`)
      .once('value')
    return snapshot.val()
  }

  saveScreen = async(projectId, screenId, screen) => {
    if (!this.connections[ConnectionTypes.Projects].ref) return
    this.connections[ConnectionTypes.Projects].ref
      .child(`${projectId}/screen/${screenId}`)
      .set(screen)
    return screen
  }

  getMakerProject = async projectId => {
    if (!this.connections[ConnectionTypes.MakerProjects].ref) return
    const snapshot = await this.connections[ConnectionTypes.MakerProjects].ref
      .child(projectId)
      .once('value')
    return snapshot.val()
  }

  /**
   * Create a maker `maker layer` in firebase.
   */
  createMakerLayer = (layer, parentRef = null) => {
    if (!this.connections[ConnectionTypes.MakerLayers].ref) return
    const ref = this.getConnectionRef(ConnectionTypes.MakerLayers, parentRef)
    ref.child(layer.id).set(layer)
  }

  /**
   * Remove a a list of `maker layers` from firebase.
   */
  removeMakerLayers = (layerIds, parentRef = null) => {
    if (!this.connections[ConnectionTypes.MakerLayers].ref) return

    const ref = this.getConnectionRef(ConnectionTypes.MakerLayers, parentRef)

    layerIds.forEach(layerId => ref.child(layerId).set(null))
  }

  /**
   * Update a firebase `layer` property at `propPath`
   */
  setMakerLayerProperty = (layerId, propPath, value, parentRef = null) => {
    if (!this.connections[ConnectionTypes.MakerLayers].ref) return
    // Firebase properties can be a value (string, number, object, etc.), or null.
    // This guard is here to handle cases where the 'value' passed is 'undefined', which would throw
    // an exception in firebase. e.g. if the user toggles 'Override Values' on a color annotation
    // that wasn't attached to any layer, the 'value' passed would be undefined.
    if (value === undefined) return
    const firebasePath = propPath.replace(/\./g, '/')

    const ref = this.getConnectionRef(ConnectionTypes.MakerLayers, parentRef)

    if (!firebasePath) {
      ref.child(layerId).update({ ...value })
      return
    }
    ref.child(layerId).update({ [firebasePath]: value })
  }

  /**
   * Remove a list of `screen layerIds` from firebase.
   */
  removeScreenLayers = (layerIds, parentRef = null) => {
    const ref = this.getConnectionRef(ConnectionTypes.ScreenLayers, parentRef)
    if (!ref) return
    layerIds.forEach(layerId => ref.child(layerId).set(null))
  }

  /**
   * Update a firebase `screen layer` property at `propPath`
   */
  setScreenLayerProperty = (layerId, propPath, value, parentRef = null) => {
    const ref = this.getConnectionRef(ConnectionTypes.ScreenLayers, parentRef)
    if (!ref) return
    // Firebase properties can be a value (string, number, object, etc.), or null.
    // This guard is here to handle cases where the 'value' passed is 'undefined', which would throw
    // an exception in firebase. e.g. if the user toggles 'Override Values' on a color annotation
    // that wasn't attached to any layer, the 'value' passed would be undefined.
    if (value === undefined) return
    const firebasePath = propPath.replace(/\./g, '/')

    if (!firebasePath) {
      ref.child(layerId).update({ ...value })
      return
    }
    ref.child(layerId).update({ [firebasePath]: value })
  }

  /**
   * Bind any changes made in firebase to redux.
   */
  bindStoreToFirebaseConnection = (store, connectionType) => {
    const { MakerLayers, ScreenLayers } = ConnectionTypes
    switch (connectionType) {
      case MakerLayers:
        this.connections[MakerLayers].ref.on('value', data => {
          const layersVal = data.val()
          if (layersVal == null) return
          const layers = formatFirebaseDictAsArray(layersVal)
          const initializedLayers = layers ? layers.map(layer => initializeLayer(layer)) : {}
          store.dispatch(replaceLayers(formatArrayAsFirebaseDict(initializedLayers, 'id')))
        })
        this.connections[MakerLayers].artboard.ref.on('value', data => {
          const layersVal = data.val()
          if (layersVal == null) return
          const layers = formatFirebaseDictAsArray(layersVal)
          const initializedLayers = layers ? layers.map(layer => initializeLayer(layer)) : {}
          store.dispatch(
            replaceLayers(formatArrayAsFirebaseDict(initializedLayers, 'id'), 'artboard')
          )
        })
        break
      case ScreenLayers:
        this.connections[ScreenLayers].ref.on('value', data => {
          const layersVal = data.val()
          if (layersVal == null) return
          const layers = formatFirebaseDictAsArray(layersVal)
          const initializedLayers = layers ? layers.map(layer => initializeLayer(layer)) : {}
          store.dispatch(replaceLayers(formatArrayAsFirebaseDict(initializedLayers, 'id')))
        })
        this.connections[ScreenLayers].artboard.ref.on('value', data => {
          const layersVal = data.val()
          if (layersVal == null) return
          const layers = formatFirebaseDictAsArray(layersVal)
          const initializedLayers = layers ? layers.map(layer => initializeLayer(layer)) : {}
          store.dispatch(
            replaceLayers(formatArrayAsFirebaseDict(initializedLayers, 'id'), 'artboard')
          )
        })
        break
      default:
        console.error(
          `Invalid \`connectionType\` supplied to \`bindStoreToFirebaseConnection\`: ${connectionType}`
        )
        break
    }
  }

  /**
   * Initialize a connection to firebase.
   * @param {Object} store Reference to the redux store.
   * @param {ConnectionType} connectionType The type of connection to create.
   * @param {Object}
   *    {String} projectId - The project id.
   *    {String} screenId - The screen id.
   */
  connect = (store, connectionType, { projectId, screenId }) => {
    const { MakerLayers, ScreenLayers } = ConnectionTypes
    switch (connectionType) {
      case MakerLayers:
        // Don't re-create connection if it already exists.
        if (this.connections[MakerLayers].ref) return
        // Otherwise create a connection to `layers`.
        this.connections[MakerLayers].ref = MakerProjectsRef.child(`${projectId}/layers`)
        this.connections[MakerLayers].artboard.ref = MakerProjectsRef.child(
          `${projectId}/artboard/layers`
        )

        // Track `projectId` to identify when to disconnect.
        this.connections[MakerLayers].projectId = projectId
        this.bindStoreToFirebaseConnection(store, MakerLayers)
        break
      case ScreenLayers:
        // Don't re-create connection if it already exists.
        if (this.connections[ScreenLayers].ref) return
        // Otherwise create a connection to `layers`.
        this.connections[ScreenLayers].artboard.ref = ProjectsRef.child(
          `${projectId}/screen/${screenId}/artboard/layers`
        )
        this.connections[ScreenLayers].ref = ProjectsRef.child(
          `${projectId}/screen/${screenId}/layers`
        )

        // Track `screenId` to identify when to disconnect.
        this.connections[ScreenLayers].screenId = screenId
        this.bindStoreToFirebaseConnection(store, ScreenLayers)
        break
      default:
        console.error(`Invalid \`connectionType\` supplied to \`connect\`: ${connectionType}`)
    }
  }

  /**
   * Disconnect from firebase and unsubscribe from the redux store.
   */
  disconnect = connectionType => {
    if (!this.connections[connectionType].ref) return
    switch (connectionType) {
      case ConnectionTypes.MakerLayers:
        this.connections[connectionType].projectId = null
        this.connections[connectionType].artboard = {}
        break
      case ConnectionTypes.ScreenLayers:
        this.connections[connectionType].screenId = null
        this.connections[connectionType].artboard = {}
        break
      default:
        console.error(`Invalid \`connectionType\` supplied to \`disconnect\`: ${connectionType}`)
    }
  }
}

export default new FirebaseAPI()
