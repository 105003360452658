/** Fetch Types */
export const FETCH_PROJECTS = 'FETCH_PROJECTS'
export const FETCH_PROJECT = 'FETCH_PROJECT'
export const FETCH_MAKER_PROJECT = 'FETCH_MAKER_PROJECT'

/** User Types */
export const INIT_USER = 'INIT_USER'
export const SCREEN_SET_LAYER_PROPERTY = 'SCREEN_SET_LAYER_PROPERTY'

/** Page Types */
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const FETCH_PROJECT_PAGE = 'FETCH_PROJECT_PAGE'
export const SET_ARTBOARD_FLOW = 'SET_ARTBOARD_FLOW'

/** Canvas Types */
export const SET_CANVAS_PROJECT = 'SET_CANVAS_PROJECT'
export const SET_HOVERED_LAYER = 'SET_HOVERED_LAYER'
export const SET_SELECTED_LAYER = 'SET_SELECTED_LAYER'
export const CREATE_LAYER = 'CREATE_LAYER'
export const SET_LAYER_PROPERTY = 'SET_LAYER_PROPERTY'
export const REPLACE_LAYERS = 'REPLACE_LAYERS'
export const REMOVE_LAYER = 'REMOVE_LAYER'
export const SET_DEFAULT_LAYER = 'SET_DEFAULT_LAYER'

/** Tool States */
export const SET_ORIGIN_MOUSE_POSITION = 'SET_ORIGIN_MOUSE_POSITION'
export const SET_LINE_TOOL_START_ATTACHMENT = 'SET_LINE_TOOL_START_ATTACHMENT'

/** Tool Types */
export const SET_ACTIVE_TOOL = 'SET_ACTIVE_TOOL'
export const SET_TOOL_STEP = 'SET_TOOL_STEP'
export const RESET_TOOLS_STATE = 'RESET_TOOLS_STATE'
