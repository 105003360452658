const SizeHandler = 6
const HandlerOffsetPos = -SizeHandler / 2

const Handler = {
  position: 'absolute',
  width: SizeHandler,
  height: SizeHandler,
  backgroundColor: '#fff',
  border: '1px solid #979797',
  zIndex: 10,
}

const VerticalHandler = {
  ...Handler,
  left: `calc(50% + ${HandlerOffsetPos}px)`,
  cursor: 'ns-resize',
}

const HorizontalHandler = {
  ...Handler,
  top: `calc(50% + ${HandlerOffsetPos}px)`,
  cursor: 'ew-resize',
}

const jssStyles = theme => ({
  container: {
    position: 'static',
    width: '100%',
    height: '100%',
  },
  handlerContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  verticalHandlerTop: {
    top: HandlerOffsetPos,
    ...VerticalHandler,
  },
  horizontalHandlerLeft: {
    left: HandlerOffsetPos,
    ...HorizontalHandler,
  },
  horizontalHandlerRight: {
    right: HandlerOffsetPos,
    ...HorizontalHandler,
  },
  verticalHandlerBottom: {
    bottom: HandlerOffsetPos,
    ...VerticalHandler,
  },
})

export default jssStyles
