export default {
  api: {
    host: 'https://torii-prod-tt.appspot.com'
  },
  firebase: {
    apiKey: 'AIzaSyBYxKs54NcexxPegDbB4VibqVVxZMzbmeU',
    authDomain: 'torii-prod-tt.firebaseapp.com',
    databaseURL: 'https://torii-prod-tt.firebaseio.com',
    projectId: 'torii-prod-tt',
    storageBucket: 'torii-prod-tt.appspot.com',
    messagingSenderId: '189330972182'
  },
  analytics: {
    id: 'UA-138959026-1',
    options: { titleCase: false }
  },
  localStorage: {
    reduxStateKey: 'toriiReduxState'
  }
}
