/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

import ComponentIdentifier from 'docs/components/ComponentIdentifier'

import styles from 'docs/DocsPageStyles'

const DocsTypography = withStyles(theme => ({
  root: {
    color: theme.palette.black65,
    fontFamily: 'Open Sans',
  },
}))(Typography)

class DocsTabs extends Component {
  render() {
    const { classes } = this.props

    return (
      <a name="tabs" className={classes.subSectionLink}>
        <a href="#tabs">
          <DocsTypography variant="h6" className={classes.contentSubTitle}>
            Tabs
          </DocsTypography>
        </a>
        <div className={classes.subSectionContent}>
          <p>Coming soon...</p>
        </div>
      </a>
    )
  }
}

DocsTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  routeParams: PropTypes.object,
}

export default withStyles(styles)(DocsTabs)
