import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'

import styles from './ButtonContainedStyles'

class ButtonContained extends Component {
  render() {
    const {
      classes,
      layer: {
        props: { text, backgroundColor, textColor: color, fontSize, borderRadius },
        frame: { width, height, y: top, x: left },
        zIndex,
      },
    } = this.props

    const containerStyles = {
      top,
      left,
      width,
      height,
      zIndex,
    }

    const buttonStyles = {
      color,
      fontSize,
      borderRadius,
      backgroundColor,
      ...containerStyles,
    }

    return (
      <Button className={classes.container} variant="contained" style={buttonStyles}>
        {text}
      </Button>
    )
  }
}

ButtonContained.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  artboardEvents: PropTypes.object,
}

export default withStyles(styles)(ButtonContained)
