const jssStyles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formContainer: {
    boxShadow: '0 1px 2px rgba(0,0,0,0.15)',
    padding: 32,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  action: {
    marginTop: 20,
  },

  '@media (max-width: 600px)': {
    formContainer: {
      boxShadow: 'none',
    },
  },
})

export default jssStyles
