import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { getComponentFromLayer } from '@components'

import styles from './ComponentLayerStyles'

class ComponentLayer extends React.Component {
  render() {
    const { classes, layer, ...props } = this.props

    return getComponentFromLayer(layer, props)
  }
}

ComponentLayer.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  artboardFrame: PropTypes.object,
  isHovered: PropTypes.bool,
  isSelected: PropTypes.bool,
  onMouseDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onChange: PropTypes.func,
  storageBucketId: PropTypes.string,
  artboardEvents: PropTypes.object,
  onArtboardFlowChange: PropTypes.func,
}

export default withStyles(styles)(ComponentLayer)
