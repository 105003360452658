import isFunction from 'lodash/isFunction'

export const stopPropagation = e => e && isFunction(e.stopPropagation) && e.stopPropagation()
export const preventDefault = e => e && isFunction(e.preventDefault) && e.preventDefault()

export const withStopPropagation = func => {
  return e => {
    stopPropagation(e)
    if (isFunction(func)) {
      func(e)
    }
  }
}

export const withPreventDefault = func => {
  return e => {
    preventDefault(e)
    if (isFunction(func)) {
      func(e)
    }
  }
}

export const loadScript = (src, idName) => {
  const tag = document.createElement('script')
  if (idName) tag.id = idName
  tag.async = false
  tag.src = src
  document.body.appendChild(tag)
}
