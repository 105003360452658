import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import styles from './NoteStyles'

class Note extends Component {
  render() {
    const { classes, title, description } = this.props

    return (
      <div className={classes.container}>
        <div className={classes.title}>{title || 'Note'}</div>
        <div className={classes.flex} />
        <div className={classes.description}>{description}</div>
      </div>
    )
  }
}

Note.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default withStyles(styles)(Note)
