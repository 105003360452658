import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import MaterialCheckbox from '@material-ui/core/Checkbox'

import styles from './CheckboxStyles'

class Checkbox extends Component {
  state = {
    checked: false,
  }

  componentWillMount() {
    const {
      layer: { defaults },
    } = this.props

    const checkedProp = defaults.find(d => d.name === 'checked')

    checkedProp && this.setState({ checked: checkedProp.value })
  }

  handleChange = () => {
    const { checked } = this.state
    this.setState({ checked: !checked })
  }

  render() {
    const {
      classes,
      layer: {
        props: { backgroundColor },
        frame: { width, height, y: top, x: left },
        zIndex,
      },
    } = this.props

    const { checked } = this.state

    const containerStyles = {
      top,
      left,
      width,
      height,
      zIndex,
    }

    const CustomCheckbox = withStyles({
      root: {
        color: backgroundColor,
      },
      checked: {
        color: backgroundColor,
      },
    })(props => <MaterialCheckbox color="default" {...props} />)

    return (
      <CustomCheckbox
        onChange={this.handleChange}
        checked={checked}
        className={classes.container}
        style={containerStyles}
      />
    )
  }
}

Checkbox.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  artboardEvents: PropTypes.object,
}

export default withStyles(styles)(Checkbox)
