const jssStyles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    // TODO: MOVE NEXT LINE  OUT
    overflow: 'hidden',
  },
})

export default jssStyles
