export default {
  api: {
    host: 'https://torii-dev-tt.appspot.com'
  },
  firebase: {
    apiKey: 'AIzaSyB6Zr419hrW9x6g8Gli8Sk-zmWZdIPUO4g',
    authDomain: 'torii-dev-tt.firebaseapp.com',
    databaseURL: 'https://torii-dev-tt.firebaseio.com',
    projectId: 'torii-dev-tt',
    storageBucket: 'torii-dev-tt.appspot.com',
    messagingSenderId: '673632867878'
  },
  analytics: {
    id: 'UA-138992764-1',
    options: { titleCase: false }
  },
  localStorage: {
    reduxStateKey: 'DEV_toriiReduxState'
  }
}
