import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'

import styles from './ButtonOutlinedStyles'

class ButtonOutlined extends Component {
  render() {
    const {
      classes,
      layer: {
        props,
        props: { text, backgroundColor, textColor: color, fontSize, borderRadius },
        frame: { width, height, y: top, x: left },
        zIndex,
      },
    } = this.props

    const containerStyles = {
      top,
      left,
      width,
      height,
      zIndex,
    }

    const buttonStyles = {
      color,
      fontSize,
      borderRadius,
      backgroundColor,
      ...containerStyles,
    }

    const CustomButton = withStyles(theme => ({
      root: {
        color,
        backgroundColor,
      },
    }))(Button)

    return (
      <CustomButton className={classes.container} variant="outlined" style={buttonStyles}>
        {text}
      </CustomButton>
    )
  }
}

ButtonOutlined.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  artboardEvents: PropTypes.object,
}

export default withStyles(styles)(ButtonOutlined)
