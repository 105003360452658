import React, { Component } from 'react'

import { parseLayerName } from 'utils'

import Void from './Void'
import Button from './Button'
import Input from './Input'
import Checkbox from './Checkbox'
import Slider from './Slider'
import Scroller from './Scroller'
import AppBar from './AppBar'
import HorizontalSlider from './HorizontalSlider'
import Slideshow from './Slideshow'
import Tabs from './Tabs'
import RadioGroup from './RadioGroup'

const ComponentNames = {
  void: Void,
  item: Void,
  button: Button,
  input: Input,
  checkbox: Checkbox,
  slider: Slider,
  scroller: Scroller,
  'app-bar': AppBar,
  // 'sticky-header--default': StickyHeader,
  // 'sticky-header--change': StickyHeader,
  'horizontal-slider': HorizontalSlider,
  slideshow: Slideshow,
  tabs: Tabs,
  'tabs-headers': Tabs,
  radiogroup: RadioGroup,
}

export function getComponentFromLayer(layer, props) {
  const { name, componentName, modifierName } = parseLayerName(layer.name)

  let ComponentName = ComponentNames[componentName] || ComponentNames[name]

  if (modifierName && !componentName) {
    ComponentName = ComponentNames.void
  }

  if (!ComponentName) return null

  return <ComponentName layer={layer} {...props} />
}
