export const AwaitingAttachmentSelection = 'AwaitingAttachmentSelection'
export const AwaitingFirstAttachmentSelection = 'AwaitingFirstAttachmentSelection'
export const AwaitingSecondAttachmentSelection = 'AwaitingSecondAttachmentSelection'

// Tool Type Steps

export const TextToolSteps = [
  {
    num: 0,
    name: AwaitingAttachmentSelection,
  },
]

export const LineToolSteps = [
  {
    num: 0,
    name: AwaitingFirstAttachmentSelection,
  },
  {
    num: 1,
    name: AwaitingSecondAttachmentSelection,
  },
]
