/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import WarningIcon from '@material-ui/icons/Warning'

import styles from './CustomSnackbarStyles'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
}

const MySnackbarContentWrapper = ({ classes, className, message, onClose, variant, ...otherProps }) => {
  const Icon = variantIcon[variant]

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...otherProps}
    />
  )
}

MySnackbarContentWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired
}

const CustomSnackbar = ({ classes, open, className, message, onClose, variant }) => {
  const [inOpen, setInOpen] = useState(open)

  useEffect(() => {
    setInOpen(open)
  }, [open])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    console.log('yeah')

    setInOpen(false)
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      open={inOpen}
      autoHideDuration={6000}
    >
      <MySnackbarContentWrapper classes={classes} variant={variant || 'success'} message={message} onClose={handleClose} />
    </Snackbar>
  )
}

CustomSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  open: PropTypes.bool,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired
}

export default withStyles(styles)(CustomSnackbar)
