const TabHeaderHeightMinPx = 52

const jssStyles = theme => ({
  container: {
    flexGrow: 1,
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  headerContainer: {
    display: 'flex',
    zIndex: theme.zIndex.GUI,
    top: 0,
    left: 0,
    width: '100%',
    transform: 'translateZ(0)',
  },
  tabsHeader: {
    minHeight: TabHeaderHeightMinPx,
    width: '100%',
  },
  flexContainer: {
    height: '100%',
    '& > span': {
      backgroundColor: 'white',
    },
  },
  content: {
    position: 'relative',
    flex: 1,
  },
  tabHeaderValue: {
    fontSize: 16,
    textTransform: 'capitalize',
    fontWeight: 400,
    height: '100%',
    minHeight: TabHeaderHeightMinPx,
    flex: 1,
  },
})

export default jssStyles
