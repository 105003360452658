import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'

import { findToriiPropByName } from 'utils'
import styles from './ToriiCardStyles'

const Image = {
  prop: '__image__',
  value: '',
}

const Title = {
  prop: '__title__',
  value: {
    color: '#000',
    text: 'Title',
  },
}

const Description = {
  prop: '__description__',
  value: {
    color: '#000',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  },
}

class ToriiCard extends Component {
  render() {
    const { classes, props } = this.props

    const { value: imageValue } = findToriiPropByName(props, Image.prop) || Image
    const { value: titleValue } = findToriiPropByName(props, Title.prop) || Title
    const { value: descriptionValue } = findToriiPropByName(props, Description.prop) || Description

    const titleStyle = {
      color: titleValue.color,
    }

    const descriptionStyle = {
      color: descriptionValue.color,
    }

    return (
      <Card className={classes.container}>
        <div className={classes.imageContainer}>
          <img className={classes.image} src={`data:image/png;base64,${imageValue}`} alt="" />
        </div>
        <div className={classes.textContainer}>
          <Typography variant="body1" className={classes.title} style={titleStyle}>
            {titleValue.text}
          </Typography>
          <Typography variant="body2" className={classes.description} style={descriptionStyle}>
            {descriptionValue.text}
          </Typography>
        </div>
      </Card>
    )
  }
}

ToriiCard.propTypes = {
  classes: PropTypes.object.isRequired,
  props: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default withStyles(styles)(ToriiCard)
