import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { stopPropagation, loadFontsFromLayers } from 'utils'

import Layer from 'components/Layer'

import styles from './ArtboardCanvasStyles'

const Ref = 'artboard'

class ArtboardCanvas extends Component {
  componentWillMount() {
    const {
      artboard: { layers },
    } = this.props
    loadFontsFromLayers(layers)
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeydown)
    window.addEventListener('keyup', this.handleKeyup)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeydown)
    window.removeEventListener('keyup', this.handleKeyup)
  }

  outerContainerRef = React.createRef()

  artboardRef = React.createRef()

  handleMouseDown = e => {
    const { onMouseDown } = this.props
    onMouseDown && onMouseDown(e, this.artboardRef.current)
  }

  handleKeydown = e => {
    const { onKeydown } = this.props
    onKeydown && onKeydown(e, Ref)
  }

  handleKeyup = e => {
    const { onKeyup } = this.props
    onKeyup && onKeyup(e, Ref)
  }

  handleLayerMouseDown = (e, layerId) => {
    stopPropagation(e)
    const { onLayerMouseDown } = this.props
    onLayerMouseDown && onLayerMouseDown(e, layerId)
  }

  handleLayerMouseEnter = (e, layerId) => {
    stopPropagation(e)
    const { onLayerMouseEnter } = this.props
    onLayerMouseEnter && onLayerMouseEnter(e, layerId)
  }

  handleLayerMouseLeave = (e, layerId) => {
    stopPropagation(e)
    const { onLayerMouseLeave } = this.props
    onLayerMouseLeave && onLayerMouseLeave(e, layerId)
  }

  handleLayerChange = (e, layerId, propPath, value) => {
    stopPropagation(e)
    const { onLayerChange } = this.props
    onLayerChange && onLayerChange(e, layerId, propPath, value, Ref)
  }

  render() {
    const {
      classes,
      artboard: {
        frame: { width, height },
        layers,
      },
      storageBucketId,
      hoveredLayerId,
      selectedLayerId,
      className,
      etsyPage,
    } = this.props

    const frame = {
      width,
      height,
    }

    const outerContainerClassNames = classNames({
      [classes.outerContainer]: !etsyPage,
    })

    const containerClassNames = classNames(
      {
        [classes.container]: true,
        [classes.etsyContainer]: etsyPage,
      },
      className
    )

    return (
      <div className={outerContainerClassNames}>
        <div
          className={containerClassNames}
          onMouseDown={this.handleMouseDown}
          style={frame}
          ref={this.artboardRef}
        >
          {layers &&
            Object.keys(layers).map(layerId => {
              const layer = layers[layerId]
              return (
                <Layer
                  layer={layer}
                  artboardFrame={frame}
                  isHovered={layerId === hoveredLayerId}
                  isSelected={layerId === selectedLayerId}
                  storageBucketId={storageBucketId}
                  onMouseDown={this.handleLayerMouseDown}
                  onMouseEnter={this.handleLayerMouseEnter}
                  onMouseLeave={this.handleLayerMouseLeave}
                  onLayerChange={this.handleLayerChange}
                  key={layerId}
                />
              )
            })}
        </div>
      </div>
    )
  }
}

ArtboardCanvas.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  artboard: PropTypes.object.isRequired,
  storageBucketId: PropTypes.string,
  hoveredLayerId: PropTypes.string,
  selectedLayerId: PropTypes.string,
  onMouseDown: PropTypes.func,
  onLayerMouseDown: PropTypes.func,
  onLayerMouseEnter: PropTypes.func,
  onLayerMouseLeave: PropTypes.func,
  onLayerChange: PropTypes.func,
  onKeydown: PropTypes.func,
  onKeyup: PropTypes.func,
  etsyPage: PropTypes.bool,
}

export default withStyles(styles)(ArtboardCanvas)
