const jssStyles = theme => ({
  scrollContainer: {
    position: 'absolute',
    overflowX: 'auto',
    left: 0,
    width: '100%',
  },
  container: {
    position: 'absolute',
  },
})

export default jssStyles
