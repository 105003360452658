import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import ReactProgressiveImage from 'react-progressive-image'

import { getFirebaseUrl } from 'utils'

const DefaultScales = ['0.1', '1', '2']
const ImageFormat = 'png'

const IntervalTestImageLoadedMs = 5000

class ProgressiveImage extends Component {
  state = {
    imagesReady: [false, false, false],
  }

  componentDidMount() {
    const { layer: layerImage } = this.props
    const scales = layerImage.scales ? layerImage.scales : DefaultScales

    scales.forEach((scale, scaleIdx) => {
      this.loadImage(scale, scaleIdx)
    })
  }

  componentDidUpdate() {
    this.loaded = true
  }

  loaded = false

  getImageByScale = scale => {
    const { layer: layerImage, storageBucketId } = this.props
    return getFirebaseUrl(`project/${storageBucketId}/${layerImage.id}_${scale}.${ImageFormat}`)
  }

  loadImage(scale, scaleIdx) {
    const { imagesReady } = this.state
    const img = new Image()
    img.src = this.getImageByScale(scale)

    img.onload = () => {
      imagesReady[scaleIdx] = scale

      this.setState({
        imagesReady,
      })
    }

    img.onerror = () => {
      setTimeout(() => {
        this.loadImage(scale)
      }, IntervalTestImageLoadedMs)
    }
  }

  renderImageWithScale(scale) {
    const { classes, layer: layerImage, draggable } = this.props

    const rootStyles = {
      width: '100%',
      height: 'auto',
    }

    return (
      <img
        className={classNames(classes.layer)}
        src={this.getImageByScale(scale)}
        alt={layerImage.name}
        draggable={draggable}
        style={rootStyles}
      />
    )
  }

  render() {
    const { imagesReady } = this.state

    let nextImageIdxToRender = imagesReady.findIndex(imageIndex => imageIndex === false)

    // If all images are ready, next image to render is the latest one.
    nextImageIdxToRender =
      nextImageIdxToRender === -1 ? imagesReady.length - 1 : nextImageIdxToRender

    if (nextImageIdxToRender <= 0) {
      return <div />
    }

    const scaleA = imagesReady[nextImageIdxToRender - 1]
    const scaleB = imagesReady[nextImageIdxToRender]

    if (!scaleB) {
      return this.renderImageWithScale(scaleA)
    }

    return (
      <ReactProgressiveImage
        placeholder={this.getImageByScale(scaleA)}
        src={this.getImageByScale(scaleB)}
      >
        {(src, loading) => {
          return loading ? this.renderImageWithScale(scaleA) : this.renderImageWithScale(scaleB)
        }}
      </ReactProgressiveImage>
    )
  }
}

ProgressiveImage.propTypes = {
  classes: PropTypes.object,
  storageBucketId: PropTypes.string,
  style: PropTypes.object,
  layer: PropTypes.object.isRequired,
  draggable: PropTypes.bool,
}

export default ProgressiveImage
