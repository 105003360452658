export const CurrentVersion = 'Beta'

export const Sections = [
  {
    name: 'Introduction',
    id: 'introduction',
    paginated: true,
    items: [
      {
        name: 'Why Torii?',
        id: 'why-torii',
        componentName: 'WhyTorii',
      },
      {
        name: 'Getting started',
        id: 'getting-started',
        componentName: 'GettingStarted',
      },
      {
        name: 'Tutorials',
        id: 'tutorials',
        componentName: 'Tutorials',
      },
    ],
  },
  {
    name: 'Components',
    id: 'components',
    paginated: false,
    items: [
      {
        name: 'Introduction',
        id: 'introduction',
      },
      {
        name: 'States',
        id: 'states',
      },
      {
        name: 'Buttons',
        id: 'buttons',
      },
      {
        name: 'Inputs',
        id: 'inputs',
      },
      {
        name: 'Checkboxes',
        id: 'checkboxes',
      },
      {
        name: 'Sliders',
        id: 'sliders',
      },
      {
        name: 'Scrollers',
        id: 'scrollers',
      },
      {
        name: 'Tabs',
        id: 'tabs',
      },
    ],
  },
  // {
  //   name: 'Examples',
  //   id: 'examples',
  // },
]
