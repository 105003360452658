import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import styles from './DropzoneStyles'

class Dropzone extends Component {
  state = {
    hightlight: false,
  }

  fileInputRef = React.createRef()

  fileListToArray = list => {
    const { accept } = this.props

    const acceptedFiles = accept ? accept.split(',').map(i => i.trim()) : []

    const array = []
    for (let i = 0; i < list.length; i += 1) {
      const item = list.item(i)
      if (acceptedFiles.length === 0 || acceptedFiles.indexOf(item.type) !== -1) {
        array.push(item)
      }
    }
    return array
  }

  openFileDialog = () => {
    const { disabled } = this.props
    if (disabled) return
    this.fileInputRef.current.click()
  }

  onFilesAdded = e => {
    const { onFilesAdded, disabled } = this.props
    if (disabled) return
    const { files } = e.target
    if (onFilesAdded) {
      const array = this.fileListToArray(files)
      array.length && onFilesAdded(array)
    }
  }

  onDragOver = e => {
    const { disabled } = this.props
    e.preventDefault()
    if (disabled) return
    this.setState({ hightlight: true })
  }

  onDragLeave = () => {
    this.setState({ hightlight: false })
  }

  onDrop = e => {
    const { onFilesAdded, disabled } = this.props
    e.preventDefault()

    if (disabled) return

    const { files } = e.dataTransfer
    if (onFilesAdded) {
      const array = this.fileListToArray(files)
      array.length && onFilesAdded(array)
    }
    this.setState({ hightlight: false })
  }

  render() {
    const { classes, accept, disabled, children } = this.props
    const { hightlight } = this.state

    const containerClassNames = classNames({
      [classes.container]: true,
      [classes.highlight]: hightlight,
    })

    return (
      <div
        className={containerClassNames}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{ cursor: disabled ? 'default' : 'pointer' }}
      >
        <input
          ref={this.fileInputRef}
          className={classes.input}
          type="file"
          multiple
          onChange={this.onFilesAdded}
        />
        {children}
      </div>
    )
  }
}

Dropzone.propTypes = {
  classes: PropTypes.object.isRequired,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  onFilesAdded: PropTypes.func,
  children: PropTypes.node,
}

export default withStyles(styles)(Dropzone)
