import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import styles from './PageItemStyles'

class PageItem extends Component {
  render() {
    const { classes, className, page } = this.props

    const containerClassNames = classNames(
      {
        [classes.container]: true,
      },
      className
    )

    return (
      <div className={containerClassNames}>
        <div className={classes.imageContainer} />
        <div className={classes.footerContainer}>
          <Typography className={classes.title}>{page.title}</Typography>
          <IconButton onClick={this.handleMenuIconClick}>
            <MoreVertIcon />
          </IconButton>
        </div>
      </div>
    )
  }
}

PageItem.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  page: PropTypes.object.isRequired,
}

export default withStyles(styles)(PageItem)
