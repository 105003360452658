import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { processLayerPosition } from 'utils'

import Layer from 'components/Layer'

import styles from './InputStyles'

const getInputType = string => {
  const formatString = string.toLowerCase().trim()

  if (formatString.includes('password')) {
    return 'password'
  }
  if (formatString.includes('email')) {
    return 'email'
  }

  return 'text'
}

class Input extends Component {
  inputRef = React.createRef()

  handleClick = () => {
    this.inputRef.current.focus()
  }

  render() {
    const {
      classes,
      layer,
      layer: {
        frame,
        frame: { width, height },
        layers,
      },
      artboardEvents,
      storageBucketId,
      scaleRatio,
      editMode,
    } = this.props

    const position = processLayerPosition(layer, scaleRatio)

    const containerStyles = {
      ...position,
      width: width * scaleRatio,
      height: height * scaleRatio,
    }

    return (
      <div className={classes.container} style={containerStyles} onClick={this.handleClick}>
        {layer.layers &&
          Object.keys(layers).map(layerId => {
            const componentLayer = layers[layerId]

            if (componentLayer.type === 'text') {
              const {
                frame: { y: topInput, x: leftInput, height: heightInput },
                style,
                zIndex,
              } = componentLayer

              const inputStyles = {
                ...style,
                fontSize: style.fontSize * scaleRatio,
                top: topInput * scaleRatio,
                left: leftInput * scaleRatio,
                width: `calc(100% - ${2 * leftInput * scaleRatio}px)`,
                height: heightInput * scaleRatio,
                zIndex,
              }

              return (
                <input
                  className={classes.input}
                  type={getInputType(componentLayer.value)}
                  key={layerId}
                  placeholder={componentLayer.value}
                  style={inputStyles}
                  spellCheck="false"
                  ref={this.inputRef}
                />
              )
            }

            return (
              <Layer
                layer={componentLayer}
                artboardFrame={frame}
                key={layerId}
                artboardEvents={artboardEvents}
                storageBucketId={storageBucketId}
                scaleRatio={scaleRatio}
                editMode={editMode}
              />
            )
          })}
      </div>
    )
  }
}

Input.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  artboardEvents: PropTypes.object,
  storageBucketId: PropTypes.string,
  scaleRatio: PropTypes.number,
  editMode: PropTypes.bool,
}

export default withStyles(styles)(Input)
