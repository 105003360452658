/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

import Note from 'docs/components/Note'

import styles from 'docs/DocsPageStyles'

const DocsTypography = withStyles(theme => ({
  root: {
    color: theme.palette.black65,
    fontFamily: 'Open Sans',
  },
}))(Typography)

const Styles = {
  list: {
    paddingBottom: 32,
  },
  itemList: {
    padding: 0,
  },
}

class GettingStarted extends Component {
  render() {
    const { classes } = this.props

    return (
      <>
        <DocsTypography variant="h6" className={classes.contentSubTitle}>
          Getting started
        </DocsTypography>
        <div>
          <p>Install the Torii Plugin by:</p>
          <div style={Styles.list}>
            <p style={Styles.itemList}>
              1. Install the latest version of{' '}
              <a href="https://www.sketch.com/updates/" target="_blank" rel="noopener noreferrer">
                Sketch
              </a>
            </p>
            <p style={Styles.itemList}>
              2. Download{' '}
              <a
                href={`/downloads/torii.sketchplugin.zip?id=${shortid.generate()}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Torii.sketchplugin
              </a>
            </p>
            <p style={Styles.itemList}>
              3. Unzip file and double click the unzipped file to install plugin
            </p>
            <p style={Styles.itemList}>4. Restart Sketch after installing plugin</p>
          </div>
          <div className={classes.imageContainer}>
            <img src="/images/docs/plugin-installed.png" alt="Plugin installed" />
          </div>
        </div>
        <Note
          title="Note"
          description={(
            <div>
              Make sure that you are always using the latest versions of Sketch and Torii to avoid
              any issues.
            </div>
)}
        />
      </>
    )
  }
}

GettingStarted.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(GettingStarted)
