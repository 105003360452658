import toolConfigList from 'constants/toolTypes'
import * as modifierKeyTypes from 'constants/modifierKeyTypes'

const toolShortcuts = toolConfigList.reduce((acc, toolConfig) => {
  const toolShortcutObj = {
    shortcutId: toolConfig.shortcutId,
    toolEnabled: toolConfig.enabled,
    displayString: toolConfig.shortcutDesc,
    displayKey: toolConfig.shortcutKey && toolConfig.shortcutKey.toUpperCase(),
    key: toolConfig.shortcutKey,
  }

  return {
    ...acc,
    [toolConfig.name]: toolShortcutObj,
  }
}, {})

/**
 * Grouping of keyboard shortcuts relevant to the Canvas component.
 */
export const canvasShortcuts = {
  moveLayerUp: {
    shortcutId: 'moveLayerUp',
    displayString: 'Move layer up',
    displayKey: 'ARROW UP',
    key: 'ArrowUp',
    repeatEnabled: true,
  },
  moveLayerRight: {
    shortcutId: 'moveLayerRight',
    displayString: 'Move layer right',
    displayKey: 'ARROW RIGHT',
    key: 'ArrowRight',
    repeatEnabled: true,
  },
  moveLayerDown: {
    shortcutId: 'moveLayerDown',
    displayString: 'Move layer down',
    displayKey: 'ARROW DOWN',
    key: 'ArrowDown',
    repeatEnabled: true,
  },
  moveLayerLeft: {
    shortcutId: 'moveLayerLeft',
    displayString: 'Move layer left',
    displayKey: 'ARROW LEFT',
    key: 'ArrowLeft',
    repeatEnabled: true,
  },
  moveLayerUpPlus: {
    shortcutId: 'moveLayerUpPlus',
    displayString: 'Move layer up plus',
    displayKey: 'ARROW UP PLUS',
    key: 'ArrowUp',
    repeatEnabled: true,
    modifierKeys: [modifierKeyTypes.SHIFT],
  },
  moveLayerRightPlus: {
    shortcutId: 'moveLayerRightPlus',
    displayString: 'Move layer right plus',
    displayKey: 'ARROW RIGHT PLUS',
    key: 'ArrowRight',
    repeatEnabled: true,
    modifierKeys: [modifierKeyTypes.SHIFT],
  },
  moveLayerDownPlus: {
    shortcutId: 'moveLayerDownPlus',
    displayString: 'Move layer down plus',
    displayKey: 'ARROW DOWN PLUS',
    key: 'ArrowDown',
    repeatEnabled: true,
    modifierKeys: [modifierKeyTypes.SHIFT],
  },
  moveLayerLeftPlus: {
    shortcutId: 'moveLayerLeftPlus',
    displayString: 'Move layer left plus',
    displayKey: 'ARROW LEFT',
    key: 'ArrowLeft',
    repeatEnabled: true,
    modifierKeys: [modifierKeyTypes.SHIFT],
  },
  deselectLayer: {
    shortcutId: 'deselectLayer',
    displayString: 'Deselect layer',
    displayKey: 'ESCAPE',
    key: 'Escape',
  },
}

/**
 * Grouping of keyboard shortcuts relevant to the ToolBar component.
 */
export const toolbarShortcuts = {
  ...toolShortcuts,
  deselectTool: {
    shortcutId: 'deselectTool',
    displayString: 'Deselect tool',
    displayKey: 'ESCAPE',
    key: 'Escape',
  },
  removeLayer: {
    shortcutId: 'removeLayer',
    displayString: 'Remove layer',
    displayKey: 'DELETE',
    keys: ['Backspace', 'Delete'],
  },
  addHyperlink: {
    shortcutId: 'addHyperlink',
    displayString: 'Add Hyperlink',
    displayKey: 'CMD + K',
    modifierKeys: [modifierKeyTypes.COMMAND],
    key: 'k',
  },
}

export const canvasKeyboardShortcuts = {
  ...canvasShortcuts,
  ...toolbarShortcuts,
}

/**
 * Grouping of all available keyboard shortcuts.
 */
export const allKeyboardShortcuts = {
  ...canvasKeyboardShortcuts,
}
