/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import QRCode from 'qrcode.react'
import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import SmartphoneIcon from '@material-ui/icons/Smartphone'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

import { isMobileDevice } from 'utils'
import * as pageTypes from 'constants/pageTypes'

import ArtboardFlowContainer from 'components/ArtboardFlowContainer'

import { setCurrentPage, fetchProjectPage } from 'actions/pageActions'
import { setArtboardFlow } from 'actions/artboardFlowActions'
import styles from './LivePageStyles'

class LivePage extends Component {
  state = {
    loading: true,
    dialogActive: false,
    isMobile: isMobileDevice(),
  }

  componentWillMount() {
    const {
      actions,
      routeParams: { projectId },
    } = this.props

    actions.setCurrentPage(pageTypes.LivePage)
    actions.fetchProjectPage(projectId)
  }

  componentDidUpdate({ page: prevPage }, prevState) {
    const { page } = this.props

    const prevArtboard = _get(prevPage, 'project.artboard')
    const artboard = _get(page, 'project.artboard')

    if (!isEqual(prevArtboard, artboard)) {
      this.setState({ loading: false })
    }
  }

  handleWindowResize = () => {
    this.setState({
      isMobile: isMobileDevice(),
    })
  }

  goToEdit = () => {
    const {
      routeParams: { projectId },
      history,
    } = this.props
    history.push(`/maker/${projectId}`)
    window.location.reload()
  }

  handleDialogOpen = () => {
    this.setState({ dialogActive: true })
  }

  handleDialogClose = () => {
    this.setState({ dialogActive: false })
  }

  handleArtboardFlowChange = newFlow => {
    const { actions } = this.props
    actions.setArtboardFlow(newFlow)
  }

  render() {
    const {
      classes,
      page: {
        project: { artboard },
      },
      routeParams: { projectId },
      artboardFlow,
    } = this.props

    const { dialogActive, loading, isMobile } = this.state

    return (
      <div className={classes.page}>
        {loading && (
          <div className={classes.loading}>
            <CircularProgress color="secondary" />
          </div>
        )}
        {!loading && (
          <ArtboardFlowContainer
            rootArtboardId={projectId}
            artboard={artboard}
            artboardFlow={artboardFlow}
            onArtboardFlowChange={this.handleArtboardFlowChange}
            editMode={false}
            onWindowResize={this.handleWindowResize}
          />
        )}
        {!isMobile && (
          <React.Fragment>
            <div className={classes.actions}>
              <IconButton edge="start" color="secondary" onClick={this.handleDialogOpen}>
                <SmartphoneIcon />
              </IconButton>
            </div>
            <Dialog className={classes.dialog} open={dialogActive} onClose={this.handleDialogClose}>
              <DialogContent>
                <QRCode value={`${window.location.origin}/live/${projectId}`} />
              </DialogContent>
            </Dialog>
          </React.Fragment>
        )}
      </div>
    )
  }
}

LivePage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    setCurrentPage: PropTypes.func.isRequired,
    fetchProjectPage: PropTypes.func.isRequired,
    setArtboardFlow: PropTypes.func.isRequired,
  }),
  routeParams: PropTypes.object,
  page: PropTypes.object.isRequired,
  artboardFlow: PropTypes.object.isRequired,
  history: PropTypes.object,
}

const mapStateToProps = (state, ownProps) => {
  const { page, artboardFlow } = state
  return {
    routeParams: ownProps.match.params,
    page,
    artboardFlow,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        setCurrentPage,
        fetchProjectPage,
        setArtboardFlow,
      },
      dispatch
    ),
  }
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(LivePage)
