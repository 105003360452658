const jssStyles = theme => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.layerElevated,
    transform: 'translateZ(0)',
  },
  hiddenContainer: {
    opacity: 0,
    visibility: 'hidden',
    zIndex: -1,
  },
})

export default jssStyles
