/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

import ComponentIdentifier from 'docs/components/ComponentIdentifier'

import styles from 'docs/DocsPageStyles'

const DocsTypography = withStyles(theme => ({
  root: {
    color: theme.palette.black65,
    fontFamily: 'Open Sans',
  },
}))(Typography)

class DocsButtons extends Component {
  render() {
    const { classes } = this.props

    return (
      <a name="buttons" className={classes.subSectionLink}>
        <a href="#buttons">
          <DocsTypography variant="h6" className={classes.contentSubTitle}>
            Buttons
          </DocsTypography>
        </a>
        <div className={classes.subSectionContent}>
          <ComponentIdentifier name="button" />
          <p>
            A button is usually represented by a group of layers that contains a <span>text</span>{' '}
            layer and a <span>shape</span> layer. It usually looks like this:
          </p>
          <div className={classes.imageContainer}>
            <img src="/images/docs/layers-button.png" alt="Button layers" />
          </div>
          <p>Some samples you can easily create:</p>
          <div className={classes.imageContainer}>
            <img src="/images/docs/sample-buttons.png" alt="Sample buttons" />
          </div>
        </div>
      </a>
    )
  }
}

DocsButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  routeParams: PropTypes.object,
}

export default withStyles(styles)(DocsButtons)
