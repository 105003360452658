import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { DraggableCore } from 'react-draggable'
import { withStyles } from '@material-ui/core/styles'

import { stopPropagation } from '../../../utils'

import styles from './ResizeWrapperStyles'

const Handlers = {
  HandlerTop: 'HANDLER_TOP',
  HandlerLeft: 'HANDLER_LEFT',
  HandlerRight: 'HANDLER_RIGHT',
  HandlerBottom: 'HANDLER_BOTTOM',
}

const InitialDimensions = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
}

class ResizeWrapper extends React.Component {
  handleChange = (e, dimensions) => {
    const { onChange } = this.props
    onChange && onChange(e, dimensions)
  }

  handleMouseDown = e => {
    const { onMouseDown } = this.props
    onMouseDown && onMouseDown(e)
  }

  handleHandlerMouseDown = e => {
    stopPropagation(e)
  }

  handleOnDrag = (e, data) => {
    const { dimensions } = this.props

    let newDimensions = InitialDimensions

    switch (data.node.id) {
      case Handlers.HandlerTop:
        newDimensions = {
          ...dimensions,
          y: dimensions.top + data.deltaY,
          height: dimensions.height - data.deltaY,
        }
        break
      case Handlers.HandlerLeft:
        newDimensions = {
          ...dimensions,
          x: dimensions.left + data.deltaX,
          width: dimensions.width - data.deltaX,
        }
        break
      case Handlers.HandlerRight:
        newDimensions = {
          ...dimensions,
          width: dimensions.width + data.deltaX,
        }
        break
      case Handlers.HandlerBottom:
        newDimensions = {
          ...dimensions,
          height: dimensions.height + data.deltaY,
        }
        break
      default:
        return null
    }

    this.handleChange(e, newDimensions)
  }

  dragHandlers = {
    onDrag: this.handleOnDrag,
    onMouseDown: this.handleHandlerMouseDown,
    offsetParent: document.body,
  }

  render() {
    const {
      classes,
      dimensions: { width, height },
      offsetDimensions,
      disabled,
      axis,
      children,
    } = this.props

    let handlerContainerStyles = {
      width,
      height,
    }

    if (offsetDimensions) {
      handlerContainerStyles = {
        width: offsetDimensions.width * 2 + width,
        height: offsetDimensions.height * 2 + height,
        top: -offsetDimensions.height,
        left: -offsetDimensions.width,
      }
    }

    return (
      <div className={classes.container}>
        {children}
        {!disabled && (width || height) && (
          <div className={classes.handlerContainer} style={handlerContainerStyles}>
            {(!axis || axis === 'vertical') && (
              <React.Fragment>
                <DraggableCore {...this.dragHandlers}>
                  <div
                    className={classNames(classes.verticalHandlerTop, classes.handler)}
                    id={Handlers.HandlerTop}
                  />
                </DraggableCore>
                <DraggableCore {...this.dragHandlers}>
                  <div
                    className={classNames(classes.verticalHandlerBottom, classes.handler)}
                    id={Handlers.HandlerBottom}
                  />
                </DraggableCore>
              </React.Fragment>
            )}
            {(!axis || axis === 'horizontal') && (
              <React.Fragment>
                <DraggableCore {...this.dragHandlers}>
                  <div
                    className={classNames(classes.horizontalHandlerLeft, classes.handler)}
                    id={Handlers.HandlerLeft}
                  />
                </DraggableCore>
                <DraggableCore {...this.dragHandlers}>
                  <div
                    className={classNames(classes.horizontalHandlerRight, classes.handler)}
                    id={Handlers.HandlerRight}
                  />
                </DraggableCore>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    )
  }
}

ResizeWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onMouseDown: PropTypes.func,
  disabled: PropTypes.bool,
  dimensions: PropTypes.object,
  offsetDimensions: PropTypes.object,
  axis: PropTypes.string,
  children: PropTypes.element,
}

export default withStyles(styles)(ResizeWrapper)
