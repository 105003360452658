import * as Analytics from '../analytics/GoogleAnalytics'
import { INIT_USER } from '../constants/actionTypes'
import FirebaseAPI from '../api/FirebaseAPI'

export function resetUser() {
  return async dispatch => {
    dispatch({
      type: INIT_USER,
      payload: {
        email: null,
        token: null,
      },
    })
  }
}

export function signIn(userEmail, userPassword) {
  Analytics.logEvent({
    category: Analytics.EVENT_CATEGORIES.SIGN_IN,
    action: Analytics.EVENT_ACTIONS.SUBMIT,
  })

  return async dispatch => {
    const userAuth = await FirebaseAPI.signIn(userEmail, userPassword)

    if (userAuth.error) {
      return userAuth
    }

    const {
      user: { email, refreshToken: token },
    } = userAuth.response

    const payload = { email, token }

    dispatch({
      type: INIT_USER,
      payload,
    })

    return userAuth
  }
}

export function signUp(email, password) {
  Analytics.logEvent({
    category: Analytics.EVENT_CATEGORIES.SIGN_UP,
    action: Analytics.EVENT_ACTIONS.SUBMIT,
  })

  return async dispatch => {
    const userAuth = await FirebaseAPI.signUp(email, password)

    if (userAuth.error) {
      return userAuth
    }

    dispatch(signIn(email, password))

    return userAuth
  }
}
