import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import TextField from '@material-ui/core/TextField'

import styles from './TextFieldOutlinedStyles'

class TextFieldOutlined extends Component {
  render() {
    const {
      classes,
      layer: {
        props: { text, label, color, fontSize, borderRadius },
        frame: { width, height, y: top, x: left },
        zIndex,
      },
    } = this.props

    const containerStyles = {
      top,
      left,
      width,
      height,
      zIndex,
    }

    const textfieldStyles = {
      color,
      fontSize,
      borderRadius,
      ...containerStyles,
    }

    const CustomTextField = withStyles({
      root: {
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: color,
          },
        },
      },
    })(TextField)

    return (
      <div className={classes.container}>
        <CustomTextField
          label={label}
          margin="normal"
          variant="outlined"
          defaultValue={text}
          style={textfieldStyles}
        />
      </div>
    )
  }
}

TextFieldOutlined.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  artboardEvents: PropTypes.object,
}

export default withStyles(styles)(TextFieldOutlined)
