import { keyframes } from '@emotion/core'

export const CssTransitionDefaultValue = 'translate3d(0, 0, 0)'
export const CssTransitionOutDefaultValue = 'translate3d(-100%, 0, 0)'

export const DefaultTransitionIn = {
  duration: 300,
  delay: 0,
  keyframes: keyframes({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  }),
}

export const TransitionsIn = [
  // slideInRight
  {
    duration: 300,
    delay: 0,
    keyframes: keyframes({
      from: {
        transform: 'translate3d(100%, 0, 0)',
      },
      to: {
        transform: 'translate3d(0, 0, 0)',
      },
    }),
  },
  // slideInLeft
  {
    duration: 300,
    delay: 0,
    keyframes: keyframes({
      from: {
        transform: 'translate3d(-100%, 0, 0)',
      },
      to: {
        transform: 'translate3d(0, 0, 0)',
      },
    }),
  },
  // slideInUp
  {
    duration: 300,
    delay: 0,
    keyframes: keyframes({
      from: {
        transform: 'translate3d(0, 100%, 0)',
      },
      to: {
        transform: 'translate3d(0, 0, 0)',
      },
    }),
  },
  // slideInDown
  {
    duration: 300,
    delay: 0,
    keyframes: keyframes({
      from: {
        transform: 'translate3d(0, -100%, 0)',
      },
      to: {
        transform: 'translate3d(0, 0, 0)',
      },
    }),
  },
]

export const TransitionsOut = [
  // void
  {
    duration: 400,
    delay: 0,
    keyframes: keyframes({
      from: {
        transform: 'translate3d(0, 0, 0)',
        opacity: 1,
      },
      to: {
        transform: 'translate3d(0, 0, 0)',
        opacity: 0,
        zIndex: -1,
      },
    }),
  },
  // slideOutRight
  {
    duration: 200,
    delay: 0,
    keyframes: keyframes({
      from: {
        transform: 'translate3d(0, 0, 0)',
      },
      to: {
        transform: 'translate3d(-100%, 0, 0)',
      },
    }),
  },
  // slideOutLeft
  {
    duration: 200,
    delay: 0,
    keyframes: keyframes({
      from: {
        transform: 'translate3d(0, 0, 0)',
      },
      to: {
        transform: 'translate3d(100%, 0, 0)',
      },
    }),
  },
  // slideOutUp
  {
    duration: 200,
    delay: 0,
    keyframes: keyframes({
      from: {
        transform: 'translate3d(0, 0, 0)',
      },
      to: {
        transform: 'translate3d(0, -100%, 0)',
      },
    }),
  },
  // slideOutDown
  {
    duration: 200,
    delay: 0,
    keyframes: keyframes({
      from: {
        transform: 'translate3d(0, 0, 0)',
      },
      to: {
        transform: 'translate3d(0, 100%, 0)',
      },
    }),
  },
]
