import get from 'lodash/get'
import find from 'lodash/find'
import filter from 'lodash/filter'
import isEqual from 'lodash/isEqual'
import {
  SET_CANVAS_PROJECT,
  SET_HOVERED_LAYER,
  SET_SELECTED_LAYER,
  REMOVE_LAYER,
  REPLACE_LAYERS,
  SET_LAYER_PROPERTY,
  RESET_TOOLS_STATE,
  SET_ACTIVE_TOOL,
  SET_TOOL_STEP,
  CREATE_LAYER,
  SET_DEFAULT_LAYER,
  SET_LINE_TOOL_START_ATTACHMENT,
  SET_ORIGIN_MOUSE_POSITION,
} from 'constants/actionTypes'
import { allKeyboardShortcuts } from 'constants/keyboardShortcuts'
import * as modifierKeyTypes from 'constants/modifierKeyTypes'
import * as pageTypes from 'constants/pageTypes'
import * as toolTypes from 'constants/toolTypes'
import * as toolStates from 'constants/toolStates'
import * as defaultCanvasLayers from 'constants/defaultCanvasLayers'
import * as utils from 'utils'
import * as Analytics from 'analytics/GoogleAnalytics'
import FirebaseAPI from 'api/FirebaseAPI'

export function setDefaultCanvasLayer(project) {
  return dispatch => {
    const layers = get(project, 'artboard.layers') || get(project, 'layers')
    const formattedLayers = utils.formatFirebaseDictAsArray(layers)

    const text = find(formattedLayers, l => l.type === 'text') || defaultCanvasLayers.textLayer

    dispatch({
      type: SET_DEFAULT_LAYER,
      payload: {
        defaultLayer: {
          text: {
            ...text,
            hyperlink: false,
          },
          // Other default layers go here...
        },
      },
    })
  }
}

export function setCanvasProject(project, props = {}) {
  return dispatch => {
    dispatch(setDefaultCanvasLayer(project))

    dispatch({
      type: SET_CANVAS_PROJECT,
      payload: {
        project: {
          ...project,
          ...props,
        },
      },
    })
  }
}

export function setHoveredLayer(layerId) {
  return dispatch => {
    dispatch({
      type: SET_HOVERED_LAYER,
      layerId,
    })
  }
}

export function createLayer(layer, parentRef = null) {
  return (dispatch, getState) => {
    const {
      page: { currentPage },
    } = getState()

    dispatch({
      type: CREATE_LAYER,
      payload: {
        layer,
        parentRef,
      },
    })

    switch (currentPage) {
      case pageTypes.MakerPage: {
        FirebaseAPI.createMakerLayer(layer, parentRef)
        return
      }
      default:
        return null
    }
  }
}

export function selectLayer(layerId) {
  return dispatch => {
    dispatch({
      type: SET_SELECTED_LAYER,
      layerId,
    })
  }
}

export function setSelectedLayer(currentPage, layerId) {
  return dispatch => {
    Analytics.logEvent({
      category: Analytics.EVENT_CATEGORIES[currentPage],
      action: Analytics.EVENT_ACTIONS.CLICK,
      label: Analytics.EVENT_LABELS.LAYER,
    })

    dispatch(selectLayer(layerId))
  }
}

export function setLayerProperty(currentPage, layerId, propPath, value, parentRef = null) {
  return async dispatch => {
    Analytics.logEvent({
      category: Analytics.EVENT_CATEGORIES[currentPage],
      action: Analytics.EVENT_ACTIONS.CHANGE,
      label: Analytics.EVENT_LABELS.LAYER,
    })

    dispatch({
      type: SET_LAYER_PROPERTY,
      payload: {
        layerId,
        propPath,
        value,
        parentRef,
      },
    })

    switch (currentPage) {
      case pageTypes.MakerPage: {
        FirebaseAPI.setMakerLayerProperty(layerId, propPath, value, parentRef)
        return
      }
      default:
        return null
    }
  }
}

export function removeLayer(currentPage, layerId, parentRef = null) {
  return dispatch => {
    dispatch({
      type: REMOVE_LAYER,
      payload: {
        layerId,
        parentRef,
      },
    })
    switch (currentPage) {
      case pageTypes.MakerPage:
        FirebaseAPI.removeMakerLayers([layerId], parentRef)
        break
      default:
        break
    }
  }
}

export function removeSelectedLayer(currentPage, parentRef = null) {
  return (dispatch, getState) => {
    const {
      canvas: { selectedLayerId },
    } = getState()

    if (!selectedLayerId) {
      return null
    }

    dispatch(removeLayer(currentPage, selectedLayerId, parentRef))
  }
}

export function replaceLayers(layers, parentRef = null) {
  return dispatch => {
    dispatch({
      type: REPLACE_LAYERS,
      payload: {
        layers,
        parentRef,
      },
    })
  }
}

export function getSelectedLayer() {
  return (dispatch, getState) => {
    const {
      canvas: { project, selectedLayerId },
    } = getState()

    if (!selectedLayerId) {
      return null
    }

    const combinedLayers = {
      ...project.layers,
      ...project.artboard.layers,
    }

    return find(combinedLayers, l => {
      return l.id === selectedLayerId
    })
  }
}

export function addHyperlinkOnSelectedLayer(currentPage, parentRef = null) {
  return (dispatch, getState) => {
    const layer = dispatch(getSelectedLayer())

    if (!layer || layer.type !== 'text') {
      return null
    }

    dispatch(setLayerProperty(currentPage, layer.id, 'hyperlink', '#', parentRef))
  }
}

export function moveSelectedLayer(currentPage, shortcut, parentRef = null) {
  return dispatch => {
    const layer = dispatch(getSelectedLayer())

    if (!layer) {
      return null
    }

    const isLineLayer = utils.isLineLayer(layer)

    const incrValue = shortcut.modifierKeys ? 10 : 1
    let propPath
    let value
    switch (shortcut.key) {
      case 'ArrowUp': {
        if (isLineLayer) {
          const { p1, p2 } = layer.points
          propPath = 'points'
          value = {
            p1: {
              ...p1,
              y: p1.y - incrValue,
            },
            p2: {
              ...p2,
              y: p2.y - incrValue,
            },
          }
        } else {
          propPath = 'frame.y'
          value = layer.frame.y - incrValue
        }
        break
      }
      case 'ArrowRight': {
        if (isLineLayer) {
          const { p1, p2 } = layer.points
          propPath = 'points'
          value = {
            p1: {
              ...p1,
              x: p1.x + incrValue,
            },
            p2: {
              ...p2,
              x: p2.x + incrValue,
            },
          }
        } else {
          propPath = 'frame.x'
          value = layer.frame.x + incrValue
        }
        break
      }
      case 'ArrowDown': {
        if (isLineLayer) {
          const { p1, p2 } = layer.points
          propPath = 'points'
          value = {
            p1: {
              ...p1,
              y: p1.y + incrValue,
            },
            p2: {
              ...p2,
              y: p2.y + incrValue,
            },
          }
        } else {
          propPath = 'frame.y'
          value = layer.frame.y + incrValue
        }
        break
      }
      case 'ArrowLeft': {
        if (isLineLayer) {
          const { p1, p2 } = layer.points
          propPath = 'points'
          value = {
            p1: {
              ...p1,
              x: p1.x - incrValue,
            },
            p2: {
              ...p2,
              x: p2.x - incrValue,
            },
          }
        } else {
          propPath = 'frame.x'
          value = layer.frame.x - incrValue
        }
        break
      }
      default:
        return
    }

    dispatch(setLayerProperty(currentPage, layer.id, propPath, value, parentRef))
  }
}

/* #############################################################################
 * TOOL STATE
 * ########################################################################## */

export function setOriginMousePosition(originMousePosition) {
  return {
    type: SET_ORIGIN_MOUSE_POSITION,
    originMousePosition,
  }
}

export function setLineToolStartAttachment(attachment) {
  return {
    type: SET_LINE_TOOL_START_ATTACHMENT,
    attachment,
  }
}

/* #############################################################################
 * TOOL EVENTS
 * ########################################################################## */

export function resetToolsState() {
  return {
    type: RESET_TOOLS_STATE,
  }
}

export function setToolStep(toolStep) {
  return dispatch => {
    dispatch({
      type: SET_TOOL_STEP,
      toolStep,
    })
  }
}

export function setActiveTool(toolName) {
  return dispatch => {
    Analytics.logEvent({
      category: Analytics.EVENT_CATEGORIES.TOOLBAR,
      action: Analytics.EVENT_ACTIONS.CLICK,
      label: toolName,
    })

    if (toolName === null) {
      dispatch(resetToolsState())
      return
    }

    dispatch({
      type: SET_ACTIVE_TOOL,
      toolName,
    })
    // Deselect selected layer.
    dispatch(selectLayer(null))

    // Initialize the tool at it's first state.
    const stepsForThisTool = utils.getStepsForTool(toolName)
    dispatch(setToolStep(stepsForThisTool[0]))
  }
}

export function advanceToolToNextStep(toolName, restartAfterEnd) {
  return (dispatch, getState) => {
    const {
      canvas: {
        toolsState: { toolStep },
      },
    } = getState()
    const stepsForTool = utils.getStepsForTool(toolName)
    // Not currently on any step, so let's go to the first step.
    if (toolStep === null) {
      dispatch(setToolStep(stepsForTool[0]))
      // We're on a step, so find the next one.
    } else {
      const nextStepIndex = toolStep.num + 1
      if (nextStepIndex >= stepsForTool.length) {
        // We've reached the end, set it back to null.
        dispatch(setActiveTool(null))
        if (restartAfterEnd) {
          // And then turn it back on!
          dispatch(setActiveTool(toolName))
        }
      } else {
        // There are steps left, so advance to the next one.
        dispatch(setToolStep(stepsForTool[nextStepIndex]))
      }
    }
  }
}

/* #############################################################################
 * CLICK EVENTS
 * ########################################################################## */

export function textToolClick(originPosition, ref = null) {
  return async(dispatch, getState) => {
    const {
      canvas: { defaultLayer },
    } = getState()

    const textLayer = utils.makeTextLayer(originPosition)

    const frame = {
      x: textLayer.position.x,
      y: textLayer.position.y,
    }

    const refType = ref ? ref.type : null

    // If click is detected over `artboard`, the position would be relative to the artboard frame
    if (refType === 'artboard') {
      const artboardFrame = utils.getFrameFromNode(ref.node)
      frame.x -= artboardFrame.x
      frame.y -= artboardFrame.y
    }

    const newLayer = {
      ...defaultLayer.text,
      type: 'text',
      id: textLayer.id,
      frame,
      value: textLayer.config.value,
    }

    dispatch(createLayer(newLayer, refType))
    dispatch(advanceToolToNextStep(toolTypes.TextTool))
    dispatch(selectLayer(newLayer.id))
  }
}

export function lineToolClick(originPosition, ref = null) {
  return async(dispatch, getState) => {
    const {
      canvas: {
        toolsState: { toolStep, lineToolState },
      },
    } = getState()

    let attachment
    switch (toolStep.name) {
      case toolStates.AwaitingFirstAttachmentSelection: {
        attachment = utils.makePositionAttachment(originPosition)
        dispatch(setLineToolStartAttachment(attachment))
        dispatch(advanceToolToNextStep(toolTypes.LineTool))
        break
      }
      case toolStates.AwaitingSecondAttachmentSelection: {
        const { startAttachment } = lineToolState
        const endAttachment = utils.makePositionAttachment(originPosition)

        const lineLayer = utils.makeLineLayer(startAttachment, endAttachment)

        const refType = ref ? ref.type : null

        // If click is detected over `artboard`, the position would be relative to the artboard frame
        if (refType === 'artboard') {
          const artboardFrame = utils.getFrameFromNode(ref.node)
          lineLayer.points.p1.x -= artboardFrame.x
          lineLayer.points.p2.x -= artboardFrame.x
          lineLayer.points.p1.y -= artboardFrame.y
          lineLayer.points.p2.y -= artboardFrame.y
        }

        dispatch(createLayer(lineLayer, refType))
        dispatch(advanceToolToNextStep(toolTypes.LineTool))
        dispatch(selectLayer(lineLayer.id))
        break
      }
      default:
        throw new Error(`Unrecognized step name: ${toolStep.name}`)
    }
  }
}

export function canvasClick(originPosition, ref = null) {
  return (dispatch, getState) => {
    const {
      canvas: {
        toolsState: { activeTool },
      },
    } = getState()

    switch (activeTool) {
      case toolTypes.TextTool:
        dispatch(textToolClick(originPosition, ref))
        break
      case toolTypes.LineTool:
        dispatch(lineToolClick(originPosition, ref))
        break
      default:
        break
    }
  }
}

/* #############################################################################
 * KEYBOARD EVENTS
 * ########################################################################## */

function getMatchingShortcut(keypressEvent, keyboardShortcutsGroup) {
  return find(keyboardShortcutsGroup, shortcut => {
    // Get a list of all the active modifier keys.
    const activeModifierKeys = filter(modifierKeyTypes, modifierKey => keypressEvent[modifierKey])
    // If `shortcut.key` exists, match directly. Otherwise attempt to find a match against the
    // `shortcut.keys` array.
    const hasMatchingKey = shortcut.key
      ? shortcut.key === keypressEvent.key
      : find(shortcut.keys, key => key === keypressEvent.key)
    // If no key matches, do not bother checking modifierKeys.
    if (!hasMatchingKey) return false
    // Check that all active modifier keys match the ones defined for the shortcut.
    return isEqual(activeModifierKeys, get(shortcut, 'modifierKeys') || [])
  })
}

export function handleShortcutsKeyUp(e, currentPage, keyboardShortcutsGroup) {
  return dispatch => {
    // TODO
    switch (e.key) {
      default:
        break
    }
  }
}

export function handleShortcutsKeyDown(e, currentPage, keyboardShortcutsGroup, parentRef = null) {
  return dispatch => {
    // Verify the users keypress matches a shortcut from the associated group.
    const shortcutMatch = getMatchingShortcut(e, keyboardShortcutsGroup)

    // If none match, or the event is repeating, return.
    if (!shortcutMatch || (!shortcutMatch.repeatEnabled && e.repeat)) return
    // Else log the analytics event and dispatch the relevant action.
    e.preventDefault()
    Analytics.logEvent({
      category: Analytics.EVENT_CATEGORIES.SHORTCUTS,
      action: Analytics.EVENT_ACTIONS.SHORTCUTS_CLICK,
      label: shortcutMatch.displayString,
    })

    switch (shortcutMatch.shortcutId) {
      case allKeyboardShortcuts[toolTypes.TextTool].shortcutId:
        dispatch(setActiveTool(toolTypes.TextTool))
        break
      case allKeyboardShortcuts[toolTypes.LineTool].shortcutId:
        dispatch(setActiveTool(toolTypes.LineTool))
        break
      case allKeyboardShortcuts.deselectTool.shortcutId:
        dispatch(setActiveTool(null))
        break
      case allKeyboardShortcuts.removeLayer.shortcutId:
        dispatch(removeSelectedLayer(currentPage, parentRef))
        break
      case allKeyboardShortcuts.addHyperlink.shortcutId: {
        dispatch(addHyperlinkOnSelectedLayer(currentPage, parentRef))
        break
      }
      case allKeyboardShortcuts.deselectLayer.shortcutId:
        dispatch(selectLayer(null))
        break
      case allKeyboardShortcuts.moveLayerUp.shortcutId:
        dispatch(moveSelectedLayer(currentPage, allKeyboardShortcuts.moveLayerUp, parentRef))
        break
      case allKeyboardShortcuts.moveLayerRight.shortcutId:
        dispatch(moveSelectedLayer(currentPage, allKeyboardShortcuts.moveLayerRight, parentRef))
        break
      case allKeyboardShortcuts.moveLayerDown.shortcutId:
        dispatch(moveSelectedLayer(currentPage, allKeyboardShortcuts.moveLayerDown, parentRef))
        break
      case allKeyboardShortcuts.moveLayerLeft.shortcutId:
        dispatch(moveSelectedLayer(currentPage, allKeyboardShortcuts.moveLayerLeft, parentRef))
        break
      case allKeyboardShortcuts.moveLayerUpPlus.shortcutId:
        dispatch(moveSelectedLayer(currentPage, allKeyboardShortcuts.moveLayerUpPlus, parentRef))
        break
      case allKeyboardShortcuts.moveLayerRightPlus.shortcutId:
        dispatch(moveSelectedLayer(currentPage, allKeyboardShortcuts.moveLayerRightPlus, parentRef))
        break
      case allKeyboardShortcuts.moveLayerDownPlus.shortcutId:
        dispatch(moveSelectedLayer(currentPage, allKeyboardShortcuts.moveLayerDownPlus, parentRef))
        break
      case allKeyboardShortcuts.moveLayerLeftPlus.shortcutId:
        dispatch(moveSelectedLayer(currentPage, allKeyboardShortcuts.moveLayerLeftPlus, parentRef))
        break
      default:
        break
    }
  }
}
