import update from 'immutability-helper'

import { FETCH_PROJECT } from '../constants/actionTypes'
import initialState from './initialState'

export default (state = initialState.projectPage, action) => {
  switch (action.type) {
    case FETCH_PROJECT:
      return update(state, {
        project: {
          $set: {
            ...initialState.projectPage.project,
            ...action.payload,
          },
        },
      })
    default:
      return state
  }
}
