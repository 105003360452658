import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { processLayerPosition } from 'utils'

import styles from './HotspotLayerStyles'

class HotspotLayer extends React.Component {
  handleClick = e => {
    const { layer } = this.props
    this.handleArtboardFlowChange(layer.value)
  }

  handleArtboardFlowChange = hotspotValue => {
    const { onArtboardFlowChange } = this.props
    onArtboardFlowChange && onArtboardFlowChange(hotspotValue)
  }

  render() {
    const {
      classes,
      layer,
      layer: {
        frame: { width, height },
      },
      scaleRatio,
    } = this.props

    const containerClassNames = classNames({
      [classes.container]: true,
    })

    const position = processLayerPosition(layer, scaleRatio)

    const rootStyles = {
      ...position,
      width: width * scaleRatio,
      height: height * scaleRatio,
    }

    return <div className={containerClassNames} onClick={this.handleClick} style={rootStyles} />
  }
}

HotspotLayer.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  isHovered: PropTypes.bool,
  isSelected: PropTypes.bool,
  onMouseDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onChange: PropTypes.func,
  onArtboardFlowChange: PropTypes.func,
  scaleRatio: PropTypes.number,
}

export default withStyles(styles)(HotspotLayer)
