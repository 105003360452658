import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'

import NumberField from '../../_common/NumberField'

import styles from './TextFormStyles'

class TextForm extends Component {
  handleInputKeyDown = e => e.stopPropagation()

  handleFontSizeChange = e => {
    const { onChange, parentRef } = this.props
    onChange && onChange(e, 'style.fontSize', Number(e.target.value), parentRef)
  }

  handleHyperlinkChange = e => {
    const { onChange, parentRef } = this.props
    onChange && onChange(e, 'hyperlink', e.target.value, parentRef)
  }

  render() {
    const { classes, layer } = this.props
    return (
      <div className={classes.container}>
        <Typography variant="body1" className={classes.formType}>
          {layer.type}
        </Typography>

        <FormControl fullWidth margin="dense" className={classes.form}>
          <NumberField
            onChange={this.handleFontSizeChange}
            label="Font size"
            value={layer.style.fontSize}
            onKeyDown={this.handleInputKeyDown}
          />
        </FormControl>

        {layer.hyperlink && (
          <FormControl fullWidth margin="dense" className={classes.form}>
            <TextField
              label="Hyperlink"
              value={layer.hyperlink}
              onChange={this.handleHyperlinkChange}
              onKeyDown={this.handleInputKeyDown}
            />
          </FormControl>
        )}
      </div>
    )
  }
}

TextForm.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object,
  parentRef: PropTypes.string,
  onChange: PropTypes.func,
}

export default withStyles(styles)(TextForm)
