import { FETCH_PROJECTS } from '../constants/actionTypes'

import initialState from './initialState'

export default (state = initialState.projects, action) => {
  switch (action.type) {
    case FETCH_PROJECTS:
      return {
        ...initialState.projects,
        ...action.payload,
      }
    default:
      return state
  }
}
