const jssStyles = theme => ({
  page: {
    position: 'relative',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#1e1e1e',
  },
  outerContainer: {
    position: 'relative',
    height: '100%',
    width: '100%',
    overflow: 'scroll',
    WebkitOverflowScrolling: 'touch',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    fontFamily: 'Roboto',
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: 'white',
  },
  actions: {
    position: 'absolute',
    top: 14,
    right: 30,
    '& > *': {
      marginLeft: 8,
    },
  },
  loading: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default jssStyles
