const jssStyles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 70,
    borderLeft: `2px solid ${theme.palette.primary.color}`,
    padding: '8px 16px',
    marginTop: 32,
    '& div': {
      fontWeight: 600,
    },
  },
  title: {
    paddingBottom: 12,
  },
  description: {
    lineHeight: '18px',
  },
  flex: {
    flex: 1,
  },
})

export default jssStyles
