const PrimaryColor = '#000'
const SecondaryColor = '#ad2064'

const jssStyles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'ProximaNova-Regular'
  },
  flex: {
    flex: 1
  },
  logo: {
    height: 24,
    margin: '0 0 0 58px'
  },
  button: {
    border: '2px solid #fff',
    cursor: 'pointer',
    backgroundColor: SecondaryColor,
    color: 'white',
    height: 40,
    fontSize: 18,
    minWidth: 120,
    borderRadius: 6,
    '&:hover': {
      fontWeight: 500,
      borderWidth: 1
    },
    '&:disabled': {
      borderWidth: 2,
      backgroundColor: 'transparent',
      color: PrimaryColor,
      cursor: 'default',
      opacity: 0.7,
      fontWeight: 400
    }
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    width: '100%',
    height: 72,
    zIndex: theme.zIndex.GUI,
    backgroundColor: '#fff'
  },
  scrolling: {
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 80px 0',
    '& a': {
      color: PrimaryColor,
      marginLeft: 36,
      cursor: 'pointer',
      '&:hover': {
        fontWeight: 600
      }
    },
    '& $ctaButton': {
      color: 'white',
      marginLeft: 36
    }
  },
  ctaButton: {
    backgroundColor: SecondaryColor,
    padding: '8px 16px',
    height: 30,
    color: 'white',
    borderRadius: 6,
    fontWeight: 600,
    userSelect: 'none',
    '&:hover': {
      opacity: 0.8
    }
  },
  startedButton: {
    backgroundColor: PrimaryColor,
    padding: '8px 16px',
    height: 30,
    color: 'white',
    borderRadius: 6,
    fontWeight: 600,
    userSelect: 'none',
    width: 114
  },
  ctaBoldLarge: {
    cursor: 'pointer',
    border: 'none',
    backgroundColor: PrimaryColor,
    padding: '10px 35px',
    height: 50,
    color: 'white',
    borderRadius: 6,
    fontWeight: 600,
    fontSize: 24,
    marginTop: 64,
    userSelect: 'none',
    '&:hover': {
      opacity: 0.8
    }
  },
  hero: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 152,
    '& *::selection': {
      color: 'white',
      backgroundColor: SecondaryColor
    }
  },
  headline: {
    fontFamily: 'ProximaNova-Extrabold',
    fontSize: 92,
    fontWeight: 800,
    textAlign: 'center'
  },
  subheading: {
    fontSize: 28,
    margin: '16px 0 24px',
    textAlign: 'center'
  },
  servicesList: {
    display: 'flex',
    minWidth: 480
  },
  heroServices: {
    paddingTop: 80,
    display: 'flex',
    justifyContent: 'center'
  },
  heroPricing: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '120px 0'
  },
  pricingContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  pricingActions: {
    marginTop: 72,
    display: 'flex',
    justifyContent: 'center'
  },
  pricingDrawers: {
    display: 'none'
  },
  service: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 6,
    backgroundColor: SecondaryColor,
    width: 308,
    height: 271,
    margin: '0 20px',
    padding: 24,

    '& h6, & span': {
      color: 'white'
    }
  },
  serviceLabel: {
    lineHeight: 'unset',
    fontSize: 20
  },
  serviceDescription: {
    color: 'white',
    marginTop: 24,
    fontSize: 18,
    lineHeight: '24px'
  },
  ctaToPricing: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: PrimaryColor,
    padding: '8px 16px',
    height: 42,
    color: 'white',
    borderRadius: 29,
    fontSize: 18,
    '&:hover': {
      opacity: 0.8
    }
  },
  pricingDescription: {
    display: 'none',
    fontSize: 14,
    padding: '0 16px 16px'
  },
  pricingService: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 6,
    border: `1px solid ${PrimaryColor}`,
    width: 304,
    margin: '0 20px',
    position: 'relative'
  },
  pricingDrawerIcon: {
    position: 'absolute',
    right: 0
  },
  pricingLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 18,
    paddingTop: 18,
    fontWeight: 700
  },
  feature: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column'
  },
  pricingFeature: {
    left: 21,
    fontSize: 18,
    margin: '0 24px 24px',
    paddingLeft: 21,
    position: 'relative',
    lineHeight: '24px',

    '&:before': {
      position: 'absolute',
      content: '""',
      height: 15,
      width: 19,
      backgroundImage: 'url(/images/check.svg)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      marginLeft: -40,
      top: 4
    }
  },
  priceContainer: {
    position: 'relative',
    backgroundColor: PrimaryColor,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',

    '& p': {
      color: 'white'
    },

    '&:before': {
      position: 'absolute',
      content: '"Estimated price"',
      top: 8,
      left: 8,
      fontSize: 10
    }
  },
  priceCenter: {
    padding: '29px 0 12px 0',
    display: 'flex',
    alignItems: 'center'
  },
  totalPrice: {
    fontSize: 40,
    lineHeight: 'unset',
    paddingRight: 8
  },
  subPrice: {
    fontSize: 14,
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  checkboxContainer: {
    display: 'flex',
    cursor: 'pointer',
    userSelect: 'none',
    margin: '0 12px',
    flex: 1,
    '& img': {
      marginRight: 8,
      width: 16
    },
    '&:hover p': {
      fontWeight: 500
    }
  },
  checkboxLabel: {
    fontSize: 18
  },
  heroAbout: {
    display: 'flex',
    flexDirection: 'column',
    height: 720,
    backgroundColor: PrimaryColor,
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center'
  },
  centerContent: {
    display: 'flex',
    width: '75%',
    marginBottom: 48
  },
  aboutLabel: {
    display: 'flex',
    flex: 0.5,
    fontSize: 120,
    fontWeight: 700,
    marginRight: 32,
    lineHeight: '100px',
    maxWidth: 580
  },
  aboutDescription: {
    color: '#fff',
    position: 'relative',
    fontSize: 24,
    flex: 0.5,

    '& span': {
      fontWeight: 'bold'
    },

    '&:before': {
      content: '""',
      position: 'absolute',
      backgroundColor: 'white',
      height: 5,
      width: '50%',
      bottom: -20
    }
  },
  ctaWhiteButton: {
    border: `1px solid ${PrimaryColor}`,
    background: 'transparent',
    color: PrimaryColor,
    height: 50,
    width: 240,
    fontSize: 24,
    borderRadius: 6,
    cursor: 'pointer',

    '&:hover': {
      fontWeight: 500,
      borderWidth: 2
    }
  },
  ctaBlackButton: {
    border: '1px solid #fff',
    background: 'transparent',
    color: 'white',
    height: 50,
    width: 240,
    fontSize: 24,
    borderRadius: 6,
    cursor: 'pointer',

    '&:hover': {
      fontWeight: 500,
      borderWidth: 2
    }
  },
  locationsContent: {
    display: 'flex',
    width: '75%',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',

    '& p': {
      color: 'white',
      marginLeft: 32,
      fontSize: 18,

      '&:hover': {
        fontWeight: 500
      }
    }
  },
  dialogTitle: {
    padding: '24px 24px 12px 24px',
    width: 680,

    '& h2': {
      fontSize: 24
    }
  },
  dialogContent: {
    '& $checkboxContainer': {
      margin: '0 12px 0 0'
    }
  },
  dialogActions: {
    padding: 24
  },
  dialogForm: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 32
  },
  inputsOneLine: {
    display: 'flex',
    marginTop: 24,
    '& > *': {
      flex: 1
    },
    '& > *:first-child': {
      marginRight: 16
    }
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,.5)'
  },
  '@media (max-width: 1400px)': {
    headline: {
      fontSize: 72
    },
    centerContent: {
      width: '80%'
    },
    aboutLabel: {
      fontSize: 110,
      lineHeight: '80px'
    },
    aboutDescription: {
      fontSize: 18
    },
    locationsContent: {
      '& p': {
        fontSize: 14
      }
    }
  },
  '@media (max-width: 1150px)': {
    headerActions: {
      margin: '0 20px 0',
      '& a': {
        marginLeft: 20
      }
    },
    headline: {
      fontSize: 52
    },
    subheading: {
      margin: '8px 0 16px',
      fontSize: 20
    },
    service: {
      width: 260,
      margin: '0 16px'
    },
    serviceDescription: {
      fontSize: 16
    },
    pricingService: {
      width: 260,
      margin: '0 16px'
    },
    pricingFeature: {
      left: 12,
      fontSize: 17
    },
    centerContent: {
      width: '90%'
    },
    aboutLabel: {
      fontSize: 80,
      lineHeight: '72px',
      flex: 0.4,
      minWidth: 350
    },
    aboutDescription: {
      fontSize: 16,
      minWidth: 320
    },
    locationsContent: {
      width: '90%',
      '& p': {
        fontSize: 12
      }
    }
  },
  '@media (max-width: 800px)': {
    headline: {
      fontSize: 40
    },
    subheading: {
      margin: '4px 0 16px',
      fontSize: 18
    },
    hero: {
      marginTop: 124
    },
    checkboxContainer: {
      width: 104,
      margin: '0 4px 0 0'
    },
    checkboxLabel: {
      fontSize: 16
    },
    ctaBoldLarge: {
      marginTop: 36,
      height: 40,
      fontSize: 18
    },
    ctaToPricing: {
      height: 32,
      fontSize: 16
    },
    feature: {
      marginTop: 20
    },
    heroPricing: {
      padding: '60px 0'
    },
    serviceDescription: {
      fontSize: 14
    },
    heroServices: {
      paddingTop: 40
    },
    service: {
      margin: '0 8px'
    },
    pricingService: {
      margin: '0 8px'
    },
    pricingFeature: {
      left: 8,
      fontSize: 15,
      margin: '0 24px 12px',
      paddingLeft: 4,
      '&:before': {
        top: 8,
        width: 14,
        height: 14,
        marginLeft: -24
      }
    },
    pricingActions: {
      marginTop: 32
    },
    centerContent: {
      flexDirection: 'column'
    },
    aboutLabel: {
      fontSize: 52,
      marginRight: 0
    },
    aboutDescription: {
      '&:before': {
        top: -100,
        bottom: 'auto'
      }
    },
    logo: {
      height: 14,
      margin: '0 0 0 20px'
    },
    headerActions: {
      margin: '0 12px 0 0px',
      '& a': {
        marginLeft: 8
      }
    }
  },
  '@media (max-width: 580px)': {
    header: {
      height: 52
    },
    logo: {
      height: 14,
      margin: '0 0 0 20px'
    },
    headline: {
      fontSize: 32
    },
    subheading: {
      fontSize: 16
    },
    headerActions: {
      margin: '0 16px 0 0',
      '& a': {
        marginLeft: 8,
        fontSize: 13
      },
      '& $ctaButton': {
        fontSize: 13,
        marginLeft: 8,
        padding: '9px 8px'
      }
    },
    hero: {
      marginTop: 72
    },
    checkboxLabel: {
      fontSize: 14
    },
    ctaBoldLarge: {
      fontSize: 16,
      marginTop: 24,
      padding: '7px 24px'
    },
    ctaBlackButton: {
      width: '100%'
    },
    heroAbout: {
      height: 520
    },
    aboutLabel: {
      fontSize: 40
    },
    pricingDrawers: {
      display: 'block'
    },
    heroServices: {
      display: 'none'
    },
    pricingContainer: {
      flexDirection: 'column',
      alignItems: 'center'
    },
    pricingService: {
      width: '95%',
      margin: '0 8px 8px'
    },
    pricingLabel: {
      justifyContent: 'flex-start',
      padding: '8px 0 8px 16px'
    },
    pricingDescription: {
      display: 'block'
    },
    feature: {
      marginTop: 0,
      display: 'none'
    },
    showFeatures: {
      '& $feature': {
        display: 'block'
      }
    },
    dialogTitle: {
      '& h2': {
        fontSize: 18
      }
    },
    servicesList: {
      minWidth: 'auto'
    },
    dialogContent: {
      '& $checkboxContainer': {
        margin: '0 4px 0 0'
      },
      '& $checkboxLabel': {
        fontSize: 13
      },
      '& img': {
        marginRight: 4
      }
    },
    heroPricing: {
      padding: '32px 0'
    }
  },
  '@media (max-width: 450px)': {
    header: {
      height: 50,
      alignItems: 'center'
    },
    headerActions: {
      '& a': {
        display: 'none'
      }
    },
    logo: {
      margin: '0 0 0 18px'
    },
    headline: {
      fontSize: 22
    },
    subheading: {
      fontSize: 11
    },
    aboutLabel: {
      fontSize: 32
    },
    dialogActions: {
      padding: 12
    },
    dialogTitle: {
      padding: '12px 12px 0 12px',
      width: '100%',
      '& h2': {
        fontSize: 16
      }
    },
    dialogForm: {
      marginTop: 18
    },
    dialogContent: {
      padding: '0 12px',

      '& $servicesList': {
        alignItems: 'center'
      },
      '& $checkboxContainer': {
        margin: '8px 0 0 0'
      }
    },
    inputsOneLine: {
      flexDirection: 'column',
      marginTop: 12,
      '& > * ': {
        margin: '0 0 12px !important'
      }
    },
    hero: {
      marginTop: 92
    }
  },
  '@media (max-width: 340px)': {
    checkboxContainer: {
      width: 96,
      '& $checkboxLabel': {
        fontSize: 12
      },
      '& img': {
        marginRight: 4
      }
    },
    checkboxLabel: {
      fontSize: 13
    },
    aboutLabel: {
      fontSize: 24
    },
    aboutDescription: {
      fontSize: 13,
      minWidth: 'auto'
    }
  }
})

export default jssStyles
