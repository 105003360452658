import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { withStyles } from '@material-ui/core/styles'

import * as toolTypes from 'constants/toolTypes'
import * as utils from 'utils'

import LineHint from '_hints/LineHint'

import styles from './BlockHintStyles'

function renderToolHints(properties) {
  const {
    toolsState: { activeTool },
    originMousePosition,
  } = properties
  if (utils.isValidCanvasPosition(originMousePosition)) {
    switch (activeTool) {
      case toolTypes.LineTool:
        return <LineHint {...properties} />
      default:
        break
    }
  }
  return null
}

const BlockHint = props => {
  const { classes, className, style, project, toolsState, originMousePosition } = props

  return (
    <div className={classNames(classes.component, className)} style={style}>
      {renderToolHints({
        project,
        toolsState,
        originMousePosition,
      })}
    </div>
  )
}

BlockHint.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  project: PropTypes.object,
  toolsState: PropTypes.object,
  originMousePosition: PropTypes.object,
}

export default withStyles(styles)(BlockHint)
