const jssStyles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    zIndex: theme.zIndex.layerElevated,
  },
  fab: {
    backgroundColor: 'white',
  },
  fabIcon: {
    width: '70%',
    height: 'auto',
  },
})

export default jssStyles
