const jssStyles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 560,
  },
  form: {
    display: 'block',
    padding: 28,
  },
  projectName: {
    display: 'flex',
    alignItems: 'center',
    height: 92,

    '& > *:nth-child(2)': {
      marginLeft: 12,
    },
    '& > *': {
      flex: 1,
    },
  },
  projectNameSelect: {
    flex: 1,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

export default jssStyles
